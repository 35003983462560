@media (max-width: 1650px) {
    section.section-footer {
        .footer-top {
            .top-service {
                display: block;

                >* {
                    margin-right: 0;
                }

                .service-heading {
                    width: auto;
                    margin-right: 20px;
                }

                .service-heading,
                .item-clock {
                    display: inline-block;
                }
            }
        }
    }
}

@media (max-width: 1366px) {
    body {
        .container-fluid {
            max-width: 1260px;
        }
    }

    nav.section-header {
        .header-top {
            .top-logo {
                .logo-image {
                    img {
                        height: 36px;
                        width: 202px;
                    }
                }
            }
        }
    }

    section.section-testimonials {
        .owl-carousel-testimonials-wrapper {
            padding: 0 100px;
        }
    }
}


@media (max-width: 1250px) {
    nav.section-header {
        .header-top {
            padding: 32px 0;

            .top-logo {
                .logo-slogan {
                    p {
                        white-space: normal;
                    }
                }
            }
        }
    }
}

@media (max-width: 1230px) {
    nav.section-header {
        .header-top {
            padding: 32px 0;

            // .top-logo {
            //     .logo-slogan {
            //         // display: none;
            //     }
            // }
        }
    }

    section.section-about {
        .about-flexbox {
            justify-content: space-between;

            .flexbox-service {
                margin-right: 0;

                .service-square {
                    height: 165px;
                    width: 165px;
                }

                .service-name {
                    margin-top: 40px;
                }
            }
        }
    }

    section.outsourcing-section-content {
        .content-numbers {
            .numbers-flexbox {
                .flexbox-service {
                    .service-square {
                        height: 150px;
                        width: 150px;

                        .square-icon {
                            height: 30px;
                        }

                        .square-number {
                            font-size: 38px;
                        }
                    }

                    .service-shadow {
                        height: 150px;
                        width: 150px;
                    }
                }
            }
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    nav.section-header {
        .header-dropdown {
            .dropdown-inner {
                .inner-service {
                    .service-image {
                        .image-product {
                            height: 136px;
                        }
                    }
                }

                .divider {
                    height: 136px;
                }
            }
        }
    }

    section.section-map {
        .map-form {
            margin-left: 45%;
        }
    }

    section.oprogramowanie-section-content {
        .content-products {
            .products-flexbox {
                .inner-service {
                    .service-category {
                        font-size: 18px;
                        min-height: 54px;
                    }
                }
            }
        }

        .content-product-service-background {
            .content-product-service {
                .service-inner {
                    .inner-sidebar {
                        width: 24%;
                    }

                    .inner-body {
                        width: 66%;
                    }
                }
            }
        }
    }

    section.formularz-section-content {
        .content-flexbox {
            .flexbox-column {
                width: 60%;
            }
        }
    }

    section.section-footer {
        .footer-flexbox {
            flex-wrap: wrap;
            justify-content: flex-start;

            .flexbox-service {
                width: 25%;

                &:nth-child(n+5) {
                    margin-top: 50px;
                }
            }
        }
    }
}

@media (max-width: 1149.98px) {
    nav.section-header {
        .header-top {
            display: none;
        }

        .header-flexbox-background {
            background: #fff;
        }

        .container-width-100 {
            padding: 0;
        }

        .header-flexbox {
            position: fixed;
            top: 0px;
            z-index: 1500;
            width: 100%;
            background-color: #fff;
            display: flex;
            padding: 20px 15px;

            .flexbox-logo {
                display: block;

                img {
                    height: 34px;
                    width: 191.34px;
                }
            }

            .nav-button {
                display: flex;
            }

            .flexbox-nav-wrapper {
                position: absolute;
                top: 80px;
                left: 0;
                width: 100%;
                background: #e3edf5;
                z-index: 999;
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s;

                .flexbox-nav-inner {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    min-height: 100%;
                    padding: 0 15px;

                    .flexbox-nav {
                        flex-direction: column;
                        align-items: flex-start;
                        overflow-y: auto;
                        max-height: calc(100vh - 140px);

                        li {
                            margin-right: 0;
                            width: 100%;

                            a,
                            .dropdown-button {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                height: 60px;
                                border-bottom: 1px solid #fff;
                                width: 100%;

                                img {
                                    margin-right: 15px;
                                }

                                .subpage-icon {
                                    height: 30px;
                                    margin-right: 5px;
                                }
                            }

                            &.display-collapsed-hide {
                                display: none;
                            }

                            &.display-collapsed-show {
                                display: block;

                                .collapse.show {
                                    border-bottom: 1px solid #fff;
                                }

                                ul {
                                    padding-left: 1.5rem;
                                }

                                .header-dropdown {
                                    position: relative;
                                    display: block;
                                    z-index: 111;
                                    visibility: visible;
                                    opacity: 1;

                                    .dropdown-inner {
                                        flex-wrap: wrap;
                                        padding: 15px;

                                        .inner-service {
                                            margin-top: 15px;
                                            height: auto;
                                            width: calc(100% / 3);
                                            border-bottom: none;

                                            .service-category {
                                                margin-top: 10px;
                                                margin-bottom: 0;
                                            }

                                            .service-name {
                                                margin-top: 0;
                                                text-transform: none;
                                            }

                                            &:hover {
                                                .service-image {
                                                    .image-product {
                                                        transform: none;
                                                    }

                                                    .image-shadow {
                                                        filter: none;
                                                    }
                                                }
                                            }

                                            &:nth-child(n+4) {
                                                margin-top: 25px;
                                            }
                                        }

                                        .divider {
                                            display: none;
                                        }
                                    }

                                    &:after {
                                        content: none;
                                    }
                                }
                            }

                            &:last-child {

                                a,
                                .dropdown-button {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -30px;
                    left: 0;
                    width: 100%;
                    height: 30px;
                    opacity: 0.25;
                    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
                    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
                    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
                }

                &.active {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .header-dropdown {
            display: none;
        }
    }
}

@media (max-width: 1049.98px) {
    section.section-footer {
        .footer-top {
            .top-service {
                display: flex;
                flex-direction: column;

                .inner-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                .service-heading {
                    margin-bottom: 0;
                }

                .service-item {
                    margin-top: 10px;
                }
            }

            .no-break {
                display: block;
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .body-wrapper {
        margin-top: 80px;

        .office-img {
            background: url(../../files/dynamic/media/100642488_profektus-sklep-mobile.jpg) 20% center no-repeat !important;
        }
    }

    section.section-offer {
        .offer-service {
            min-height: 29vh;
            display: flex;
            align-items: center;

            .inner-wrapper {

                .service-background {
                    background: url(../img/pictures/oprogramowanie-dla-firm-mobile.jpg) center 15% no-repeat !important;
                    background-size: cover !important;
                }
            }

            .service-inner {
                padding: 50px 0;
                text-align: center;

                p {
                    display: none;
                }

                .btn {
                    margin-top: 30px;
                }
            }

            .service-cover {
                width: 100%;
                left: 0;
                background: rgba(227, 237, 245, 0.75);
            }

            .service-background {
                width: 100%;
                background-position: center 20% !important;
            }
        }

        .offer-flexbox-wrapper {
            .offer-flexbox-backgrounds {
                flex-direction: column;

                a:nth-of-type(1) {
                    .service-image {
                        background: url(../img/pictures/oprogramowanie-dla-przychodni-aptek-mobile.jpg) center 30% no-repeat !important;
                        background-size: cover !important;
                    }
                }

                a:nth-of-type(2) {
                    .service-image {
                        background: url(../img/pictures/opieka-informatyczna-mobile.jpg) center 15% no-repeat !important;
                        background-size: cover !important;
                    }
                }

                .background-service {
                    width: 100%;
                    height: 50%;

                    .service-cover {
                        width: 100%;
                        left: 0%;
                    }

                    .service-image {
                        width: 100%;
                        right: 0;
                        background-position: center 25% !important;
                    }

                    &:nth-child(1) {
                        .service-cover {
                            background: rgba(111, 154, 197, 0.75);
                        }
                    }

                    &:nth-child(2) {
                        background: rgba(0, 80, 140, 1);

                        .service-cover {
                            background: rgba(0, 80, 140, 0.75);
                        }
                    }
                }
            }

            .offer-flexbox {
                flex-direction: column;
                display: block;

                @supports(display: flex) {
                    display: flex;
                }

                .flexbox-service {
                    padding: 50px 15px;
                    min-height: 29vh;
                    width: 100%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    h2 {
                        width: 100%;
                    }

                    p {
                        width: 100%;
                        display: none;
                    }

                    .btn {
                        margin-top: 30px;
                    }
                }
            }
        }

        .offer-chevron {
            left: 0;
            transform: none;
        }
    }

    section.section-about {
        .about-flexbox {
            flex-wrap: wrap;
            justify-content: center;
            display: block;

            @supports(display: flex) {
                display: flex;
            }

            .flexbox-service {
                width: 49.5%;
                display: inline-block;

                @supports(display: flex) {
                    width: 36%;
                    display: flex;
                }

                .service-square {
                    margin: 0 auto;
                }

                .service-name {
                    margin-top: 35px;
                }

                &:nth-child(n+3) {
                    margin-top: 50px;
                }
            }
        }
    }

    section.section-comarch {
        border-bottom: 1px solid @color2;

        .comarch-flexbox {
            flex-direction: column;
            justify-content: center;

            .flexbox-image {
                display: none;
            }

            .flexbox-description {
                width: 100%;
                max-width: 530px;
                text-align: center;
            }
        }
    }

    section.section-map {
        padding: 0;
        display: flex;
        flex-direction: column-reverse;

        .localisation-map {
            top: auto;
            height: 60vh;
            left: auto;
            width: 100%;
            position: relative;

            &.map-index {
                background: url(../../img/backgrounds/mapa-profektus-mobile.jpg) 15% center;
            }
        }

        .map-form {
            padding-top: 75px;
            padding-bottom: 75px;
            margin-top: 0;
            margin-left: 0;
        }
    }

    .content-welcome {
        text-align: center;

        .welcome-inner {
            p {
                width: 100%;
            }
        }

        .welcome-cover {
            width: 100% !important;
            left: 0 !important;
            right: 0 !important;
            background: rgba(227, 237, 245, 0.75);
        }

        .welcome-background {
            width: 100% !important;
            left: 0 !important;
            right: 0 !important;
            background-position: center !important;
        }
    }

    section.o-firmie-section-content {
        .content-advantages {
            .container {
                max-width: 100%;
            }
        }

        .content-about {
            .offset-anchor-span {
                top: -80px;
            }
        }
    }

    section.oprogramowanie-section-content {
        .content-welcome {
            .welcome-background {
                background-position: center top !important;
            }
        }

        .content-products {
            .products-flexbox {
                .inner-service {
                    width: 25%;
                }
            }
        }

        .content-product-service-background {
            text-align: center;

            .content-product-service {
                .service-heading {
                    max-width: 700px;
                    margin: 0 auto;

                    ul {
                        margin-top: 0;
                        margin-left: 30px;

                        li {
                            padding-left: 0;
                            display: flex;
                            // align-items: center;
                            // justify-content: center;
                            align-items: flex-start;
                            justify-content: flex-start;
                            text-align: left;

                            &:before {
                                min-width: 10px;
                                margin-right: 10px;
                                display: block;
                                position: relative;
                                left: auto;
                                // top: auto;
                            }
                        }

                        &:first-child {
                            margin-top: 15px;
                        }
                    }

                    .heading-list {
                        display: block;

                        >* {
                            margin-right: 0;
                        }
                    }
                }

                .service-inner {
                    flex-direction: column;
                    align-items: center;

                    .inner-sidebar {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .sidebar-image {
                            margin: 0 50px 0 0;
                            max-width: 157px;
                        }

                        .sidebar-buttons {
                            display: flex;
                            flex-direction: column;

                            .btn {
                                margin-top: 10px;

                                &:nth-child(1) {
                                    margin-top: 0;
                                }
                            }
                        }
                    }

                    .inner-body {
                        width: 100%;
                        max-width: 700px;

                        .body-inner {
                            margin-top: 20px;
                            grid-gap: 20px;

                            .body-service {
                                margin-top: 30px;
                            }
                        }

                        .collapse-inner {
                            margin-top: 20px;
                        }
                    }
                }
            }

            .offset-anchor-span {
                top: -80px;
            }
        }
    }

    section.outsourcing-section-content {

        .content-advantages {
            .advantage-service {
                width: calc(100% / 3);

                img {
                    height: 65px;
                }
            }
        }

        .content-numbers {
            .numbers-flexbox {
                flex-wrap: wrap;
                justify-content: center;

                .flexbox-service {
                    width: 30%;

                    .service-shadow {
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    &:nth-child(4),
                    &:nth-child(5) {
                        margin-top: 50px;
                    }
                }
            }
        }

        .content-offer {
            overflow: hidden;

            .offer-flexbox {
                flex-direction: column;

                .flexbox-service {
                    margin-top: 50px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .offset-anchor-span {
                        top: -80px;
                    }

                    .service-image {
                        margin: 0 100px 0 0;
                    }

                    .service-name {
                        margin-top: 0;
                        text-align: left;
                    }

                    .service-description {
                        margin-top: 20px;
                        // padding: 0;
                        // background: none;
                        // border: none;

                        .btn {
                            width: 100%;

                            .text {
                                justify-content: center;
                            }
                        }

                        &:after {
                            content: none;
                        }
                    }

                    &.service-highlight {
                        .service-name {
                            transform: none;
                        }

                        // .service-description {
                        //     // background: none;
                        //     // border: none;
                        // }
                    }
                }
            }
        }
    }

    section.formularz-section-content {
        .content-flexbox {
            .flexbox-column {
                width: 80%;
            }
        }
    }
}

@media (max-width: 849.98px) {

    section.o-firmie-section-content {
        .welcome-background {
            background: url(../../img/pictures/o-firmie-powitanie-mobile.jpg) 50% center no-repeat !important;
            background-size: cover !important;
        }
    }

    section.section-footer {
        .footer-top {
            flex-wrap: wrap;
            justify-content: flex-start;

            .top-heading {
                width: 100%;
            }

            .top-service {
                width: 40%;
                margin-top: 30px;

                &:nth-child(3) {
                    width: 60%;
                }
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

    nav.section-header {
        .header-flexbox {
            .flexbox-nav-wrapper {
                .flexbox-nav-inner {
                    .flexbox-nav {
                        li {
                            &.display-collapsed-show {
                                .header-dropdown {
                                    .dropdown-inner {
                                        .inner-service {
                                            width: 50%;

                                            &:nth-child(n+3) {
                                                margin-top: 25px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section.section-about {
        .about-flexbox {
            .flexbox-service {
                width: 48%;

                @supports(display: flex) {
                    width: 50%;
                }
            }
        }
    }

    section.section-testimonials {
        .owl-carousel-testimonials-wrapper {
            padding: 0;
        }
    }

    section.o-firmie-section-content {
        .welcome-background {
            background: url(../../img/pictures/o-firmie-powitanie-mobile.jpg) 50% center no-repeat !important;
            background-size: cover !important;
        }

        .content-advantages {
            .advantages-flexbox {
                .advantage {
                    width: calc(100% / 2 - 15px);

                    &:nth-last-child(-n + 2) {
                        justify-self: unset;
                        margin-left: 0;
                        margin-right: 0;
                    }

                    &:nth-last-child(-n + 1) {
                        justify-self: center;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }

        .content-about-flexbox {
            flex-direction: column;

            .flexbox-service {
                width: 100%;

                &:not(:first-child) {
                    margin-top: 40px;
                } 
            }
        }

        .content-company-activities {
            .content-wrapper {

                .activities-wrapper {
                    flex-direction: column;
    
                    .flexbox-service {
                        width: 100%;
    
                        &:nth-of-type(2) {
                            margin-top: 20px;
                        }
                    }
                } 
            }
        }
    }

    section.oprogramowanie-section-content {
        .welcome-background {
            background: url(../../img/pictures/oprogramowanie-dla-firm-mobile.jpg) 50% center no-repeat !important;
            background-size: cover !important;
        }

        .content-products {
            .container {
                max-width: 100%;
            }

            .products-flexbox {
                display: block;

                @supports(display: grid) {
                    justify-content: space-around;
                    flex-wrap: wrap;
                }

                .inner-service {
                    display: block;
                    width: 75%;
                    margin-top: 50px;
                    margin-left: auto;
                    margin-right: auto;

                    &:first-child {
                        margin-top: 0;
                    }

                    .service-category {
                        font-size: 18px;
                        min-height: auto;
                    }

                    .service-image {
                        margin-top: 20px;
                        width: 120px;
                    }

                    .service-name {
                        margin-top: 20px;
                        font-size: 18px;
                    }

                    // .service-description {
                    //                 // display: none;
                    // }

                    .btn {
                        margin-top: 20px;
                    }
                }
            }
        }

        .content-product-service-background {
            .content-product-service {
                .service-inner {
                    .inner-body {
                        .body-inner {
                            // grid-template-columns: 1fr 1fr;
                            display: block;


                            .body-service {
                                display: inline-block;
                                width: 46%;
                                margin-left: 1%;
                                margin-right: 1%;
                                vertical-align: top;

                                &:last-child {
                                    float: left;
                                }

                                &.hidden-md {
                                    display: none;
                                }
                            }
                        }

                        .collapse-inner {
                            .body-service {
                                &:first-child {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section.outsourcing-section-content {
        .welcome-background {
            background: url(../img/pictures/opieka-informatyczna-mobile.jpg) center 15% no-repeat !important;
            background-size: cover !important;
        }

        .content-numbers {
            .numbers-flexbox {
                justify-content: space-around;

                .flexbox-service {
                    width: 36%;

                    &:nth-child(n+3) {
                        margin-top: 50px;
                    }
                }
            }
        }

        .content-offer {
            .offer-flexbox {
                .flexbox-service {
                    display: block;

                    .service-image {
                        margin: 0 auto;
                    }

                    .service-name {
                        margin-top: 30px;
                        text-align: center;
                    }

                    .service-description {
                        max-width: 400px;
                        margin-top: 20px;
                        margin-left: auto;
                        margin-right: auto;
                        // padding: 0;
                        // background: none;
                        // border: none;

                        .btn {
                            width: 100%;
                            max-width: 290px;
                        }
                    }
                }
            }

            .offer-cta {
                .btn {
                    width: 100%;
                    max-width: 290px;

                    .text {
                        justify-content: center;
                    }
                }
            }
        }
    }

    .instalacje-section-content {
        .welcome-background {
            background: url(../../img/pictures/instalacje-powitanie-mobile.jpg) center 15% no-repeat !important;
            background-size: cover !important;
        }
    }

    section.sklep-section-content {
        .welcome-background, .content-shop {
            background: url(../../img/pictures/profektus-sklep-mobile.jpg) center no-repeat!important;
            background-size: cover!important;
            }
        }
    
    .serwis-section-content {
            .welcome-background {
            background: url(../../img/pictures/serwis-komputerow-i-pomoc-techniczna-powitanie-mobile.jpg) 20% center no-repeat!important;
            background-size: cover!important;
            }   
        }
    
    .kontakt-section-content {
        .welcome-background {
            background: url(../../img/pictures/kontakt-powitanie-mobile.jpg) 60% center no-repeat !important;
            background-size: cover !important;
        }
    }

    section.section-footer {
        .footer-flexbox {
            .flexbox-service {
                width: 40%;

                &:nth-child(n+3) {
                    margin-top: 40px;
                }
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .g-recaptcha {
        transform: scale(0.75);
        transform-origin: 0 0;
    }

    section.section-offer {
        .offer-service {
            .service-inner {
                h1 {
                    font-size: 24px;
                }
            }
        }

        .offer-flexbox-wrapper {
            .offer-flexbox {
                .flexbox-service {
                    h2 {
                        font-size: 24px;
                    }
                }
            }
        }

        .offer-chevron {
            left: 0;
            transform: none;
        }
    }

    .content-welcome {
        min-height: 250px;
        text-align: center;

        .welcome-inner {
            h1 {
                font-size: 30px;
            }

            p {
                font-size: 15px;
            }
        }
    }

    section.o-firmie-section-content {
        .content-advantages {
            .advantages-flexbox {
                .advantage {
                    h3 {
                        height: auto;
                    }

                    p {
                        margin-top: 25px;
                    }
                }
            }
        }

        .content-about {
            text-align: center;

            h2 {
                font-size: 24px;
            }
        }

        .content-company-activities {
            padding-bottom: 60px;

            .content-wrapper {
                .content-heading {
                    h2 {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    section.oprogramowanie-section-content {

        .content-products {
            .products-flexbox {
                display: block;

                @supports(display: grid) {
                    display: grid;
                }

                .inner-service {
                    width: 100%;

                    .service-category {
                        font-size: 16px;
                        // min-height: 48px;
                    }

                    .service-image {
                        margin-top: 15px;
                        width: 80px;
                    }

                    .service-name {
                        margin-top: 15px;
                        font-size: 16px;
                    }

                    .btn {
                        margin-top: 15px;
                        font-size: 12px;
                        padding: 0 40px 0 10px;
                        height: 30px;

                        .arrow {
                            height: 30px;
                            width: 30px;

                            img {
                                height: 10px;
                            }
                        }
                    }
                }
            }
        }

        .content-product-service-background {
            .content-product-service {
                .service-heading {
                    h2 {
                        font-size: 24px;
                    }

                    p {
                        margin-top: 10px;
                        font-size: 15px;
                    }

                    ul {
                        li {
                            font-size: 15px;
                        }
                    }
                }

                .service-inner {
                    .inner-sidebar {
                        flex-direction: column;

                        .sidebar-image {
                            margin: 0;
                        }

                        .sidebar-buttons {
                            margin-top: 50px;
                        }
                    }

                    .inner-body {
                        .body-inner {
                            .body-service {
                                img {
                                    height: 45px;
                                    width: 45px;
                                }

                                h3 {
                                    font-size: 14px;
                                }

                                p {
                                    font-size: 13px;
                                }

                                .service-more {
                                    .more-icon {
                                        height: 45px;

                                        .arrow {
                                            height: 30px;
                                            width: 30px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section.outsourcing-section-content {
        .content-advantages {
            .advantage-service {
                width: calc(100% / 2);

                img {
                    height: 65px;
                }
            }
        }

        .content-offer {
            .offer-heading {
                h2 {
                    font-size: 24px;
                }
            }

            .offer-cta {
                h3 {
                    font-size: 20px;
                }
            }
        }
    }

    section.section-footer {
        .footer-top {
            .top-service {
                &:nth-child(3) {
                    padding-left: 20px;

                    .service-item {
                        .item-clock-inner {
                            align-items: flex-start;

                            img {
                                transform: translateY(2px);
                            }

                            .inner-helper {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        .footer-flexbox {
            padding: 40px 0;
            display: block;

            .flexbox-service {
                width: 100%;
                text-align: center;

                li {
                    &.heading {
                        a {
                            pointer-events: auto;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            &:after {
                                margin-left: 10px;
                                content: '';
                                background: url(../img/icons/arrow-right-white.svg) center;
                                background-size: cover;
                                display: block;
                                height: 8px;
                                min-width: 8px;
                                transform: rotate(90deg);
                            }
                        }
                    }

                    a {
                        padding-left: 0;

                        &:before {
                            content: none;
                        }
                    }
                }

                &:nth-child(n+2) {
                    margin-top: 20px;
                }
            }
        }

        .footer-credits {
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;

            .credits-webemo {
                margin-top: 30px;

                p {
                    margin-right: 10px;
                }

                img {
                    height: 20px;
                }
            }
        }
    }
}

@media (max-width: 460px) {
    section.section-map {
        .map-form {
            .recaptcha-wrapper {
                display: block;

                .btn {
                    margin-top: 5px;
                    min-width: 50%;

                    .text {
                        display: inline-block;
                        line-height: 40px;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }

    section.oprogramowanie-section-content {
        .content-product-service-background {
            .content-product-service {
                .service-inner {
                    .inner-body {
                        .body-inner {
                            margin-top: 30px;

                            .body-service {
                                margin-top: 30px;

                                h3 {
                                    font-size: 11px;
                                }

                                // p {
                                //     // display: none;
                                // }
                            }
                        }

                        .collapse-inner {
                            margin-top: 20px;
                        }
                    }
                }

                .service-heading {
                    ul {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    section.section-footer {
        .footer-top {
            display: flex;
            flex-direction: column;
            align-items: center;

            .top-heading {
                text-align: center;

                p {
                    white-space: normal;
                    font-size: 18px;
                }
            }

            .top-service {
                display: block;
                text-align: left;
                width: 100%;

                >* {
                    margin-right: 0;
                }

                .inner-wrapper {
                    display: block;

                    >* {
                        margin-right: 0;
                    }
                }

                .service-heading {
                    text-align: center;
                }

                .service-item {
                    text-align: left;
                    justify-content: flex-start;

                    .item-clock-inner {
                        justify-content: flex-start;

                        .inner-helper {
                            display: block;
                        }
                    }
                }

                .service-heading,
                .item-clock {
                    display: block;
                }

                &:nth-child(2) {
                    max-width: 230px;
                    width: 100%;

                }

                &:nth-child(3) {
                    width: 100%;
                    max-width: 230px;
                    padding-left: 0;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    nav.section-header {
        .header-dropdown {
            .dropdown-inner {
                .inner-service {
                    .service-category {
                        min-height: 42px;
                    }

                    .service-name {
                        min-height: 42px;
                    }
                }
            }
        }
    }

    section.section-about {
        padding: 50px 0;

        .about-flexbox {
            margin-top: 50px;
            display: block;

            @supports(display: flex) {
                display: flex;
            }

            .flexbox-service {
                width: 100%;

                &:nth-child(n+2) {
                    margin-top: 30px;
                }
            }
        }
    }

    section.section-testimonials {
        padding: 50px 0;

        .owl-carousel-testimonials-wrapper {
            .owl-carousel-testimonials {
                margin-top: 50px;

                .owl-controls {
                    margin-top: 50px;
                }
            }
        }

        .testimonials-cta {
            margin-top: 50px;
        }
    }

    section.section-comarch {
        padding: 50px 0;
    }

    section.section-map {
        .map-form {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    section.o-firmie-section-content {
        .content-advantages {
            .advantages-flexbox {
                padding-bottom: 50px;

                .advantage {
                    width: 100%;
                    margin: 50px 0 0 0;
                }
            }
        }

        .content-about {
            padding: 50px 0;

            p {
                margin-top: 30px;

                &:first-child {
                    margin-top: 30px;
                }
            }
        }
    }

    section.oprogramowanie-section-content {
        .content-products {
            padding: 50px 0;

            .products-flexbox {
                .inner-service {
                    // width: 30%;

                    .service-category {
                        font-size: 14px;
                        // min-height: 43px;
                    }

                    .service-image {
                        width: 60px;
                    }

                    .service-name {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    section.outsourcing-section-content {
        .content-advantages {
            padding: 10px 0 50px 0;
            display: block;

            @supports(display: flex) {
                display: flex;
            }

            .advantage-service {
                width: 100%;

                img {
                    height: 50px;
                }
            }
        }

        .content-numbers {
            padding: 50px 0;

            .numbers-flexbox {
                display: block;

                @supports(display: flex) {
                    display: flex;
                }

                .flexbox-service {
                    width: 100%;

                    &:nth-child(n+2) {
                        margin-top: 30px;
                    }
                }
            }
        }

        .content-offer {
            padding: 50px 0;
        }
    }

    .teamviewer-button {
        display: none;
    }
}

@media (max-width: 359px) {
    nav.section-header {
        .header-flexbox {
            .flexbox-logo {
                img {
                    height: 28px;
                    width: 157.55px;
                }
            }
        }

        .header-dropdown {
            .dropdown-inner {
                .inner-service {
                    width: 100% !important;

                    .service-category {
                        min-height: unset;
                    }

                    .service-name {
                        min-height: unset;
                    }
                }
            }
        }
    }
}

@media (max-width: 315px) {
    .g-recaptcha {
        transform: scale(0.60);
        transform-origin: 0 0;
    }
}