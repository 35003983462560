@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,900&subset=latin-ext&display=fallback');
@import url('https://fonts.googleapis.com/css?family=Oswald:300&subset=latin-ext&display=fallback');

// :target:before {
// 	content:"anchorBefore";
// 	color: rgba(0, 0, 0, 0);
// 	display:block;
// 	height: 80px; /* fixed header height*/
// }

body {
    min-height: 100vh;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.no-scroll {
        overflow: hidden;
        max-height: 100vh;
    }

    .btn {
        font-size: 14px;
        font-weight: 600;
        border-radius: 0;
        border: none;
        padding: 0 55px 0 15px;
        height: 40px;
        position: relative;
        text-transform: lowercase;

        .text {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .arrow {
            position: absolute;
            top: 0;
            right: 0;
            height: 40px;
            width: 40px;
            background: #cd191e;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s;

            img {
                height: 12px;
                width: 12px;
            }
        }

        &.btn-default {
            color: #fff;
            background: @color1;
            transition: all 0.3s;

            &:hover {
                background: #cd191e;

                .arrow {
                    background: @color1;
                }
            }
        }

        &.btn-gray {
            color: @color1;
            background: #e6e6e6;
            transition: all 0.3s;

            .arrow {
                background: #d2d2d2;
            }

            &:hover {
                background: #d2d2d2;

                .arrow {
                    background: #e6e6e6;
                }
            }
        }

        &.btn-small {
            font-size: 12px;
            padding: 0 40px 0 10px;
            height: 30px;

            .arrow {
                height: 30px;
                width: 30px;

                img {
                    height: 10px;
                }
            }
        }
    }


    ul,
    ol,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none !important;
    }

    .container-fluid {
        max-width: 1850px;

        &.width-1366 {
            max-width: 1230px;
        }
    }

    :focus,
    :active {
        outline: 0 none;
    }
}

@color1: #ed1d24;
@color2: #00508c;
@color3: #00beff;

.system-alerts {
    width: 100%;
    text-align: center;

    .alert {
        border-radius: 0;
        border: none;
        height: 60px;
        display: flex;
        align-items: center;
        opacity: 0.9;
        transition: all 0.4s;

        .alert-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .img-hidden {
                visibility: hidden;
                opacity: 0;
                height: 18px;
            }

            p {
                color: #fff;
                padding: 0 50px;
            }

            button {
                color: #fff;
                opacity: 1;
                font-weight: 300;
                float: none;

                img {
                    height: 18px;
                }
            }
        }

        &.alert-success {
            background: #76ad21;
        }

        &.alert-info {
            background: #218ead;
        }

        &.alert-warning {
            background: #c52b20;
        }
    }
}

.alert-message {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;

    img {
        width: 60px;
    }

    h3 {
        margin-top: 30px;
        font-size: 28px;
        font-weight: 700;
        color: @color2;

        span {
            font-size: 18px;
            font-weight: 300;
        }
    }

    p {
        font-size: 14px;
        margin-top: 30px;
    }

    .btn {
        margin-top: 30px;
    }

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.modal-service-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: rgba(0, 0, 0, 0.5);
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    .modal-service {
        padding: 50px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100%;

        .service-inner {
            background: #fff;
            width: 700px;
            max-width: 100%;
            padding: 50px 25px 25px 25px;
            text-align: center;
            position: relative;

            .service-button {
                position: absolute;
                top: 20px;
                right: 20px;
                border: none;
                background: none;
                padding: 15px;
                display: flex;
                justify-content: center;
                align-items: center;

                .button-bar {
                    display: block;
                    position: absolute;
                    width: 20px;
                    height: 2px;
                    background: #222;

                    &:nth-child(1) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(2) {
                        transform: rotate(-45deg);
                    }
                }
            }

            .service-content {
                .heading {
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                }

                .btn {
                    display: flex;
                    justify-content: center;
                    margin-top: 5px;
                }
            }

            .service-buttons {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .btn-link {
                    padding: 0;
                    text-transform: uppercase;
                    font-weight: 700;
                    text-decoration: none;
                }
            }
        }
    }
}

#return-to-top {
    position: fixed;
    z-index: 44;
    bottom: 15px;
    right: 15px;
    transition: all 0.3s;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: @color1;
    transition: all 0.25s;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;

    img {
        height: 15px;
        width: 15px;
    }

    &.active {
        opacity: 0.5;
        visibility: visible;
    }

    &.near-bottom {
        bottom: 110px;
    }

    &:hover {
        opacity: 1;
    }
}

nav.section-header {
    .header-top {
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top-logo {
            display: flex;
            align-items: center;

            .logo-image {
                img {
                    height: 50px;
                    width: 100%;
                    min-width: 175px;
                }
            }

            .logo-slogan {
                display: flex;
                align-items: center;

                img {
                    height: 43px;
                    margin: 0 20px 0 15px;
                }

                p {
                    text-transform: uppercase;
                    font-size: 14px;
                    color: #000;
                    font-weight: 500;
                    white-space: nowrap;

                    strong {
                        color: @color1;
                    }
                }
            }
        }

        .top-menu {
            display: flex;
            align-items: center;

            li {
                a {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    font-weight: 700;
                    color: @color2;
                    font-size: 14px;
                    transition: all 0.3s;

                    img {
                        width: 30px;
                    }

                    &:hover {
                        color: @color3;
                    }
                }

                &:nth-child(1) {
                    a {
                        img {
                            margin-right: 15px;
                        }
                    }
                }

                &:nth-child(3) {
                    a {
                        img {
                            margin-left: 15px;
                        }
                    }
                }

                &.divider {
                    height: 20px;
                    width: 1px;
                    background: #ddd;
                    margin: 0 20px;
                }
            }
        }
    }

    .header-flexbox-background {
        background: #f1f6fa;
    }

    .header-flexbox {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .flexbox-logo {
            display: none;
        }

        .flexbox-nav-wrapper {
            .flexbox-nav-inner {
                .flexbox-nav {
                    display: flex;
                    align-items: center;

                    li {
                        margin-right: 45px;

                        a {
                            display: flex;
                            text-transform: uppercase;
                            font-weight: 700;
                            color: @color2;
                            font-size: 14px;
                            cursor: pointer;
                            transition: all 0.3s;


                            &:hover,
                            &.active {
                                color: @color1;
                            }

                            &.chevron-rotate {
                                img {
                                    transform: rotate(180deg);
                                }
                            }
                        }

                        .dropdown-button {
                            display: flex;
                            align-items: center;
                            white-space: nowrap;

                            img {
                                height: 5px;
                                margin-left: 10px;
                                transition: all 0.3s;
                            }
                        }

                        .dropdown {
                            .dropdown-menu {
                                margin-top: 12px;
                                padding: 10px 10px;
                                min-width: 12rem;
                                background: #e3edf6;
                                border: 1px solid #c0cad3;

                                .dropdown-item {
                                    padding: 10px;
                                    color: @color3;

                                    &:hover {
                                        background: none;
                                        color: @color1;
                                    }
                                }
                            }
                        }

                        &.display-collapsed-show {
                            display: none;
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .nav-button {
            cursor: pointer;
            padding: 10px;
            margin-right: -10px;
            border: none;
            background: none;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            outline: none;
            display: none;

            .button-bar {
                display: block;
                height: 2px;
                width: 32px;
                background-color: @color2;
                margin-bottom: 7px;
                transition: all .7s ease;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.active {
                .button-bar {
                    &:nth-child(1) {
                        transform: translateY(9px) rotateZ(45deg);
                    }

                    &:nth-child(2) {
                        width: 0;
                    }

                    &:nth-child(3) {
                        transform: translateY(-9px) rotateZ(-45deg);
                    }
                }
            }
        }
    }

    .header-dropdown {
        position: absolute;
        z-index: 111;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s;

        .dropdown-inner {
            background: #e3edf6;
            padding: 25px;
            display: flex;
            justify-content: space-around;

            .inner-service {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;

                .service-category {
                    margin-bottom: 30px;
                    text-transform: uppercase;
                    font-weight: 700;
                    color: @color3;
                    font-size: 14px;
                }

                .service-image {
                    position: relative;
                    display: block;

                    .image-product {
                        height: 155px;
                        position: relative;
                        z-index: 111;
                        transition: all 0.3s;
                    }

                    .image-shadow {
                        position: absolute;
                        bottom: -3px;
                        right: 0;
                        width: 150%;
                        transition: all 0.3s;
                    }

                    &:hover {
                        .image-product {
                            transform: translateY(-10px);
                        }

                        .image-shadow {
                            filter: blur(5px);
                        }

                        &+.service-name {
                            color: @color1;
                        }
                    }
                }

                .service-name {
                    margin-top: 30px;
                    font-weight: 700;
                    color: @color2;
                    font-size: 14px;
                    transition: all 0.3s;

                    &:hover {
                        color: @color1;
                    }
                }


            }

            .divider {
                height: 155px;
                width: 1px;
                background: #fff;
                align-self: center;
            }
        }

        &.active {
            opacity: 1;
            visibility: visible;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -30px;
            left: 0;
            width: 100%;
            height: 30px;
            opacity: 0.25;
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
        }
    }
}

section.section-offer {
    position: relative;

    .offer-service {
        background: #e3edf5;
        display: block;
        position: relative;
        overflow: hidden;

        .service-inner {
            padding: 135px 50px;
            position: relative;

            h1 {
                color: @color2;
                font-weight: 700;
                font-size: 32px;
                transition: all 0.3s;
            }

            p {
                font-size: 20px;
                color: @color2;
                font-weight: 500;
                margin: 25px 0;
            }
        }

        .inner-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 0.3s;
        }

        .service-cover {
            position: absolute;
            top: 0;
            height: 100%;
            width: 40%;
            left: 39.88%;
            background: -moz-linear-gradient(left, rgba(227, 237, 245, 1) 0%, rgba(227, 237, 245, 0) 100%);
            background: -webkit-linear-gradient(left, rgba(227, 237, 245, 1) 0%, rgba(227, 237, 245, 0) 100%);
            background: linear-gradient(to right, rgba(227, 237, 245, 1) 0%, rgba(227, 237, 245, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6e3edf5', endColorstr='#00e3edf5', GradientType=1);
        }

        .service-background {
            position: absolute;
            height: 100%;
            width: 60%;
            right: 0;
            top: 0;
        }

        &:hover {
            .inner-wrapper {
                transform: scale(1.05);
            }
        }
    }

    .offer-flexbox-wrapper {
        position: relative;

        .offer-flexbox-backgrounds {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;

            .background-service {
                width: 50%;
                overflow: hidden;
                position: relative;

                .service-inner {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    transition: all 0.3s;
                }

                .service-cover {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 50%;
                    left: 39.88%;
                }

                .service-image {
                    position: absolute;
                    height: 100%;
                    width: 60%;
                    right: 0;
                    top: 0;
                }

                &:nth-child(1) {
                    background: #6f9ac5;

                    .service-cover {
                        background: -moz-linear-gradient(left, rgba(111, 154, 197, 1) 0%, rgba(111, 154, 197, 0) 100%);
                        background: -webkit-linear-gradient(left, rgba(111, 154, 197, 1) 0%, rgba(111, 154, 197, 0) 100%);
                        background: linear-gradient(to right, rgba(111, 154, 197, 1) 0%, rgba(111, 154, 197, 0) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6e3edf5', endColorstr='#00e3edf5', GradientType=1);
                    }
                }

                &:nth-child(2) {
                    background: #00508c;

                    .service-cover {
                        background: -moz-linear-gradient(left, rgba(0, 80, 140, 1) 0%, rgba(0, 80, 140, 0) 100%);
                        background: -webkit-linear-gradient(left, rgba(0, 80, 140, 1) 0%, rgba(0, 80, 140, 0) 100%);
                        background: linear-gradient(to right, rgba(0, 80, 140, 1) 0%, rgba(0, 80, 140, 0) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6e3edf5', endColorstr='#00e3edf5', GradientType=1);
                    }
                }

                &:hover,
                &.active {
                    .service-inner {
                        transform: scale(1.05);
                    }
                }
            }
        }

        .offer-flexbox {
            position: relative;
            display: flex;
            justify-content: space-between;

            .flexbox-service {
                width: 50%;
                padding: 98px 50px;

                h2 {
                    color: #fff;
                    font-weight: 700;
                    font-size: 32px;
                }

                p {
                    font-size: 20px;
                    color: #fff;
                    font-weight: 500;
                    margin: 25px 0;
                    width: 70%;
                }
            }
        }
    }

    .offer-chevron {
        position: absolute;
        z-index: 111;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 40px;
        width: 40px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 10px;
        }
    }
}

section.section-about {
    padding: 75px 0;

    h3 {
        color: @color2;
        font-size: 32px;
        font-weight: 700;
        text-align: center;
    }

    .about-flexbox {
        margin-top: 75px;
        display: flex;
        justify-content: center;

        .flexbox-service {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 100px;

            .service-square {
                height: 175px;
                width: 175px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: #e3edf5;
                border: 10px solid #f7fafc;
                position: relative;

                .square-icon {
                    height: 40px;
                }

                .square-number {
                    font-family: 'Oswald', sans-serif;
                    font-size: 48px;
                    font-weight: 300;
                    color: @color2;
                    letter-spacing: -4px;
                    margin: 3px 0 5px 0;
                }

                span {
                    display: block;
                    width: 20px;
                    height: 2px;
                    background: @color1;
                }

                &:after {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    box-shadow: 0 0 80px rgba(0, 0, 0, 0.8);
                    bottom: -10px;
                    width: 60%;
                    height: 50%;
                    border-radius: 100%;
                    left: 20%;
                    right: 20%;
                }
            }

            .service-name {
                margin-top: 50px;
                font-size: 20px;
                font-weight: 500;
                color: @color2;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

section.section-testimonials {
    padding: 75px 0;
    background: @color2;

    // display: none;
    h3 {
        color: #fff;
        font-size: 32px;
        font-weight: 700;
        text-align: center;
    }

    .owl-carousel-testimonials-wrapper {
        padding: 0 350px;

        .owl-carousel-testimonials {
            margin-top: 65px;

            .testimonial-item {
                text-align: center;

                .name {
                    color: #fff;
                    font-size: 16px;

                    strong {
                        color: @color3;
                        font-weight: 700;
                    }
                }

                img {
                    margin-top: 20px;
                    width: auto;
                    max-width: 100%;
                    max-height: 25px;
                    display: block;
                    margin: 20px auto 0 auto;
                }

                span {
                    margin: 20px auto;
                    display: block;
                    width: 20px;
                    height: 2px;
                    background: @color1;
                }

                .description {
                    p {
                        color: #fff;
                        font-size: 16px;
                    }
                }
            }

            .owl-controls {
                margin-top: 65px;

                .owl-nav {
                    display: none;
                }

                .owl-dots {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;

                    .owl-dot {
                        margin: 5px;

                        span {
                            margin: 0;
                            height: 15px;
                            width: 15px;
                            border-radius: 0;
                            border: 1px solid @color3;
                            background: none;
                        }

                        &.active {
                            span {
                                background: @color3;
                            }
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .testimonials-cta {
        text-align: center;
        margin-top: 65px;

        h4 {
            font-weight: 700;
            color: #fff;
            font-size: 22px;
        }

        .btn {
            margin-top: 30px;
        }
    }
}

section.section-comarch {
    padding: 75px 0;

    .comarch-flexbox {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .flexbox-image {
            width: 20%;

            img {
                width: 100%;
            }
        }

        .flexbox-description {
            width: 65%;
            text-align: center;

            h3 {
                color: @color2;
                font-size: 32px;
                font-weight: 700;
            }

            p {
                margin-top: 20px;
                font-size: 16px;
                color: #323232;
            }

            .btn {
                margin-top: 20px;
            }
        }
    }
}

section.section-map {
    padding: 75px 0;
    position: relative;

    .localisation-map {
        top: 0;
        left: -40%;
        position: absolute;
        width: 140%;
        height: 100%;

        &.map-index {
            left: 0;
            width: 100%;
            background-image: url('../img/backgrounds/mapa-profektus.jpg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    .map-form {
        padding: 30px;
        background: #fff;
        position: relative;
        margin-left: 55%;

        .form-heading {
            h3 {
                color: @color2;
                font-size: 32px;
                font-weight: 700;
                text-align: center;
                letter-spacing: -1px;
            }

            p {
                color: @color2;
                font-size: 20px;
                text-align: center;
                letter-spacing: -1px;
            }
        }

        .form-service {
            margin-top: 20px;

            .form-group {
                position: relative;
                margin-bottom: 20px;

                label {
                    font-weight: 400;
                    font-size: 11px;
                    color: #a0a0a0;
                    margin-bottom: 0;
                }

                input,
                textarea {
                    margin-top: 0;
                    border: none;
                    border-bottom: 1px solid #ddd;
                    border-radius: 0;
                    padding-left: 0;
                    padding-right: 30px;

                    &:focus {
                        color: @color2;
                        border-color: @color2;
                    }
                }

                textarea {
                    min-height: 80px;
                    max-height: 80px;
                }

                .alert-text {
                    position: absolute;
                    bottom: -20px;
                    right: 0;
                    font-size: 11px;
                    color: @color1;
                }

                &.required {
                    &:after {
                        content: '*';
                        font-weight: 700;
                        color: @color1;
                        position: absolute;
                        top: 33px;
                        right: 5px;
                        z-index: 111;
                    }

                    &.correct {
                        &:after {
                            color: #15a529;
                        }
                    }
                }
            }

            input[type="text"],
            input[type="email"],
            textarea {
                box-shadow: none;
            }

            textarea {
                min-width: 100%;
                max-width: 100%;
                height: 180px;
            }
        }

        .checkbox-service {
            position: relative;
            margin-top: 20px;
            margin-bottom: 0;
            cursor: pointer;
            width: 100%;
            display: block;

            .service-inner {
                display: flex;
                flex-wrap: wrap;

                .box {
                    display: block;
                    margin-right: 10px;
                    height: 15px;
                    min-width: 15px;
                    max-width: 15px;
                    background: #f0f0f0;
                    border: 1px solid #e1e1e1;
                    position: relative;
                    transition: all 0.2s;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        height: 104%;
                        width: 104%;
                        background: @color3;
                        transform: translate(-50%, -50%) scale(0);
                        transition: all 0.2s;
                    }
                }

                .name {
                    display: block;
                    text-align: left;
                    font-weight: 400;
                    font-size: 11px;
                    color: #a0a0a0;
                    width: calc(100% - 25px);
                }
            }

            .btn-more {
                font-size: 11px;
                font-weight: 700;
                display: block;
                margin-top: 3px;
                margin-left: 25px;
                color: @color3;

                &:before {
                    content: "więcej";
                }

                &.active {
                    &:before {
                        content: none;
                    }
                }
            }

            .collapse-inner {
                display: block;
                margin-left: 27px;

                span {
                    display: block;
                    text-align: left;
                    font-weight: 400;
                    font-size: 11px;
                    color: #a0a0a0;
                }

                &.active {
                    span {
                        &:after {
                            content: " *";
                            color: @color1;
                        }
                    }
                }
            }

            &.more-hidden {
                .service-inner {
                    .name {
                        &:after {
                            content: "... *";
                            color: @color1;
                        }
                    }
                }

                &.more-visible {
                    .service-inner {
                        .name {
                            &:after {
                                content: '.';
                                color: #a0a0a0;
                            }
                        }
                    }
                }
            }

            .alert-text {
                font-size: 11px;
                color: @color1;
            }

            input {
                visibility: hidden;
                position: absolute;
            }
        }

        .checkbox-service>input:checked+.service-inner {
            .box {
                &:before {
                    transform: translate(-50%, -50%) scale(1);
                }
            }
        }

        .recaptcha-wrapper {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .required-info {
            margin-top: 10px;

            p {
                font-size: 13px;

                strong {
                    color: @color1;
                    font-weight: 900;
                    margin-right: 10px;
                    top: 5px;
                    font-size: 18px;
                    position: relative;
                }
            }
        }
    }
}

.content-welcome {
    background: #e3edf5;
    min-height: 340px;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;

    .welcome-inner {
        padding: 50px 0;
        position: relative;

        h1 {
            color: @color2;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 32px;
            transition: all 0.3s;
        }

        p {
            font-size: 20px;
            color: @color2;
            font-weight: 400;
            margin-top: 25px;
            width: 50%;
        }

        .btn {
            margin-top: 30px;
        }
    }

    .welcome-cover {
        position: absolute;
        top: 0;
        height: 100%;
        background: -moz-linear-gradient(left, rgba(227, 237, 245, 1) 0%, rgba(227, 237, 245, 0) 100%);
        background: -webkit-linear-gradient(left, rgba(227, 237, 245, 1) 0%, rgba(227, 237, 245, 0) 100%);
        background: linear-gradient(to right, rgba(227, 237, 245, 1) 0%, rgba(227, 237, 245, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6e3edf5', endColorstr='#00e3edf5', GradientType=1);
    }

    .welcome-background {
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;
    }
}

section.o-firmie-section-content {
    .content-welcome {
        .welcome-cover {
            width: 50%;
            left: 20%;
        }

        .welcome-background {
            width: 80%;
        }
    }

    .content-advantages {
        .advantages-flexbox {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0 0 75px 0;

            .advantage {
                text-align: center;
                width: calc(100% / 3 - 15px);
                max-width: 370px;
                margin: 70px 0 0 0;
                overflow: hidden;

                &:nth-last-child(-n + 2) {
                    justify-self: center;
                    margin-left: auto;
                    margin-right: auto;
                }

                img {
                    width: 100%;
                    object-fit: cover;
                }

                h3 {
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: 700;
                    color: #00508c;
                    margin: 25px auto 0;
                    letter-spacing: -0.4px;
                    height: calc(4 * 16px * 1.2);
                }

                p {
                    margin-top: 5px;
                    font-size: 14px;
                    color: #323232;
                }
            }
        }
    }

    .content-consult {
        padding: 50px 0;
        background-color: @color2;
        color: #fff;

        a {
            color: inherit;
        }

        .content-consult-inner {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h2 {
                font-size: 22px;
                font-weight: 700;
            }

            .btn {
                margin-top: 35px;
                font-size: 16px;
            }

            p {
                margin-top: 20px;
                font-size: 18px;

                a {
                    font-weight: 700;
                }
            }
        }
    }

    .content-about {
        padding: 75px 0;
        position: relative;

        h2 {
            text-align: center;
            font-weight: 700;
            text-transform: uppercase;
            color: @color2;
            font-size: 32px;
        }

        p {
            margin-top: 45px;
            line-height: 1.5;
            color: #323232;
            letter-spacing: -0.4px;

            &:first-child {
                margin-top: 60px;
            }
        }

        .offset-anchor-span {
            position: absolute;
            top: 0;
            opacity: 0;
            visibility: hidden;
        }
    }

    .content-about-flexbox {
        display: flex;
        justify-content: space-between;

        .flexbox-service {
            width: 30%;
            text-align: center;

            p {
                line-height: 1.5;
                color: #323232;
                letter-spacing: -0.4px;

                strong {
                    color: @color2;
                    font-size: 20px;
                    text-transform: uppercase;
                }
            }
        }
    }

    .content-company-activities {
        padding-bottom: 100px;

        .content-wrapper {
            padding-top: 40px;

            &:first-of-type {
                border-top: 1px solid #ddd;
            }

            .content-heading {
                h2 {
                    text-align: center;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: @color2;
                    font-size: 32px;
                }

                h3 {
                    margin-bottom: 15px;
                    font-size: 22px;
                    color: @color2;
                    text-align: center;
                    text-transform: uppercase;
                }
            }

            .activities-wrapper {
                display: flex;
                align-items: flex-start;
                padding-top: 45px;

                .flexbox-service {
                    width: 47%;

                    h3 {
                        margin-bottom: 20px;
                        font-size: 20px;
                        color: @color2;
                        text-align: center;
                        text-transform: uppercase;
                    }

                    p {
                        margin-left: 18px;
                    }

                    ul {
                        padding-left: 18px;

                        li {
                            padding: 5px 0;
                        }
                    }

                    .images {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        a {
                            width: 32%;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }

                    &:nth-of-type(2) {
                        .images {
                            a {
                                width: 40%;
                            }
                        }
                    }
                }
            }

            .content-description-wrapper {
                display: flex;
                flex-direction: column;

                .flexbox-description {
                    width: 100%;
                    text-align: center;
                }

                .flexbox-gallery {
                    padding-top: 40px;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .item {
                        width: 33%;

                        img {
                            width: 100%;
                            object-fit: cover;
                            object-position: top center;
                        }
                    }
                }
            }
        }
    }
}

section.o-firmie-section-cooperate {
    .owl-carousel-cooperate {
        padding: 30px 0;

        .owl-stage-outer {
            .owl-stage {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .owl-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 20px;

        .owl-dot {
            margin: 5px;

            span {
                margin: 0;
                height: 15px;
                width: 15px;
                border-radius: 0;
                border: 1px solid @color3;
                background: none;
            }

            &.active {
                span {
                    background: @color3;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}


section.oprogramowanie-section-content {
    position: relative;

    .content-welcome {
        .welcome-cover {
            width: 20%;
            left: 50%;
        }

        .welcome-background {
            width: 50%;
        }
    }

    .content-products {
        padding: 75px 0;

        .products-flexbox {
            display: flex;
            justify-content: space-between;

            .inner-service {
                width: 30%;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .service-category {
                    text-transform: uppercase;
                    font-weight: 700;
                    color: @color3;
                    font-size: 22px;
                }

                .service-image {
                    width: 157px;
                    margin: 30px auto 0 auto;
                    position: relative;

                    .image-product {
                        width: 100%;
                        position: relative;
                        z-index: 111;
                    }

                    .image-shadow {
                        position: absolute;
                        bottom: -3px;
                        right: 0;
                        width: 150%;
                        z-index: -1;
                    }
                }

                .service-name {
                    margin-top: 30px;
                    font-weight: 700;
                    color: @color3;
                    font-size: 22px;
                }

                .service-description,
                p {
                    margin-top: 15px;
                    font-size: 14px;
                    color: #323232;
                }

                .btn {
                    margin-top: 30px;
                }
            }
        }
    }

    .content-product-service-background {
        padding: 50px 0;
        position: relative;

        .content-product-service {
            .service-heading {
                h2 {
                    color: @color2;
                    font-weight: 700;
                    font-size: 32px;
                    transition: all 0.3s;
                }

                p {
                    font-size: 16px;
                    color: #323232;
                    font-weight: 400;
                    margin-top: 15px;
                }

                ul {
                    margin-top: 15px;
                    list-style: none;

                    li {
                        margin-top: 5px;
                        font-size: 16px;
                        color: #323232;
                        font-weight: 400;
                        padding-left: 18px;
                        position: relative;

                        &:before {
                            content: '';
                            height: 10px;
                            width: 10px;
                            background: url(../img/icons/done.svg) center;
                            background-size: cover;
                            position: absolute;
                            left: 0;
                            top: 8px;
                        }
                    }
                }

                .heading-list {
                    display: flex;

                    >* {
                        margin-right: 30px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            .service-inner {
                margin-top: 50px;
                display: flex;
                justify-content: space-between;

                .inner-sidebar {
                    width: 20%;

                    .sidebar-image {
                        margin-left: 20px;
                        position: relative;
                        margin-bottom: 20px;

                        .image-product {
                            width: 100%;
                            position: relative;
                            z-index: 111;
                        }

                        .image-shadow {
                            position: absolute;
                            bottom: -3px;
                            right: 0;
                            width: 150%;
                        }
                    }

                    .btn {
                        margin-top: 30px;
                        width: 100%;

                        .text {
                            justify-content: center;
                        }
                    }

                    .btn-break {
                        font-size: 12px;
                        line-height: 14px;
                    }
                }

                .inner-body {
                    width: 69%;

                    .body-inner {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-gap: 65px;

                        .body-service {
                            text-align: center;
                            position: relative;

                            img {
                                height: 75px;
                                width: 75px;
                            }

                            h3 {
                                margin-top: 25px;
                                // color: #323232;
                                color: @color2;
                                font-weight: 700;
                                text-transform: uppercase;
                                font-size: 16px;
                            }

                            p {
                                margin-top: 10px;
                                font-size: 14px;
                                font-weight: 500;
                                color: #323232;
                            }

                            .service-more {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                background: #fff;
                                padding: 0;
                                border: none;
                                cursor: pointer;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: center;
                                text-align: center;
                                max-height: 150px;
                                overflow-y: visible;

                                ~h3 {
                                    visibility: hidden;
                                }

                                ~p {
                                    visibility: hidden;
                                }



                                .more-icon {
                                    height: 75px;
                                    display: flex;
                                    align-items: center;

                                    .arrow {
                                        height: 40px;
                                        width: 40px;
                                        background: #cd191e;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        transition: all 0.3s;

                                        img {
                                            height: 12px;
                                            transform: rotate(90deg);
                                        }
                                    }
                                }

                                &.hidden {
                                    display: none;

                                    ~h3 {
                                        visibility: visible;
                                    }

                                    ~p {
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                    }

                    .collapse-inner {
                        margin-top: 65px;

                        .body-service {
                            &:first-child {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        &:nth-child(odd) {
            background: #f4faff;

            .content-product-service {
                .service-inner {
                    .inner-body {
                        .body-inner {
                            .body-service {
                                .service-more {
                                    background: #f4faff;
                                }
                            }
                        }
                    }
                }
            }
        }

        .offset-anchor-span {
            position: absolute;
            top: 0;
            opacity: 0;
            visibility: hidden;
        }
    }

    .content-consult {
        padding: 50px 0;
        background-color: @color2;
        color: #fff;

        a {
            color: inherit;
        }

        .content-consult-inner {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h2 {
                font-size: 22px;
                font-weight: 700;
            }

            .btn {
                margin-top: 35px;
                font-size: 16px;
            }

            p {
                margin-top: 20px;
                font-size: 18px;

                a {
                    font-weight: 700;
                }
            }
        }
    }
}

section.outsourcing-section-content {
    position: relative;

    .content-welcome {
        .welcome-inner {
            p {
                margin-top: 10px;
            }
        }

        .welcome-cover {
            width: 20%;
            left: 50%;
        }

        .welcome-background {
            width: 50%;
        }
    }

    .content-advantages {
        padding: 30px 0 50px 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .advantage-service {
            margin-top: 20px;
            width: 25%;
            padding: 20px;
            text-align: center;
            position: relative;

            img {
                height: 75px;
            }

            p {
                margin-top: 20px;
                font-size: 16px;
                font-weight: 500;
                color: #323232;
            }
        }
    }

    .content-numbers {
        padding: 75px 0;
        background: @color2;

        .numbers-flexbox {
            display: flex;
            justify-content: space-between;

            .flexbox-service {
                text-align: center;
                position: relative;

                .service-square {
                    height: 175px;
                    width: 175px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background: #e3edf5;
                    border: 10px solid #1a6298;
                    position: relative;
                    z-index: 111;
                    margin: 0 auto;

                    .square-icon {
                        height: 40px;
                    }

                    .square-number {
                        font-family: 'Oswald', sans-serif;
                        font-size: 48px;
                        font-weight: 300;
                        color: @color2;
                        letter-spacing: -4px;
                        margin: 3px 0 5px 0;

                        &.percent {
                            &:after {
                                content: '%';
                            }
                        }
                    }

                    span {
                        display: block;
                        width: 20px;
                        height: 2px;
                        background: @color1;
                    }
                }

                .service-shadow {
                    height: 175px;
                    width: 175px;
                    position: absolute;
                    top: 0;
                    left: 0;

                    &:after {
                        content: '';
                        position: absolute;
                        box-shadow: 0 0 80px rgba(0, 0, 0, 1);
                        bottom: 0;
                        width: 60%;
                        height: 50%;
                        border-radius: 100%;
                        left: 20%;
                        right: 20%;
                    }
                }

                .service-name {
                    margin-top: 30px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .content-offer {
        padding: 75px 0;

        .offer-heading {
            text-align: center;

            h2 {
                color: @color2;
                font-size: 32px;
                font-weight: 700;
                text-align: center;
            }

            p {
                margin-top: 10px;
                font-size: 16px;
                color: #323232;
            }
        }

        .offer-flexbox {
            display: flex;
            justify-content: space-between;

            .flexbox-service {
                margin-top: 50px;
                width: 30%;
                position: relative;

                .offset-anchor-span {
                    position: absolute;
                    top: 0;
                    opacity: 0;
                    visibility: hidden;
                }

                .service-image {
                    width: 157px;
                    margin: 0 auto;
                    position: relative;

                    .image-product {
                        width: 100%;
                        position: relative;
                        z-index: 111;
                    }

                    .image-shadow {
                        position: absolute;
                        bottom: -3px;
                        right: 0;
                        width: 150%;
                    }
                }

                .service-name {
                    font-size: 22px;
                    color: @color3;
                    font-weight: 700;
                    text-align: center;
                    text-transform: uppercase;
                    margin-top: 30px;
                }

                .service-description {
                    margin-top: 30px;
                    padding: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background: #e3edf5;
                    border: 10px solid #f7fafc;
                    position: relative;

                    ul {
                        list-style: none;

                        li {
                            margin-top: 20px;
                            font-size: 14px;
                            color: #323232;
                            font-weight: 400;
                            padding-left: 18px;
                            position: relative;

                            &.disactive {
                                color: #b4b4b4;

                                &:before {
                                    height: 9px;
                                    width: 9px;
                                    top: 7px;
                                    background: url(../img/icons/disactive.svg) center;
                                    background-size: cover;
                                }
                            }

                            &:before {
                                content: '';
                                height: 10px;
                                width: 10px;
                                background: url(../img/icons/done.svg) center;
                                background-size: cover;
                                position: absolute;
                                left: -5px;
                                top: 6px;
                            }

                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }

                    .btn {
                        margin-top: 30px;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        box-shadow: 0 0 80px rgba(0, 0, 0, 0.5);
                        bottom: -10px;
                        width: 80%;
                        height: 10%;
                        border-radius: 100%;
                        left: 10%;
                        right: 10%;
                    }
                }

                &.service-highlight {
                    .service-image {
                        .image-badge {
                            padding: 7px 13px 5px 13px;
                            background: @color1;
                            text-align: center;
                            position: absolute;
                            top: 25%;
                            right: -75px;
                            z-index: 222;
                            display: table-caption;

                            p {
                                font-size: 14px;
                                font-weight: 700;
                                color: #fff;
                                text-transform: uppercase;
                                line-height: 16px;
                            }
                        }
                    }

                    .service-name {
                        transform: scale(1.35);
                    }

                    .service-description {
                        background: #edfaff;
                        border: 10px solid #99e5ff;
                    }
                }
            }
        }

        .offer-info {
            text-align: center;
            margin: 50px 0;

            p {
                font-size: 11px;
                color: #323232;
                line-height: 20px;
            }
        }

        .offer-cta {
            text-align: center;

            h3 {
                font-size: 22px;
                font-weight: 700;
                color: @color2;
            }

            .btn {
                margin: 30px 0;
            }

            p {
                color: #323232;
                font-size: 18px;

                a {
                    color: #323232;
                    font-weight: 700;
                }
            }
        }
    }
}

section.sklep-section-content,
section.kasy-fiskalne-section-content {
    position: relative;

    .content-welcome {
        .welcome-cover {
            width: 40%;
            left: 50%;
        }

        .welcome-background {
            width: 50%;
        }
    }

    .content-products {
        padding: 55px 0;

        .products-flexbox {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .inner-service {
                width: calc(100% / 3);
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 20px;

                &.w-5 {
                    width: calc(100% / 5);
                }

                .service-image {
                    img {
                        height: 75px;
                    }
                }

                .service-name {
                    margin-top: 30px;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: @color3;
                    font-size: 20px;
                }

                .btn {
                    margin-top: 30px;
                }
            }

        }
    }

    .content-categories-service-background {
        padding-top: 75px;
        position: relative;

        &:nth-child(odd) {
            background: #f5faff;
            padding: 50px 0;

            .width-1366 {
                .category-inner {
                    .inner-flexbox {
                        flex-direction: row-reverse;
                    }
                }
            }
        }

        .offset-anchor-span {
            position: absolute;
            top: 0;
            opacity: 0;
            visibility: hidden;
        }

        .category-inner {
            .inner-heading {
                text-align: center;

                h2,
                h3 {
                    color: @color2;
                    font-weight: 700;
                    font-size: 32px;
                    text-transform: uppercase;
                    transition: all 0.3s;
                }

                h3 {
                    font-size: 26px;
                }
            }

            .inner-flexbox {
                margin-top: 40px;
                display: flex;
                justify-content: space-between;

                .links-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    a {
                        padding: 10px 0;
                        font-size: 18px;
                        font-weight: 700;
                        color: #cd191e;
                        transition: color 200ms ease-in;

                        &:hover {
                            color: @color2;
                        }
                    }
                }

                .flexbox-description {
                    width: 50%;

                    p {
                        font-size: 16px;
                        color: #323232;
                        line-height: 29px;
                    }
                }

                .flexbox-image {
                    width: 45%;

                    img {
                        width: 80%;
                    }
                }
            }

            .btn {
                margin-top: 30px;
            }

            .btn-more {
                visibility: hidden;
            }

            .inner-description {
                margin-top: 50px;

                p {
                    font-size: 16px;
                    color: #323232;
                    line-height: 29px;
                }

                .link {
                    font-size: 18px;
                    font-weight: 700;
                    margin-top: 30px;
                }
            }

            .inner-products {
                // display: none;
                margin: 60px 0 70px 0;

                .products-heading {
                    p {
                        color: @color2;
                        font-weight: 700;
                        font-size: 22px;
                        text-align: center;
                    }
                }

                .products-flexbox {
                    margin-top: 50px;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                    grid-gap: 30px;

                    .product-service {
                        overflow: hidden;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        text-align: center;
                        transition: all 0.3s;

                        .service-image-wrapper {
                            width: 100%;
                            padding-top: 100%;
                            position: relative;
                            overflow: hidden;
                            border-radius: 5px;
                            border: 1px solid #eee;

                            .service-image {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                display: flex;
                                border-radius: 5px;
                                align-items: center;
                                overflow: hidden;

                                img {
                                    width: 100%;
                                }
                            }
                        }

                        .name {
                            margin-top: 10px;
                            color: @color2;
                            font-weight: 700;
                            font-size: 16px;
                        }

                        .description {
                            margin-top: 7px;

                            p {
                                margin-top: 7px;
                                color: #323232;
                                font-size: 12.5px;
                            }

                            li {
                                display: flex;

                                p:nth-of-type(2) {
                                    margin-left: 5px;
                                }
                            }
                        }

                        .price {
                            font-size: 16px;
                            color: @color3;
                            font-weight: 700;
                            text-transform: uppercase;
                            margin-top: 10px;
                        }
                    }
                }
            }

            .inner-cta {
                margin-top: 50px;

                p {
                    text-align: center;
                    font-size: 18px;
                    color: #323232;
                    font-weight: 700;
                }

                .cta-buttons {
                    margin-bottom: 50px;
                    display: flex;
                    justify-content: center;

                    .btn {
                        width: 240px;
                        margin-right: 30px;

                        .text {
                            justify-content: center;
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        &.blue {
            background: #f5faff;
            padding: 50px 0;
        }
    }


    .content-alsen,
    .content-logo-company {
        background: @color2;

        .company-flexbox {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 50px 0;

            img {
                width: 30%;
                margin-top: 20px;
            }

            .flexbox-description {
                text-align: center;

                p {
                    font-size: 22px;
                    color: #fff;

                    a {
                        font-weight: 700;
                        color: @color3;
                    }
                }
            }
        }
    }
}

section.instalacje-section-content {
    position: relative;

    .content-welcome {
        .welcome-inner {
            p {
                margin-top: 10px;
            }
        }

        .welcome-cover {
            width: 40%;
            left: 50%;
        }

        .welcome-background {
            width: 50%;
        }
    }

    .content-products {
        padding: 55px 0;

        .products-flexbox {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .inner-service {
                width: calc(100% / 3);
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 20px;

                .service-image {
                    img {
                        height: 70px;
                    }
                }

                .service-name {
                    margin-top: 30px;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: @color3;
                    font-size: 22px;
                }

                .btn {
                    margin-top: 30px;
                }
            }
        }
    }

    .content-categories-service-background {
        padding: 75px 0;
        position: relative;

        .offset-anchor-span {
            position: absolute;
            top: 0;
            opacity: 0;
            visibility: hidden;
        }

        .category-inner {
            .inner-heading {
                text-align: center;

                h2 {
                    color: @color2;
                    font-weight: 700;
                    font-size: 32px;
                    text-transform: uppercase;
                    transition: all 0.3s;
                }
            }

            .inner-flexbox {
                margin-top: 50px;
                display: flex;
                justify-content: space-between;

                .flexbox-description {
                    width: 50%;

                    p {
                        font-size: 16px;
                        color: #323232;
                        line-height: 29px;
                    }
                }

                .flexbox-image {
                    width: 40%;

                    img {
                        width: 100%;
                    }
                }
            }
        }

        &:nth-child(odd) {
            background: #f5faff;

            .category-inner {
                .inner-flexbox {
                    flex-direction: row-reverse;
                }
            }
        }
    }

    .content-description {
        margin-top: 50px;

        p {
            font-size: 16px;
            color: #323232;
            line-height: 29px;
        }
    }

    .content-cta {
        padding: 50px 0;

        p {
            text-align: center;
            font-size: 18px;
            color: #323232;
            font-weight: 700;
        }

        .cta-buttons {
            margin-top: 30px;
            display: flex;
            justify-content: center;

            .btn {
                width: 240px;
                margin-right: 30px;

                .text {
                    justify-content: center;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.contact-icon-row {
    display: flex;
    align-items: center;
    margin-top: 20px;
    line-height: 1.15;

    &:first-child {
        margin-top: 0;
    }

    .icon-wrapper {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: @color2;
        margin-right: 15px;

        &--white {
            background-color: #fff;
        }

        &--light-blue {
            background-color: @color3;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }

    span {
        &:nth-of-type(2) {
            color: #a0a0a0;
            font-size: 14px;
            width: 50px;
            margin-right: 15px;
        }
    }

    a {
        color: inherit;
    }
}

section.kontakt-section-content {
    position: relative;

    .content-welcome {
        .welcome-cover {
            width: 20%;
            left: 25%;
        }

        .welcome-background {
            width: 75%;
        }
    }

    .content-contact {
        padding: 75px 0;

        .contact-flexbox {
            display: flex;
            justify-content: space-between;

            .contact-service {
                width: 30%;

                .service-inner {
                    padding: 0 20px;
                }

                .service-header {
                    img {
                        max-width: 75px;
                        width: 100%;
                        margin: 0 auto;
                        display: block;
                    }

                    h2 {
                        color: @color3;
                        text-transform: uppercase;
                        font-size: 22px;
                        font-weight: 700;
                        text-align: center;
                        margin-top: 30px;
                        height: calc(2 * 22px * 1.5);
                    }
                }

                address {
                    margin-bottom: 0;
                    margin-top: 50px;
                }

                h4 {
                    margin-top: 40px;
                    margin-bottom: 25px;
                    font-size: 14px;
                    font-weight: 700;
                }
            }

            .separator {
                width: 1px;
                background-color: #eeeeee;
                display: block;
            }
        }

        .contact-alert {
            margin-top: 40px;
            border: 1px solid @color2;

            .header {
                background-color: @color2;
                color: #fff;
                padding: 10px 20px;

                h3 {
                    margin-top: 0px;
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: 700;
                }
            }

            .content {
                padding: 20px;
            }
        }
    }
}

section.serwis-section-content {
    .content-welcome {
        .welcome-inner {
            h1 {
                width: 45%;
                text-transform: none;
            }
        }

        .welcome-cover {
            width: 20%;
            left: 60%;
        }

        .welcome-background {
            width: 40%;
        }
    }

    .services-flexbox {
        display: flex;
        justify-content: space-between;
        padding: 50px 0 80px;

        .service-column {
            width: 48%;

            img {
                max-width: 75px;
                width: 100%;
                margin: 0 auto;
                display: block;
            }

            h2 {
                color: @color3;
                text-transform: uppercase;
                font-size: 22px;
                font-weight: 700;
                text-align: center;
                margin-top: 30px;
                height: calc(2 * 22px * 1.5);
            }

            .service-card {
                padding: 50px 60px;
                background: #e3edf5;
                border: 10px solid #f7fafc;
                position: relative;

                .header {
                    text-align: center;

                    h4 {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 1.5;
                        letter-spacing: -0.4px;
                    }

                    p {
                        font-size: 16px;
                    }
                }

                .data {
                    margin-top: 40px;
                    padding: 0 40px;

                    a {
                        color: inherit;
                    }

                    h4 {
                        margin-top: 40px;
                        margin-bottom: 25px;
                        font-size: 14px;
                        font-weight: 700;
                    }
                }

                .localisation-map {
                    margin-top: 45px;
                    height: 415px;
                    width: 100%;
                }

                .contract-clients {
                    margin-top: 45px;
                    height: 415px;
                    width: 100%;
                    background-color: @color2;
                    color: #fff;
                    padding: 45px 70px;

                    h4 {
                        font-weight: 700;
                        font-size: 14px;

                        &:nth-of-type(2) {
                            color: @color3;
                            margin-top: 40px;
                        }
                    }

                    a {
                        color: inherit;
                    }
                }

                address {
                    margin-top: 25px;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    margin-bottom: 0;

                    p {
                        font-weight: 700;
                        letter-spacing: -0.35px;
                        font-size: 14px;
                        margin-bottom: 5px;
                    }
                }

                .team-viewer {
                    margin-top: 25px;

                    img {
                        margin: 0 auto;
                        width: 180px;
                        max-width: unset;
                        display: block;
                    }
                }

                .button-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .btn {
                        margin: 30px auto 0;
                        display: inline-block;

                        br {
                            display: none;
                        }
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    box-shadow: 0 0 80px rgba(0, 0, 0, 0.5);
                    bottom: 0px;
                    width: 80%;
                    height: 10%;
                    border-radius: 100%;
                    left: 10%;
                    right: 10%;
                }
            }
        }
    }

    .not-client-contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 70px;

        h3 {
            font-size: 22px;
            font-weight: 700;
            text-align: center;
            color: @color2;
        }

        .btn {
            margin-top: 40px;
        }
    }
}

section.do-pobrania-section-content {
    padding: 75px 0;

    h1 {
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        letter-spacing: -0.8px;
        color: @color2;
    }

    .table-wrapper {
        background-color: #f8fafa;
        padding: 70px 0 90px;
        margin-top: 75px;

        .nav-pills {
            padding: 0px 0 30px 65px;
            align-items: center;

            h3 {
                font-size: 15px;
                font-weight: 500;
                color: #00508c;
            }

            .nav-item {
                margin: 10px;

                .nav-button {
                    color: #fff;
                    font-size: 14px;
                    padding: 8px 20px;
                    text-decoration: none;
                    font-weight: 600;
                    background: @color1;
                    opacity: 0.4;
                    transition: opacity 150ms ease-in;

                    &:hover {
                        opacity: 1;
                    }

                    &.active {
                        opacity: 1;
                    }
                }
            }
        }

        .table {
            margin-bottom: 0;
            background-color: #f8fafa;

            thead {
                color: @color2;
                font-weight: 700;
                text-transform: uppercase;

                tr {
                    th {
                        padding: 15px;
                        border: none;
                        border-bottom: 2px solid #fff;

                        &:first-child {
                            width: 80px;
                            border-bottom: none;

                            @media only screen and (max-width: 1366px) {
                                width: 60px;
                            }
                        }

                        &:nth-child(2) {
                            min-width: 200px;
                        }

                        &:nth-child(4) {
                            width: 100px;
                        }

                        &:last-child {
                            width: 80px;
                            border-bottom: none;

                            @media only screen and (max-width: 1366px) {
                                width: 60px;
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    border: none;

                    &:hover {
                        cursor: pointer;

                        th,
                        td {
                            &:first-child {
                                border-left: 5px solid @color1;
                            }

                            &:nth-child(4) {
                                a {
                                    opacity: 1;
                                    font-weight: 700;
                                }
                            }
                        }
                    }

                    &:nth-child(even) {
                        background-color: #fff;
                    }

                    th,
                    td {
                        border: none;
                        font-size: 14px;
                        vertical-align: middle;

                        &:first-child {
                            border-left: 5px solid transparent;
                            transition: all 200ms ease;

                        }

                        &:nth-child(3) {
                            padding: 15px;
                            height: 100%;
                            min-height: 60px;
                            vertical-align: middle;

                            p {
                                height: 100%;
                                min-height: 60px;
                                display: flex;
                                align-items: center;
                            }
                        }

                        &:nth-child(4) {
                            position: relative;
                            height: 100%;
                            min-height: 60px;
                            width: 100px;

                            a {
                                color: @color1;
                                font-weight: 700;
                                opacity: 0;
                                transition: opacity 200ms ease;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                display: flex;
                                align-items: center;

                                img {
                                    height: 12px;
                                    margin-left: 10px;
                                }
                            }
                        }

                        span {
                            font-size: 10px;
                            color: #a0a0a0;
                            margin-right: 15px;

                            &:first-of-type {
                                color: inherit;
                                display: block;
                                margin-right: 0;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

section.error-page-section-content {
    padding: 100px 0;

    h1 {
        text-align: center;
        font-size: 80px;
        font-weight: 700;
        text-align: center;
        color: @color3;
    }

    .content-text {
        color: @color2;
        font-size: 20px;
        margin-top: 45px;
        text-align: center;

        p {
            margin-top: 40px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .content-action {
        margin: 60px auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

section.formularz-section-content {
    padding: 75px 0;

    h1 {
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        letter-spacing: -0.8px;
        color: @color2;
    }

    .content-flexbox {
        display: flex;
        justify-content: space-between;
        margin: 75px auto 0 auto;

        .flexbox-column {
            width: 48%;
        }

        .product-desc-image {
            h2 {
                text-transform: uppercase;
                text-align: center;
                font-weight: 700;
                color: @color3;
                font-size: 24px;
            }

            .service-image {
                width: 100%;
                max-width: 290px;
                margin: 50px auto 0 auto;
                position: relative;

                .image-product {
                    width: 100%;
                    position: relative;
                    z-index: 110;
                }

                .image-shadow {
                    position: absolute;
                    bottom: -3px;
                    right: 0;
                    width: 150%;
                }
            }
        }

        .form-wrapper {
            padding: 30px;
            background: #e3edf5;
            border: 10px solid #f7fafc;
            position: relative;

            .form-heading {
                h3 {
                    color: @color2;
                    font-size: 32px;
                    font-weight: 700;
                    text-align: center;
                    letter-spacing: -1px;
                }

                p {
                    color: @color2;
                    font-size: 20px;
                    text-align: center;
                    letter-spacing: -1px;
                }
            }

            .form-service {
                margin-top: 20px;

                .form-group {
                    position: relative;
                    margin-bottom: 20px;

                    label {
                        font-weight: 400;
                        font-size: 11px;
                        color: #a0a0a0;
                        margin-bottom: 0;
                    }

                    input,
                    textarea {
                        background-color: transparent;
                        margin-top: 0;
                        border: none;
                        border-bottom: 1px solid #fff;
                        border-radius: 0;
                        padding-left: 0;
                        padding-right: 30px;

                        &:focus {
                            color: @color2;
                            border-color: @color2;
                        }
                    }

                    textarea {
                        min-height: 80px;
                        max-height: 80px;
                    }

                    .alert-text {
                        position: absolute;
                        bottom: -20px;
                        right: 0;
                        font-size: 11px;
                        color: @color1;
                    }

                    &.required {
                        &:after {
                            content: '*';
                            font-weight: 700;
                            color: @color1;
                            position: absolute;
                            top: 33px;
                            right: 5px;
                            z-index: 111;
                        }

                        &.correct {
                            &:after {
                                color: #15a529;
                            }
                        }
                    }
                }

                input[type='text'],
                input[type='email'],
                textarea {
                    box-shadow: none;
                }

                textarea {
                    min-width: 100%;
                    max-width: 100%;
                    height: 180px;
                }
            }

            .checkbox-service {
                position: relative;
                margin-top: 20px;
                margin-bottom: 0;
                cursor: pointer;
                width: 100%;
                display: block;

                .service-inner {
                    display: flex;
                    flex-wrap: wrap;

                    .box {
                        margin-right: 10px;
                        height: 15px;
                        min-width: 15px;
                        max-width: 15px;
                        background: #fff;
                        border: 1px solid #e1e1e1;
                        position: relative;
                        transition: all 0.2s;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            height: 104%;
                            width: 104%;
                            background: @color3;
                            transform: translate(-50%, -50%) scale(0);
                            transition: all 0.2s;
                        }
                    }

                    .name {
                        text-align: left;
                        font-weight: 400;
                        font-size: 11px;
                        color: #a0a0a0;
                        width: calc(100% - 25px);

                    }
                }

                .btn-more {
                    font-size: 11px;
                    font-weight: 700;
                    display: block;
                    color: @color3;
                    margin-top: 3px;
                    margin-left: 25px;

                    &:before {
                        content: 'więcej';
                    }

                    &.active {
                        &:before {
                            content: none;
                        }
                    }
                }

                .collapse-inner {
                    display: block;
                    margin-left: 27px;

                    span {
                        display: block;
                        text-align: left;
                        font-weight: 400;
                        font-size: 11px;
                        color: #a0a0a0;
                    }

                    &.active {
                        span {
                            &:after {
                                content: ' *';
                                color: @color1;
                            }
                        }
                    }
                }

                &.more-hidden {
                    .service-inner {
                        .name {
                            &:after {
                                content: '... *';
                                color: @color1;
                            }
                        }
                    }

                    &.more-visible {
                        .service-inner {
                            .name {
                                &:after {
                                    content: '.';
                                    color: #a0a0a0;
                                }
                            }
                        }
                    }
                }

                input {
                    visibility: hidden;
                    position: absolute;
                }

                .alert-text {
                    font-size: 11px;
                    color: @color1;
                }
            }

            .checkbox-service>input:checked+.service-inner {
                .box {
                    &:before {
                        transform: translate(-50%, -50%) scale(1);
                    }
                }
            }

            .recaptcha-wrapper {
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .required-info {
                margin-top: 10px;

                p {
                    font-size: 13px;

                    strong {
                        color: @color1;
                        font-weight: 900;
                        margin-right: 10px;
                        top: 5px;
                        font-size: 18px;
                        position: relative;
                    }
                }
            }

            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                box-shadow: 0 0 80px rgba(0, 0, 0, 0.5);
                bottom: 0px;
                width: 80%;
                height: 10%;
                border-radius: 100%;
                left: 10%;
                right: 10%;
            }
        }
    }
}

section.static-page-section-content {
    padding: 75px 0;

    h1 {
        font-size: 32px;
        color: @color2;
        font-weight: 700;
        text-align: center;
    }

    .regulations-text {
        margin-top: 40px;
        letter-spacing: -0.4px;

        h2 {
            font-size: 16px;
            text-align: center;
            font-weight: 700;
            letter-spacing: -0.8px;
        }

        ol {
            margin-top: 35px;
            padding-left: 45px;
            list-style: none;
            counter-reset: counter;

            li {
                margin-top: 40px;
                counter-increment: counter;
                position: relative;

                &:first-child {
                    margin-top: 0;
                }

                &::before {
                    content: counter(counter) '. ';
                    color: @color3;
                    font-weight: 700;
                    position: absolute;
                    left: -40px;
                }
            }

            ol {
                list-style: none;
                counter-reset: counter-my;

                li {
                    margin-top: 10px;
                    counter-increment: counter-my;
                    position: relative;

                    &::before {
                        counter-increment: list;
                        list-style-type: square;
                        content: counter(counter-my, lower-alpha) ')';
                        color: @color3;
                        font-weight: 700;
                        position: absolute;
                        left: -40px;
                    }
                }
            }

        }

        p {
            &:last-child {
                margin-top: 60px;
                font-size: 14px;
                color: #a0a0a0;
            }
        }
    }
}

section.section-footer {
    background: #323232;

    .footer-top {
        padding: 40px 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid #5b5b5b;

        .top-heading {
            h3 {
                color: #fff;
                font-size: 30px;
                font-weight: 700;
            }

            p {
                font-size: 16px;
                color: @color3;
                font-weight: 700;
                white-space: nowrap;
            }
        }

        .top-service {
            display: flex;
            flex-wrap: wrap;

            >* {
                margin-right: 25px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .inner-wrapper {
                display: flex;
                align-items: center;

                >* {
                    margin-right: 25px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .service-heading {
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;

                p {
                    font-size: 16px;
                    text-transform: uppercase;
                    color: @color3;
                    font-weight: 700;
                }
            }

            .service-item {
                display: flex;
                align-items: center;

                >* {
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                img {
                    height: 18px;
                }

                p {
                    font-size: 14px;
                    color: #fff;
                }

                .item-clock-inner {
                    display: flex;
                    align-items: center;

                    >* {
                        margin-right: 10px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    .inner-helper {
                        display: flex;
                        align-items: center;

                        >* {
                            margin-right: 15px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            // .service-heading,
            // .item-clock {
            //     // display: inline-block;
            // }

        }

        .no-break {
            white-space: nowrap !important;
        }
    }

    .footer-flexbox {
        padding: 40px 0 70px 0;
        display: flex;
        justify-content: space-between;

        .flexbox-service {
            list-style: none;

            li {
                &.heading {
                    font-size: 14px;
                    text-transform: uppercase;
                    color: @color3;
                    font-weight: 700;
                    margin-bottom: 10px;

                    a {
                        color: inherit;
                        font-size: inherit;
                        padding-left: 0;
                        cursor: text;
                        pointer-events: none;

                        &:before {
                            content: none;
                        }
                    }
                }

                a {
                    font-size: 12.5px;
                    color: #fff;
                    padding-left: 18px;
                    position: relative;

                    &:before {
                        content: '■';
                        color: @color3;
                        position: absolute;
                        left: 0;
                        top: -4px;
                    }
                }

                &.social-media {
                    a {
                        img {
                            width: 100%;
                            max-width: 60px;
                        }

                        &:before {
                            top: -2px;
                        }
                    }
                }
            }
        }
    }

    .footer-credits {
        padding: 40px 0;
        border-top: 1px solid #5b5b5b;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-size: 13px;
            color: #fff;
        }

        .credits-webemo {
            display: flex;
            align-items: center;

            p {
                margin-right: 15px;
            }

            img {
                height: 25px;
            }
        }
    }

    #cookies-alert {
        background-color: transparent;
        border-left: 0 none;
        border-right: 0 none;
        border-bottom: 0 none;
        border-top: 1px solid #5b5b5b;
        margin-bottom: 0;
        padding: 0.75rem 0;

        p {
            color: #fff;
            font-size: 12.5px;

            .cookies-more {
                color: #00beff;
            }
        }

        a {
            cursor: pointer;
        }
    }
}

.facebook-button,
.teamviewer-button {
    position: fixed;
    z-index: 44;
    transform: translateY(-50%);
    right: 15px;
    transition: all 0.3s;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s;
    opacity: 0.5;
    visibility: visible;
    cursor: pointer;
}

.teamviewer-button {
    top: 240px;

    img {
        height: 40px;
        width: 40px;
    }
}

.facebook-button {
    background: #4267b2;
    top: 180px;

    img {
        height: 15px;
        width: 15px;
    }
}

.facebook-button:hover,
.teamviewer-button:hover {
    opacity: 1;
}