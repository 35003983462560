@media (max-width: 1650px) {}

@media (max-width: 1366px) {
    section.kontakt-section-content {
        .content-contact {
            .contact-flexbox {
                .contact-service {
                    width: 31%;

                    .service-inner {
                        padding: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 1230px) {}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    section.serwis-section-content {
        .services-flexbox {
            .service-column {
                .service-card {
                    .contract-clients {
                        padding: 45px 40px;
                    }
                }
            }
        }
    }

    .contact-icon-row {
        .icon-wrapper {
            height: 35px;
            width: 35px;
            min-width: 35px;
            margin-right: 10px;
        }

        span {
            margin-right: 10px;

            &:nth-of-type(2) {
                margin-right: 10px;
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .contact-icon-row {
        min-width: 305px;
    }

    section.serwis-section-content {
        .content-welcome {
            .welcome-inner {
                h1 {
                    width: 100%;
                }

                p {
                    width: 100%;
                }
            }
        }

        .services-flexbox {
            flex-wrap: wrap;

            .service-column {
                width: 100%;
                max-width: 600px;
                margin: 75px auto 0;

                &:first-child {
                    margin-top: 0;
                }

                .service-card {
                    margin: 0;

                    .contract-clients {
                        height: auto;
                    }
                }
            }
        }
    }

    section.kontakt-section-content {
        .content-contact {
            padding-bottom: 0;

            .contact-flexbox {
                flex-wrap: wrap;

                .contact-service {
                    width: 100%;
                    margin: 0 auto;
                    margin-top: 75px;

                    &:first-child {
                        margin-top: 0;
                    }

                    .service-inner {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 0;
                    }
                }

                .separator {
                    display: none;
                }
            }

            .contact-alert {
                margin: 50px auto 0;
                max-width: 300px;
            }
        }
    }

    .contact-icon-row {
        .icon-wrapper {
            height: 40px;
            width: 40px;
            margin-right: 15px;
        }

        span {
            &:nth-of-type(2) {
                margin-right: 15px;
            }
        }
    }

    section.error-page-section-content {
        h1 {
            font-size: 68px
        }
    }

    section.formularz-section-content {
        .content-flexbox {
            .flexbox-column {
                &:nth-child(2) {
                    min-width: 450px;
                    margin-left: 30px;
                }
            }

            .product-desc-image {
                h2 {
                    font-size: 18px;
                }
            }

            .form-wrapper {
                min-width: 450px;
                max-width: 720px;
            }
        }

        &--single {
            .content-flexbox {
                .flexbox-column {
                    width: 65%;
                }
            }
        }
    }

    section.instalacje-section-content {
        .content-categories-service-background {
            .offset-anchor-span {
                top: -80px;
            }
        }
    }

    section.sklep-section-content, section.kasy-fiskalne-section-content {
        .content-categories-service-background {
            .offset-anchor-span {
                top: -80px;
			}
			.category-inner {
				.inner-products {
					.products-flexbox {
						grid-template-columns: 1fr 1fr 1fr;
					}
				}
			}
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    section.kontakt-section-content {
        .content-contact {
            .contact-flexbox {
                .contact-service {
                    width: 100%;

                    &:nth-of-type(2) {
                        margin-top: 50px;
                    }

                    .service-header {
                        h2 {
                            height: auto;

                        }
                    }
                }
            }

            .alert-flexbox {
                .contact-service {
                    &:nth-of-type(2) {
                        margin-top: 75px;
                    }
                }
            }
        }
    }

    section.sklep-section-content, section.kasy-fiskalne-section-content {
        .content-products {
            .products-flexbox {
				@supports(display: flex) {
					display: flex;
					flex-direction: column;
					align-items: center;
                }
                .inner-service {
                    padding: 20px 10px;

                    .service-image {
                        img {
                            height: 60px;
                        }
                    }

                    .service-name {
                        font-size: 18px;
                    }
                }
            }
        }

        .content-categories-service-background {
			padding: 50px 0 20px 0;
            .category-inner {
                .inner-flexbox {
                    flex-wrap: wrap;

                    .flexbox-description {
						width: 100%;
						&.order1 {
							order: 1;
						}
                    }

                    .flexbox-image {
                        width: 100%;
						margin-top: 50px;
						&.order2 {
							order: 2;
						}

                        img {
                            width: 70%;
                            margin: 30px auto;
                            display: block;
                        }
                    }
                }

                .inner-products {
                    .products-flexbox {
                        grid-template-columns: 1fr 1fr;

                    }
				}
            }

        }
    }

    section.instalacje-section-content {
        .content-products {
            .products-flexbox {
                .inner-service {
                    padding: 20px 10px;

                    .service-image {
                        img {
                            height: 60px;
                        }
                    }

                    .service-name {
                        font-size: 18px;
                    }
                }
            }
        }

        .content-categories-service-background {
            padding: 50px 0;

            .category-inner {
                .inner-flexbox {
                    flex-wrap: wrap;

                    .flexbox-description {
                        width: 100%;
                    }

                    .flexbox-image {
                        width: 100%;
                        margin-top: 50px;

                        img {
                            width: 70%;
                            display: block;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }

    section.error-page-section-content {
        h1 {
            font-size: 50px
        }

        .content-text {
            p {
                margin-top: 20px;
                font-size: 16px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .content-action {
            margin-top: 50px;
        }
    }

    section.do-pobrania-section-content {
        .table-wrapper {
            padding: 50px 0;

            .table {
                thead {
                    tr {
                        th {
                            padding: 0;

                            &:first-child {
                                width: 10px
                            }

                            &:nth-child(2) {
                                min-width: 175px;
                            }

                            // &:nth-child(4) {}

                            &:last-child {
                                width: 10px
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        &:hover {

                            th,
                            td {
                                &:nth-child(4) {
                                    a {
                                        opacity: unset;
                                    }
                                }
                            }
                        }

                        th,
                        td {
                            padding: 0;

                            &:first-child {
                                width: auto;
                            }

                            &:nth-child(3) {
                                padding: 10px 0;

                                p {
                                    font-size: 12px;
                                }
                            }

                            &:nth-child(4) {
                                width: auto;

                                a {
                                    opacity: 1;
                                }
                            }

                            span {
                                margin-right: 10px;

                                &:last-child {
                                    margin-right: 0;
                                }

                                &:first-of-type {
                                    font-weight: 600;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section.formularz-section-content {

        .content-flexbox {
            flex-wrap: wrap;

            .flexbox-column {
                width: 100%;

                &:nth-child(2) {
                    margin-left: 0;
                    min-width: unset;
                    margin-top: 50px;

                }
            }

            .product-desc-image {
                h2 {}

                .service-image {
                    width: 60%;
                    display: block;
                }
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    section.kontakt-section-content {
        .content-contact {
            padding: 50px 0 0;

            .contact-flexbox {

                .contact-service {
                    margin-top: 50px;

                    .service-header {
                        h2 {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .contact-icon-row {
        font-size: 14px;
        min-width: 275px;

        .icon-wrapper {
            margin-right: 10px;
        }

        span {
            &:nth-of-type(2) {
                margin-right: 10px;
            }
        }
    }

    section.sklep-section-content, section.kasy-fiskalne-section-content {
		padding-bottom: 50px;
		text-align: center;

        .content-products {
            .products-flexbox {
                // @supports(display: flex) {
                //     display: flex;
                // }

                .inner-service {
                    margin-top: 20px;
                    padding: 20px;
                    width: 100%;

                    &:first-child {
                        margin-top: 0;
                    }

                    .service-name {
                        margin-top: 20px;
                    }

                    .btn {
                        margin-top: 20px;
					}
					.product-service {
						padding: 15px 0;
					}
                }
            }
        }

        .content-categories-service-background {
            padding-top: 50px;

            .category-inner {
                .inner-heading {
                    h2 {
                        font-size: 32px;
					}
					h3 {
						font-size: 22px;
					}
                }

                .inner-products {
					margin: 60px 0 20px 0;
                    .products-flexbox {
                        grid-template-columns: 1fr 1fr;
						text-align: left;
						.price {
							text-align: center;
						}
                    }
				}

				.inner-flexbox {
					.links-wrapper {
						align-items: flex-start;
						a {
							font-size: 15px;
						}
					}
				}
            }
        }

        .content-alsen {
            .alsen-flexbox {
                flex-wrap: wrap;

                img {
                    width: 70%;
                    display: block;
                    max-width: 200px;
                    margin: 0 auto;
                }

                .flexbox-description {
                    width: 100%;
                    margin-top: 30px;

                    p {
                        font-size: 18px;
                    }
                }
            }
        }
	}
	
	
	section.kasy-fiskalne-section-content {
		padding-bottom: 0;
	}

    section.instalacje-section-content {
        .content-products {
            .products-flexbox {
                .inner-service {
                    margin-top: 20px;
                    padding: 10px;
                    width: 100%;

                    &:first-child {
                        margin-top: 0;
                    }

                    .service-name {
                        margin-top: 20px;
                    }

                    .btn {
                        margin-top: 20px;
                    }
                }
            }
        }

        .content-categories-service-background {
            .category-inner {
                .inner-heading {
                    h2 {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    section.serwis-section-content {
        .services-flexbox {
            padding: 50px 0;

            .service-column {
                margin: 50px auto 0;

                img {
                    max-width: 60px;
                }

                .service-card {
                    padding: 30px 20px;

                    .data {
                        padding: 0;
                    }
                }
            }
        }

        .not-client-contact {
            padding-bottom: 50px;
        }
    }

    section.formularz-section-content {
        padding: 50px 0;

        h1 {
            font-size: 24px;
        }

        .content-flexbox {
            margin-top: 50px;

            .flexbox-column {

                &:nth-child(2) {}
            }

            .form-wrapper {
                min-width: unset;

                .recaptcha-wrapper {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .btn {
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    section.error-page-section-content {
        padding: 75px 0;
    }

    section.static-page-section-content {
        padding: 50px 0;

        h1 {
            font-size: 24px;
        }

        .regulations-text {

            ol {
                padding-left: 25px;

                li {
                    margin-top: 25px;

                    &::before {
                        left: -25px;
                    }
                }

                ol {
                    li {
                        &::before {
                            left: -25px;
                        }
                    }
                }

            }
        }
    }

    section.do-pobrania-section-content {
        padding: 50px 0;

        h1 {
            font-size: 24px;
        }

        .table-wrapper {
            margin-top: 50px;
			overflow-x: scroll;
			.nav-pills {
				flex-direction: column;
				justify-content: center;
				padding: 0 0 30px 0;
			}

            .table {
                min-width: 550px;

                tbody {
                    tr {
                        &:hover {

                            th,
                            td {
                                &:first-child {}

                                &:nth-child(4) {
                                    a {
                                        opacity: initial;
                                    }
                                }
                            }
                        }

                        &:nth-child(even) {}

                        th,
                        td {
                            &:nth-child(4) {
                                a {
                                    opacity: 1;

                                    img {
                                        margin-left: 5px;
                                        height: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: 450.98px) {
    section.serwis-section-content {
        .services-flexbox {
            .service-column {
                h2 {
                    // font-size: 18px;
                }

                .service-card {
                    .contract-clients {
                        padding: 20px;

                        .contact-icon-row {
                            min-width: unset;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 359.98px) {
    section.serwis-section-content {
        .services-flexbox {
            .service-column {
                .service-card {
                    .button-wrapper {
                        .btn {
                            .text {
                                justify-content: center;
                                font-size: 12px;
                                line-height: 15px;

                                br {
                                    display: inline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section.sklep-section-content {
        .content-categories-service-background {
            .category-inner {
                .inner-products {
                    .products-flexbox {
                        display: block;

                        @supports(display: grid) {
                            display: grid;
                            grid-template-columns: 1fr;
                        }

                        .product-service {
                            margin-top: 20px;
                            display: inline-block;

                            @supports(display: grid) {
                                margin-top: 0;
                                display: unset;
                            }
                        }
                    }
                }

                .inner-cta {
                    .cta-buttons {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .btn {
                            margin: 0;

                            &:last-child {
                                margin: 15px 0 0 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .contact-icon-row {
        min-width: 215px;

        span {
            &:nth-of-type(2) {
                display: none;
            }

            &.open-hours {
                display: inline;
                margin-right: 5px;
            }
        }
    }

    section.instalacje-section-content {
        .content-cta {
            .cta-buttons {
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .btn {
                    margin: 0;

                    &:last-child {
                        margin: 15px 0 0 0;
                    }
                }
            }
        }
    }

    .content-contact {
        .contact-alert {
            width: 100%;
            min-width: unset;
        }
    }
}

@media (max-width: 319.98px) {
    .contact-icon-row {
        .icon-wrapper {
            height: 32px;
            width: 32px;
        }
    }
}