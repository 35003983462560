@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gfD-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wdhzg.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gfD-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gfD-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gfD-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3gfD-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url(https://fonts.gstatic.com/s/oswald/v31/TK3_WkUHHAIjg75cFRf3bXL8LICs169vsUhiYA.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body .btn {
  font-size: 14px;
  font-weight: 600;
  border-radius: 0;
  border: none;
  padding: 0 55px 0 15px;
  height: 40px;
  position: relative;
  text-transform: lowercase;
}
body .btn .text {
  height: 100%;
  display: flex;
  align-items: center;
}
body .btn .arrow {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  background: #cd191e;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
body .btn .arrow img {
  height: 12px;
  width: 12px;
}
body .btn.btn-default {
  color: #fff;
  background: #ed1d24;
  transition: all 0.3s;
}
body .btn.btn-default:hover {
  background: #cd191e;
}
body .btn.btn-default:hover .arrow {
  background: #ed1d24;
}
body .btn.btn-gray {
  color: #ed1d24;
  background: #e6e6e6;
  transition: all 0.3s;
}
body .btn.btn-gray .arrow {
  background: #d2d2d2;
}
body .btn.btn-gray:hover {
  background: #d2d2d2;
}
body .btn.btn-gray:hover .arrow {
  background: #e6e6e6;
}
body .btn.btn-small {
  font-size: 12px;
  padding: 0 40px 0 10px;
  height: 30px;
}
body .btn.btn-small .arrow {
  height: 30px;
  width: 30px;
}
body .btn.btn-small .arrow img {
  height: 10px;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
body .container-fluid {
  max-width: 1850px;
}
body .container-fluid.width-1366 {
  max-width: 1230px;
}
body :focus,
body :active {
  outline: 0 none;
}
.system-alerts {
  width: 100%;
  text-align: center;
}
.system-alerts .alert {
  border-radius: 0;
  border: none;
  height: 60px;
  display: flex;
  align-items: center;
  opacity: 0.9;
  transition: all 0.4s;
}
.system-alerts .alert .alert-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.system-alerts .alert .alert-inner .img-hidden {
  visibility: hidden;
  opacity: 0;
  height: 18px;
}
.system-alerts .alert .alert-inner p {
  color: #fff;
  padding: 0 50px;
}
.system-alerts .alert .alert-inner button {
  color: #fff;
  opacity: 1;
  font-weight: 300;
  float: none;
}
.system-alerts .alert .alert-inner button img {
  height: 18px;
}
.system-alerts .alert.alert-success {
  background: #76ad21;
}
.system-alerts .alert.alert-info {
  background: #218ead;
}
.system-alerts .alert.alert-warning {
  background: #c52b20;
}
.alert-message {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
}
.alert-message img {
  width: 60px;
}
.alert-message h3 {
  margin-top: 30px;
  font-size: 28px;
  font-weight: 700;
  color: #00508c;
}
.alert-message h3 span {
  font-size: 18px;
  font-weight: 300;
}
.alert-message p {
  font-size: 14px;
  margin-top: 30px;
}
.alert-message .btn {
  margin-top: 30px;
}
.alert-message.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
#return-to-top {
  position: fixed;
  z-index: 44;
  bottom: 15px;
  right: 15px;
  transition: all 0.3s;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ed1d24;
  transition: all 0.25s;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}
#return-to-top img {
  height: 15px;
  width: 15px;
}
#return-to-top.active {
  opacity: 0.5;
  visibility: visible;
}
#return-to-top.near-bottom {
  bottom: 110px;
}
#return-to-top:hover {
  opacity: 1;
}
nav.section-header .header-top {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-top .top-logo {
  display: flex;
  align-items: center;
}
nav.section-header .header-top .top-logo .logo-image img {
  height: 50px;
  width: 100%;
  min-width: 175px;
}
nav.section-header .header-top .top-logo .logo-slogan {
  display: flex;
  align-items: center;
}
nav.section-header .header-top .top-logo .logo-slogan img {
  height: 43px;
  margin: 0 20px 0 15px;
}
nav.section-header .header-top .top-logo .logo-slogan p {
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  white-space: nowrap;
}
nav.section-header .header-top .top-logo .logo-slogan p strong {
  color: #ed1d24;
}
nav.section-header .header-top .top-menu {
  display: flex;
  align-items: center;
}
nav.section-header .header-top .top-menu li a {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #00508c;
  font-size: 14px;
  transition: all 0.3s;
}
nav.section-header .header-top .top-menu li a img {
  width: 30px;
}
nav.section-header .header-top .top-menu li a:hover {
  color: #00beff;
}
nav.section-header .header-top .top-menu li:nth-child(1) a img {
  margin-right: 15px;
}
nav.section-header .header-top .top-menu li:nth-child(3) a img {
  margin-left: 15px;
}
nav.section-header .header-top .top-menu li.divider {
  height: 20px;
  width: 1px;
  background: #ddd;
  margin: 0 20px;
}
nav.section-header .header-flexbox-background {
  background: #f1f6fa;
}
nav.section-header .header-flexbox {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-logo {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
  margin-right: 45px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  display: flex;
  text-transform: uppercase;
  font-weight: 700;
  color: #00508c;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:hover,
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a.active {
  color: #ed1d24;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a.chevron-rotate img {
  transform: rotate(180deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown-button {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown-button img {
  height: 5px;
  margin-left: 10px;
  transition: all 0.3s;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown .dropdown-menu {
  margin-top: 12px;
  padding: 10px 10px;
  min-width: 12rem;
  background: #e3edf6;
  border: 1px solid #c0cad3;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown .dropdown-menu .dropdown-item {
  padding: 10px;
  color: #00beff;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown .dropdown-menu .dropdown-item:hover {
  background: none;
  color: #ed1d24;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-child {
  margin-right: 0;
}
nav.section-header .header-flexbox .nav-button {
  cursor: pointer;
  padding: 10px;
  margin-right: -10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  display: block;
  height: 2px;
  width: 32px;
  background-color: #00508c;
  margin-bottom: 7px;
  transition: all 0.7s ease;
}
nav.section-header .header-flexbox .nav-button .button-bar:last-child {
  margin-bottom: 0;
}
nav.section-header .header-flexbox .nav-button.active .button-bar:nth-child(1) {
  transform: translateY(9px) rotateZ(45deg);
}
nav.section-header .header-flexbox .nav-button.active .button-bar:nth-child(2) {
  width: 0;
}
nav.section-header .header-flexbox .nav-button.active .button-bar:nth-child(3) {
  transform: translateY(-9px) rotateZ(-45deg);
}
nav.section-header .header-dropdown {
  position: absolute;
  z-index: 111;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}
nav.section-header .header-dropdown .dropdown-inner {
  background: #e3edf6;
  padding: 25px;
  display: flex;
  justify-content: space-around;
}
nav.section-header .header-dropdown .dropdown-inner .inner-service {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
nav.section-header .header-dropdown .dropdown-inner .inner-service .service-category {
  margin-bottom: 30px;
  text-transform: uppercase;
  font-weight: 700;
  color: #00beff;
  font-size: 14px;
}
nav.section-header .header-dropdown .dropdown-inner .inner-service .service-image {
  position: relative;
  display: block;
}
nav.section-header .header-dropdown .dropdown-inner .inner-service .service-image .image-product {
  height: 155px;
  position: relative;
  z-index: 111;
  transition: all 0.3s;
}
nav.section-header .header-dropdown .dropdown-inner .inner-service .service-image .image-shadow {
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 150%;
  transition: all 0.3s;
}
nav.section-header .header-dropdown .dropdown-inner .inner-service .service-image:hover .image-product {
  transform: translateY(-10px);
}
nav.section-header .header-dropdown .dropdown-inner .inner-service .service-image:hover .image-shadow {
  filter: blur(5px);
}
nav.section-header .header-dropdown .dropdown-inner .inner-service .service-image:hover + .service-name {
  color: #ed1d24;
}
nav.section-header .header-dropdown .dropdown-inner .inner-service .service-name {
  margin-top: 30px;
  font-weight: 700;
  color: #00508c;
  font-size: 14px;
  transition: all 0.3s;
}
nav.section-header .header-dropdown .dropdown-inner .inner-service .service-name:hover {
  color: #ed1d24;
}
nav.section-header .header-dropdown .dropdown-inner .divider {
  height: 155px;
  width: 1px;
  background: #fff;
  align-self: center;
}
nav.section-header .header-dropdown.active {
  opacity: 1;
  visibility: visible;
}
nav.section-header .header-dropdown:after {
  content: '';
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  height: 30px;
  opacity: 0.25;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
}
section.section-offer {
  position: relative;
}
section.section-offer .offer-service {
  background: #e3edf5;
  display: block;
  position: relative;
  overflow: hidden;
}
section.section-offer .offer-service .service-inner {
  padding: 135px 50px;
  position: relative;
}
section.section-offer .offer-service .service-inner h1 {
  color: #00508c;
  font-weight: 700;
  font-size: 32px;
  transition: all 0.3s;
}
section.section-offer .offer-service .service-inner p {
  font-size: 20px;
  color: #00508c;
  font-weight: 500;
  margin: 25px 0;
}
section.section-offer .offer-service .inner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}
section.section-offer .offer-service .service-cover {
  position: absolute;
  top: 0;
  height: 100%;
  width: 40%;
  left: 39.88%;
  background: -moz-linear-gradient(left, #e3edf5 0%, rgba(227, 237, 245, 0) 100%);
  background: -webkit-linear-gradient(left, #e3edf5 0%, rgba(227, 237, 245, 0) 100%);
  background: linear-gradient(to right, #e3edf5 0%, rgba(227, 237, 245, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6e3edf5', endColorstr='#00e3edf5', GradientType=1);
}
section.section-offer .offer-service .service-background {
  position: absolute;
  height: 100%;
  width: 60%;
  right: 0;
  top: 0;
}
section.section-offer .offer-service:hover .inner-wrapper {
  transform: scale(1.05);
}
section.section-offer .offer-flexbox-wrapper {
  position: relative;
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service {
  width: 50%;
  overflow: hidden;
  position: relative;
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service .service-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service .service-cover {
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  left: 39.88%;
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service .service-image {
  position: absolute;
  height: 100%;
  width: 60%;
  right: 0;
  top: 0;
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service:nth-child(1) {
  background: #6f9ac5;
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service:nth-child(1) .service-cover {
  background: -moz-linear-gradient(left, #6f9ac5 0%, rgba(111, 154, 197, 0) 100%);
  background: -webkit-linear-gradient(left, #6f9ac5 0%, rgba(111, 154, 197, 0) 100%);
  background: linear-gradient(to right, #6f9ac5 0%, rgba(111, 154, 197, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6e3edf5', endColorstr='#00e3edf5', GradientType=1);
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service:nth-child(2) {
  background: #00508c;
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service:nth-child(2) .service-cover {
  background: -moz-linear-gradient(left, #00508c 0%, rgba(0, 80, 140, 0) 100%);
  background: -webkit-linear-gradient(left, #00508c 0%, rgba(0, 80, 140, 0) 100%);
  background: linear-gradient(to right, #00508c 0%, rgba(0, 80, 140, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6e3edf5', endColorstr='#00e3edf5', GradientType=1);
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service:hover .service-inner,
section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service.active .service-inner {
  transform: scale(1.05);
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox {
  position: relative;
  display: flex;
  justify-content: space-between;
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox .flexbox-service {
  width: 50%;
  padding: 98px 50px;
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox .flexbox-service h2 {
  color: #fff;
  font-weight: 700;
  font-size: 32px;
}
section.section-offer .offer-flexbox-wrapper .offer-flexbox .flexbox-service p {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  margin: 25px 0;
  width: 70%;
}
section.section-offer .offer-chevron {
  position: absolute;
  z-index: 111;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  width: 40px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.section-offer .offer-chevron img {
  height: 10px;
}
section.section-about {
  padding: 75px 0;
}
section.section-about h3 {
  color: #00508c;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}
section.section-about .about-flexbox {
  margin-top: 75px;
  display: flex;
  justify-content: center;
}
section.section-about .about-flexbox .flexbox-service {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 100px;
}
section.section-about .about-flexbox .flexbox-service .service-square {
  height: 175px;
  width: 175px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e3edf5;
  border: 10px solid #f7fafc;
  position: relative;
}
section.section-about .about-flexbox .flexbox-service .service-square .square-icon {
  height: 40px;
}
section.section-about .about-flexbox .flexbox-service .service-square .square-number {
  font-family: 'Oswald', sans-serif;
  font-size: 48px;
  font-weight: 300;
  color: #00508c;
  letter-spacing: -4px;
  margin: 3px 0 5px 0;
}
section.section-about .about-flexbox .flexbox-service .service-square span {
  display: block;
  width: 20px;
  height: 2px;
  background: #ed1d24;
}
section.section-about .about-flexbox .flexbox-service .service-square:after {
  content: '';
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.8);
  bottom: -10px;
  width: 60%;
  height: 50%;
  border-radius: 100%;
  left: 20%;
  right: 20%;
}
section.section-about .about-flexbox .flexbox-service .service-name {
  margin-top: 50px;
  font-size: 20px;
  font-weight: 500;
  color: #00508c;
}
section.section-about .about-flexbox .flexbox-service:last-child {
  margin-right: 0;
}
section.section-testimonials {
  padding: 75px 0;
  background: #00508c;
}
section.section-testimonials h3 {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}
section.section-testimonials .owl-carousel-testimonials-wrapper {
  padding: 0 350px;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials {
  margin-top: 65px;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .testimonial-item {
  text-align: center;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .testimonial-item .name {
  color: #fff;
  font-size: 16px;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .testimonial-item .name strong {
  color: #00beff;
  font-weight: 700;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .testimonial-item img {
  margin-top: 20px;
  width: auto;
  max-width: 100%;
  max-height: 25px;
  display: block;
  margin: 20px auto 0 auto;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .testimonial-item span {
  margin: 20px auto;
  display: block;
  width: 20px;
  height: 2px;
  background: #ed1d24;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .testimonial-item .description p {
  color: #fff;
  font-size: 16px;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .owl-controls {
  margin-top: 65px;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .owl-controls .owl-nav {
  display: none;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .owl-controls .owl-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .owl-controls .owl-dots .owl-dot {
  margin: 5px;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .owl-controls .owl-dots .owl-dot span {
  margin: 0;
  height: 15px;
  width: 15px;
  border-radius: 0;
  border: 1px solid #00beff;
  background: none;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .owl-controls .owl-dots .owl-dot.active span {
  background: #00beff;
}
section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .owl-controls .owl-dots .owl-dot:last-child {
  margin-right: 0;
}
section.section-testimonials .testimonials-cta {
  text-align: center;
  margin-top: 65px;
}
section.section-testimonials .testimonials-cta h4 {
  font-weight: 700;
  color: #fff;
  font-size: 22px;
}
section.section-testimonials .testimonials-cta .btn {
  margin-top: 30px;
}
section.section-comarch {
  padding: 75px 0;
}
section.section-comarch .comarch-flexbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.section-comarch .comarch-flexbox .flexbox-image {
  width: 20%;
}
section.section-comarch .comarch-flexbox .flexbox-image img {
  width: 100%;
}
section.section-comarch .comarch-flexbox .flexbox-description {
  width: 65%;
  text-align: center;
}
section.section-comarch .comarch-flexbox .flexbox-description h3 {
  color: #00508c;
  font-size: 32px;
  font-weight: 700;
}
section.section-comarch .comarch-flexbox .flexbox-description p {
  margin-top: 20px;
  font-size: 16px;
  color: #323232;
}
section.section-comarch .comarch-flexbox .flexbox-description .btn {
  margin-top: 20px;
}
section.section-map {
  padding: 75px 0;
  position: relative;
}
section.section-map .localisation-map {
  top: 0;
  left: -40%;
  position: absolute;
  width: 140%;
  height: 100%;
}
section.section-map .localisation-map.map-index {
  left: 0;
  width: 100%;
  background-image: url('../img/backgrounds/mapa-profektus.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
section.section-map .map-form {
  padding: 30px;
  background: #fff;
  position: relative;
  margin-left: 55%;
}
section.section-map .map-form .form-heading h3 {
  color: #00508c;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  letter-spacing: -1px;
}
section.section-map .map-form .form-heading p {
  color: #00508c;
  font-size: 20px;
  text-align: center;
  letter-spacing: -1px;
}
section.section-map .map-form .form-service {
  margin-top: 20px;
}
section.section-map .map-form .form-service .form-group {
  position: relative;
  margin-bottom: 20px;
}
section.section-map .map-form .form-service .form-group label {
  font-weight: 400;
  font-size: 11px;
  color: #a0a0a0;
  margin-bottom: 0;
}
section.section-map .map-form .form-service .form-group input,
section.section-map .map-form .form-service .form-group textarea {
  margin-top: 0;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  padding-left: 0;
  padding-right: 30px;
}
section.section-map .map-form .form-service .form-group input:focus,
section.section-map .map-form .form-service .form-group textarea:focus {
  color: #00508c;
  border-color: #00508c;
}
section.section-map .map-form .form-service .form-group textarea {
  min-height: 80px;
  max-height: 80px;
}
section.section-map .map-form .form-service .form-group .alert-text {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-size: 11px;
  color: #ed1d24;
}
section.section-map .map-form .form-service .form-group.required:after {
  content: '*';
  font-weight: 700;
  color: #ed1d24;
  position: absolute;
  top: 33px;
  right: 5px;
  z-index: 111;
}
section.section-map .map-form .form-service .form-group.required.correct:after {
  color: #15a529;
}
section.section-map .map-form .form-service input[type="text"],
section.section-map .map-form .form-service input[type="email"],
section.section-map .map-form .form-service textarea {
  box-shadow: none;
}
section.section-map .map-form .form-service textarea {
  min-width: 100%;
  max-width: 100%;
  height: 180px;
}
section.section-map .map-form .checkbox-service {
  position: relative;
  margin-top: 20px;
  margin-bottom: 0;
  cursor: pointer;
  width: 100%;
  display: block;
}
section.section-map .map-form .checkbox-service .service-inner {
  display: flex;
  flex-wrap: wrap;
}
section.section-map .map-form .checkbox-service .service-inner .box {
  display: block;
  margin-right: 10px;
  height: 15px;
  min-width: 15px;
  max-width: 15px;
  background: #f0f0f0;
  border: 1px solid #e1e1e1;
  position: relative;
  transition: all 0.2s;
}
section.section-map .map-form .checkbox-service .service-inner .box:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  height: 104%;
  width: 104%;
  background: #00beff;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.2s;
}
section.section-map .map-form .checkbox-service .service-inner .name {
  display: block;
  text-align: left;
  font-weight: 400;
  font-size: 11px;
  color: #a0a0a0;
  width: calc(100% - 25px);
}
section.section-map .map-form .checkbox-service .btn-more {
  font-size: 11px;
  font-weight: 700;
  display: block;
  margin-top: 3px;
  margin-left: 25px;
  color: #00beff;
}
section.section-map .map-form .checkbox-service .btn-more:before {
  content: "więcej";
}
section.section-map .map-form .checkbox-service .btn-more.active:before {
  content: none;
}
section.section-map .map-form .checkbox-service .collapse-inner {
  display: block;
  margin-left: 27px;
}
section.section-map .map-form .checkbox-service .collapse-inner span {
  display: block;
  text-align: left;
  font-weight: 400;
  font-size: 11px;
  color: #a0a0a0;
}
section.section-map .map-form .checkbox-service .collapse-inner.active span:after {
  content: " *";
  color: #ed1d24;
}
section.section-map .map-form .checkbox-service.more-hidden .service-inner .name:after {
  content: "... *";
  color: #ed1d24;
}
section.section-map .map-form .checkbox-service.more-hidden.more-visible .service-inner .name:after {
  content: '.';
  color: #a0a0a0;
}
section.section-map .map-form .checkbox-service .alert-text {
  font-size: 11px;
  color: #ed1d24;
}
section.section-map .map-form .checkbox-service input {
  visibility: hidden;
  position: absolute;
}
section.section-map .map-form .checkbox-service > input:checked + .service-inner .box:before {
  transform: translate(-50%, -50%) scale(1);
}
section.section-map .map-form .recaptcha-wrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.section-map .map-form .required-info {
  margin-top: 10px;
}
section.section-map .map-form .required-info p {
  font-size: 13px;
}
section.section-map .map-form .required-info p strong {
  color: #ed1d24;
  font-weight: 900;
  margin-right: 10px;
  top: 5px;
  font-size: 18px;
  position: relative;
}
.content-welcome {
  background: #e3edf5;
  min-height: 340px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.content-welcome .welcome-inner {
  padding: 50px 0;
  position: relative;
}
.content-welcome .welcome-inner h1 {
  color: #00508c;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 32px;
  transition: all 0.3s;
}
.content-welcome .welcome-inner p {
  font-size: 20px;
  color: #00508c;
  font-weight: 400;
  margin-top: 25px;
  width: 50%;
}
.content-welcome .welcome-inner .btn {
  margin-top: 30px;
}
.content-welcome .welcome-cover {
  position: absolute;
  top: 0;
  height: 100%;
  background: -moz-linear-gradient(left, #e3edf5 0%, rgba(227, 237, 245, 0) 100%);
  background: -webkit-linear-gradient(left, #e3edf5 0%, rgba(227, 237, 245, 0) 100%);
  background: linear-gradient(to right, #e3edf5 0%, rgba(227, 237, 245, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6e3edf5', endColorstr='#00e3edf5', GradientType=1);
}
.content-welcome .welcome-background {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
}
section.o-firmie-section-content .content-welcome .welcome-cover {
  width: 50%;
  left: 20%;
}
section.o-firmie-section-content .content-welcome .welcome-background {
  width: 80%;
}
section.o-firmie-section-content .content-advantages .advantages-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 75px 0;
}
section.o-firmie-section-content .content-advantages .advantages-flexbox .advantage {
  text-align: center;
  width: calc(100% / 3 - 15px);
  max-width: 370px;
  margin: 70px 0 0 0;
  overflow: hidden;
}
section.o-firmie-section-content .content-advantages .advantages-flexbox .advantage:nth-last-child(-n + 2) {
  justify-self: center;
  margin-left: auto;
  margin-right: auto;
}
section.o-firmie-section-content .content-advantages .advantages-flexbox .advantage img {
  width: 100%;
  object-fit: cover;
}
section.o-firmie-section-content .content-advantages .advantages-flexbox .advantage h3 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  color: #00508c;
  margin: 25px auto 0;
  letter-spacing: -0.4px;
  height: calc(4 * 16px * 1.2);
}
section.o-firmie-section-content .content-advantages .advantages-flexbox .advantage p {
  margin-top: 5px;
  font-size: 14px;
  color: #323232;
}
section.o-firmie-section-content .content-consult {
  padding: 50px 0;
  background-color: #00508c;
  color: #fff;
}
section.o-firmie-section-content .content-consult a {
  color: inherit;
}
section.o-firmie-section-content .content-consult .content-consult-inner {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
section.o-firmie-section-content .content-consult .content-consult-inner h2 {
  font-size: 22px;
  font-weight: 700;
}
section.o-firmie-section-content .content-consult .content-consult-inner .btn {
  margin-top: 35px;
  font-size: 16px;
}
section.o-firmie-section-content .content-consult .content-consult-inner p {
  margin-top: 20px;
  font-size: 18px;
}
section.o-firmie-section-content .content-consult .content-consult-inner p a {
  font-weight: 700;
}
section.o-firmie-section-content .content-about {
  padding: 75px 0;
  position: relative;
}
section.o-firmie-section-content .content-about h2 {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: #00508c;
  font-size: 32px;
}
section.o-firmie-section-content .content-about p {
  margin-top: 45px;
  line-height: 1.5;
  color: #323232;
  letter-spacing: -0.4px;
}
section.o-firmie-section-content .content-about p:first-child {
  margin-top: 60px;
}
section.o-firmie-section-content .content-about .offset-anchor-span {
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
section.o-firmie-section-content .content-about-flexbox {
  display: flex;
  justify-content: space-between;
}
section.o-firmie-section-content .content-about-flexbox .flexbox-service {
  width: 30%;
  text-align: center;
}
section.o-firmie-section-content .content-about-flexbox .flexbox-service p {
  line-height: 1.5;
  color: #323232;
  letter-spacing: -0.4px;
}
section.o-firmie-section-content .content-about-flexbox .flexbox-service p strong {
  color: #00508c;
  font-size: 20px;
  text-transform: uppercase;
}
section.o-firmie-section-content .content-company-activities {
  padding-bottom: 100px;
}
section.o-firmie-section-content .content-company-activities .content-wrapper {
  padding-top: 40px;
}
section.o-firmie-section-content .content-company-activities .content-wrapper:first-of-type {
  border-top: 1px solid #ddd;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .content-heading h2 {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: #00508c;
  font-size: 32px;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .content-heading h3 {
  margin-bottom: 15px;
  font-size: 22px;
  color: #00508c;
  text-align: center;
  text-transform: uppercase;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .activities-wrapper {
  display: flex;
  align-items: flex-start;
  padding-top: 45px;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .activities-wrapper .flexbox-service {
  width: 47%;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .activities-wrapper .flexbox-service h3 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #00508c;
  text-align: center;
  text-transform: uppercase;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .activities-wrapper .flexbox-service p {
  margin-left: 18px;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .activities-wrapper .flexbox-service ul {
  padding-left: 18px;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .activities-wrapper .flexbox-service ul li {
  padding: 5px 0;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .activities-wrapper .flexbox-service .images {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .activities-wrapper .flexbox-service .images a {
  width: 32%;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .activities-wrapper .flexbox-service .images a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .activities-wrapper .flexbox-service:nth-of-type(2) .images a {
  width: 40%;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .content-description-wrapper {
  display: flex;
  flex-direction: column;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .content-description-wrapper .flexbox-description {
  width: 100%;
  text-align: center;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .content-description-wrapper .flexbox-gallery {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .content-description-wrapper .flexbox-gallery .item {
  width: 33%;
}
section.o-firmie-section-content .content-company-activities .content-wrapper .content-description-wrapper .flexbox-gallery .item img {
  width: 100%;
  object-fit: cover;
  object-position: top center;
}
section.o-firmie-section-cooperate .owl-carousel-cooperate {
  padding: 30px 0;
}
section.o-firmie-section-cooperate .owl-carousel-cooperate .owl-stage-outer .owl-stage {
  display: flex;
  justify-content: center;
  align-items: center;
}
section.o-firmie-section-cooperate .owl-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
}
section.o-firmie-section-cooperate .owl-dots .owl-dot {
  margin: 5px;
}
section.o-firmie-section-cooperate .owl-dots .owl-dot span {
  margin: 0;
  height: 15px;
  width: 15px;
  border-radius: 0;
  border: 1px solid #00beff;
  background: none;
}
section.o-firmie-section-cooperate .owl-dots .owl-dot.active span {
  background: #00beff;
}
section.o-firmie-section-cooperate .owl-dots .owl-dot:last-child {
  margin-right: 0;
}
section.oprogramowanie-section-content {
  position: relative;
}
section.oprogramowanie-section-content .content-welcome .welcome-cover {
  width: 20%;
  left: 50%;
}
section.oprogramowanie-section-content .content-welcome .welcome-background {
  width: 50%;
}
section.oprogramowanie-section-content .content-products {
  padding: 75px 0;
}
section.oprogramowanie-section-content .content-products .products-flexbox {
  display: flex;
  justify-content: space-between;
}
section.oprogramowanie-section-content .content-products .products-flexbox .inner-service {
  width: 30%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-category {
  text-transform: uppercase;
  font-weight: 700;
  color: #00beff;
  font-size: 22px;
}
section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-image {
  width: 157px;
  margin: 30px auto 0 auto;
  position: relative;
}
section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-image .image-product {
  width: 100%;
  position: relative;
  z-index: 111;
}
section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-image .image-shadow {
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 150%;
  z-index: -1;
}
section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-name {
  margin-top: 30px;
  font-weight: 700;
  color: #00beff;
  font-size: 22px;
}
section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-description,
section.oprogramowanie-section-content .content-products .products-flexbox .inner-service p {
  margin-top: 15px;
  font-size: 14px;
  color: #323232;
}
section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .btn {
  margin-top: 30px;
}
section.oprogramowanie-section-content .content-product-service-background {
  padding: 50px 0;
  position: relative;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading h2 {
  color: #00508c;
  font-weight: 700;
  font-size: 32px;
  transition: all 0.3s;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading p {
  font-size: 16px;
  color: #323232;
  font-weight: 400;
  margin-top: 15px;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading ul {
  margin-top: 15px;
  list-style: none;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading ul li {
  margin-top: 5px;
  font-size: 16px;
  color: #323232;
  font-weight: 400;
  padding-left: 18px;
  position: relative;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading ul li:before {
  content: '';
  height: 10px;
  width: 10px;
  background: url(../img/icons/done.svg) center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 8px;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading .heading-list {
  display: flex;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading .heading-list > * {
  margin-right: 30px;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading .heading-list > *:last-child {
  margin-right: 0;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar {
  width: 20%;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar .sidebar-image {
  margin-left: 20px;
  position: relative;
  margin-bottom: 20px;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar .sidebar-image .image-product {
  width: 100%;
  position: relative;
  z-index: 111;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar .sidebar-image .image-shadow {
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 150%;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar .btn {
  margin-top: 30px;
  width: 100%;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar .btn .text {
  justify-content: center;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar .btn-break {
  font-size: 12px;
  line-height: 14px;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body {
  width: 69%;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 65px;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service {
  text-align: center;
  position: relative;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service img {
  height: 75px;
  width: 75px;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service h3 {
  margin-top: 25px;
  color: #00508c;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #323232;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service .service-more {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  padding: 0;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  max-height: 150px;
  overflow-y: visible;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service .service-more ~ h3 {
  visibility: hidden;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service .service-more ~ p {
  visibility: hidden;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service .service-more .more-icon {
  height: 75px;
  display: flex;
  align-items: center;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service .service-more .more-icon .arrow {
  height: 40px;
  width: 40px;
  background: #cd191e;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service .service-more .more-icon .arrow img {
  height: 12px;
  transform: rotate(90deg);
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service .service-more.hidden {
  display: none;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service .service-more.hidden ~ h3 {
  visibility: visible;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service .service-more.hidden ~ p {
  visibility: visible;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .collapse-inner {
  margin-top: 65px;
}
section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .collapse-inner .body-service:first-child {
  display: none;
}
section.oprogramowanie-section-content .content-product-service-background:nth-child(odd) {
  background: #f4faff;
}
section.oprogramowanie-section-content .content-product-service-background:nth-child(odd) .content-product-service .service-inner .inner-body .body-inner .body-service .service-more {
  background: #f4faff;
}
section.oprogramowanie-section-content .content-product-service-background .offset-anchor-span {
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
section.oprogramowanie-section-content .content-consult {
  padding: 50px 0;
  background-color: #00508c;
  color: #fff;
}
section.oprogramowanie-section-content .content-consult a {
  color: inherit;
}
section.oprogramowanie-section-content .content-consult .content-consult-inner {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
section.oprogramowanie-section-content .content-consult .content-consult-inner h2 {
  font-size: 22px;
  font-weight: 700;
}
section.oprogramowanie-section-content .content-consult .content-consult-inner .btn {
  margin-top: 35px;
  font-size: 16px;
}
section.oprogramowanie-section-content .content-consult .content-consult-inner p {
  margin-top: 20px;
  font-size: 18px;
}
section.oprogramowanie-section-content .content-consult .content-consult-inner p a {
  font-weight: 700;
}
section.outsourcing-section-content {
  position: relative;
}
section.outsourcing-section-content .content-welcome .welcome-inner p {
  margin-top: 10px;
}
section.outsourcing-section-content .content-welcome .welcome-cover {
  width: 20%;
  left: 50%;
}
section.outsourcing-section-content .content-welcome .welcome-background {
  width: 50%;
}
section.outsourcing-section-content .content-advantages {
  padding: 30px 0 50px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
section.outsourcing-section-content .content-advantages .advantage-service {
  margin-top: 20px;
  width: 25%;
  padding: 20px;
  text-align: center;
  position: relative;
}
section.outsourcing-section-content .content-advantages .advantage-service img {
  height: 75px;
}
section.outsourcing-section-content .content-advantages .advantage-service p {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #323232;
}
section.outsourcing-section-content .content-numbers {
  padding: 75px 0;
  background: #00508c;
}
section.outsourcing-section-content .content-numbers .numbers-flexbox {
  display: flex;
  justify-content: space-between;
}
section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service {
  text-align: center;
  position: relative;
}
section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service .service-square {
  height: 175px;
  width: 175px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e3edf5;
  border: 10px solid #1a6298;
  position: relative;
  z-index: 111;
  margin: 0 auto;
}
section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service .service-square .square-icon {
  height: 40px;
}
section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service .service-square .square-number {
  font-family: 'Oswald', sans-serif;
  font-size: 48px;
  font-weight: 300;
  color: #00508c;
  letter-spacing: -4px;
  margin: 3px 0 5px 0;
}
section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service .service-square .square-number.percent:after {
  content: '%';
}
section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service .service-square span {
  display: block;
  width: 20px;
  height: 2px;
  background: #ed1d24;
}
section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service .service-shadow {
  height: 175px;
  width: 175px;
  position: absolute;
  top: 0;
  left: 0;
}
section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service .service-shadow:after {
  content: '';
  position: absolute;
  box-shadow: 0 0 80px #000000;
  bottom: 0;
  width: 60%;
  height: 50%;
  border-radius: 100%;
  left: 20%;
  right: 20%;
}
section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service .service-name {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service:last-child {
  margin-right: 0;
}
section.outsourcing-section-content .content-offer {
  padding: 75px 0;
}
section.outsourcing-section-content .content-offer .offer-heading {
  text-align: center;
}
section.outsourcing-section-content .content-offer .offer-heading h2 {
  color: #00508c;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}
section.outsourcing-section-content .content-offer .offer-heading p {
  margin-top: 10px;
  font-size: 16px;
  color: #323232;
}
section.outsourcing-section-content .content-offer .offer-flexbox {
  display: flex;
  justify-content: space-between;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service {
  margin-top: 50px;
  width: 30%;
  position: relative;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .offset-anchor-span {
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-image {
  width: 157px;
  margin: 0 auto;
  position: relative;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-image .image-product {
  width: 100%;
  position: relative;
  z-index: 111;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-image .image-shadow {
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 150%;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-name {
  font-size: 22px;
  color: #00beff;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin-top: 30px;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description {
  margin-top: 30px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e3edf5;
  border: 10px solid #f7fafc;
  position: relative;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description ul {
  list-style: none;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description ul li {
  margin-top: 20px;
  font-size: 14px;
  color: #323232;
  font-weight: 400;
  padding-left: 18px;
  position: relative;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description ul li.disactive {
  color: #b4b4b4;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description ul li.disactive:before {
  height: 9px;
  width: 9px;
  top: 7px;
  background: url(../img/icons/disactive.svg) center;
  background-size: cover;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description ul li:before {
  content: '';
  height: 10px;
  width: 10px;
  background: url(../img/icons/done.svg) center;
  background-size: cover;
  position: absolute;
  left: -5px;
  top: 6px;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description ul li:first-child {
  margin-top: 0;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description .btn {
  margin-top: 30px;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description:after {
  content: '';
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.5);
  bottom: -10px;
  width: 80%;
  height: 10%;
  border-radius: 100%;
  left: 10%;
  right: 10%;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service.service-highlight .service-image .image-badge {
  padding: 7px 13px 5px 13px;
  background: #ed1d24;
  text-align: center;
  position: absolute;
  top: 25%;
  right: -75px;
  z-index: 222;
  display: table-caption;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service.service-highlight .service-image .image-badge p {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  line-height: 16px;
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service.service-highlight .service-name {
  transform: scale(1.35);
}
section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service.service-highlight .service-description {
  background: #edfaff;
  border: 10px solid #99e5ff;
}
section.outsourcing-section-content .content-offer .offer-info {
  text-align: center;
  margin: 50px 0;
}
section.outsourcing-section-content .content-offer .offer-info p {
  font-size: 11px;
  color: #323232;
  line-height: 20px;
}
section.outsourcing-section-content .content-offer .offer-cta {
  text-align: center;
}
section.outsourcing-section-content .content-offer .offer-cta h3 {
  font-size: 22px;
  font-weight: 700;
  color: #00508c;
}
section.outsourcing-section-content .content-offer .offer-cta .btn {
  margin: 30px 0;
}
section.outsourcing-section-content .content-offer .offer-cta p {
  color: #323232;
  font-size: 18px;
}
section.outsourcing-section-content .content-offer .offer-cta p a {
  color: #323232;
  font-weight: 700;
}
section.sklep-section-content,
section.kasy-fiskalne-section-content {
  position: relative;
}
section.sklep-section-content .content-welcome .welcome-cover,
section.kasy-fiskalne-section-content .content-welcome .welcome-cover {
  width: 40%;
  left: 50%;
}
section.sklep-section-content .content-welcome .welcome-background,
section.kasy-fiskalne-section-content .content-welcome .welcome-background {
  width: 50%;
}
section.sklep-section-content .content-products,
section.kasy-fiskalne-section-content .content-products {
  padding: 55px 0;
}
section.sklep-section-content .content-products .products-flexbox,
section.kasy-fiskalne-section-content .content-products .products-flexbox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
section.sklep-section-content .content-products .products-flexbox .inner-service,
section.kasy-fiskalne-section-content .content-products .products-flexbox .inner-service {
  width: calc(100% / 3);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
section.sklep-section-content .content-products .products-flexbox .inner-service.w-5,
section.kasy-fiskalne-section-content .content-products .products-flexbox .inner-service.w-5 {
  width: calc(100% / 5);
}
section.sklep-section-content .content-products .products-flexbox .inner-service .service-image img,
section.kasy-fiskalne-section-content .content-products .products-flexbox .inner-service .service-image img {
  height: 75px;
}
section.sklep-section-content .content-products .products-flexbox .inner-service .service-name,
section.kasy-fiskalne-section-content .content-products .products-flexbox .inner-service .service-name {
  margin-top: 30px;
  font-weight: 700;
  text-transform: uppercase;
  color: #00beff;
  font-size: 20px;
}
section.sklep-section-content .content-products .products-flexbox .inner-service .btn,
section.kasy-fiskalne-section-content .content-products .products-flexbox .inner-service .btn {
  margin-top: 30px;
}
section.sklep-section-content .content-categories-service-background,
section.kasy-fiskalne-section-content .content-categories-service-background {
  padding-top: 75px;
  position: relative;
}
section.sklep-section-content .content-categories-service-background:nth-child(odd),
section.kasy-fiskalne-section-content .content-categories-service-background:nth-child(odd) {
  background: #f5faff;
  padding: 50px 0;
}
section.sklep-section-content .content-categories-service-background:nth-child(odd) .width-1366 .category-inner .inner-flexbox,
section.kasy-fiskalne-section-content .content-categories-service-background:nth-child(odd) .width-1366 .category-inner .inner-flexbox {
  flex-direction: row-reverse;
}
section.sklep-section-content .content-categories-service-background .offset-anchor-span,
section.kasy-fiskalne-section-content .content-categories-service-background .offset-anchor-span {
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-heading,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-heading {
  text-align: center;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-heading h2,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-heading h2,
section.sklep-section-content .content-categories-service-background .category-inner .inner-heading h3,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-heading h3 {
  color: #00508c;
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  transition: all 0.3s;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-heading h3,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-heading h3 {
  font-size: 26px;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .links-wrapper,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .links-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .links-wrapper a,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .links-wrapper a {
  padding: 10px 0;
  font-size: 18px;
  font-weight: 700;
  color: #cd191e;
  transition: color 200ms ease-in;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .links-wrapper a:hover,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .links-wrapper a:hover {
  color: #00508c;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-description,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-description {
  width: 50%;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-description p,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-description p {
  font-size: 16px;
  color: #323232;
  line-height: 29px;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image {
  width: 45%;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image img,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image img {
  width: 80%;
}
section.sklep-section-content .content-categories-service-background .category-inner .btn,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .btn {
  margin-top: 30px;
}
section.sklep-section-content .content-categories-service-background .category-inner .btn-more,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .btn-more {
  visibility: hidden;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-description,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-description {
  margin-top: 50px;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-description p,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-description p {
  font-size: 16px;
  color: #323232;
  line-height: 29px;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-description .link,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-description .link {
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-products,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products {
  margin: 60px 0 70px 0;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-heading p,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-heading p {
  color: #00508c;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  transition: all 0.3s;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .service-image-wrapper,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .service-image-wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #eee;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .service-image-wrapper .service-image,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .service-image-wrapper .service-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 5px;
  align-items: center;
  overflow: hidden;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .service-image-wrapper .service-image img,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .service-image-wrapper .service-image img {
  width: 100%;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .name,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .name {
  margin-top: 10px;
  color: #00508c;
  font-weight: 700;
  font-size: 16px;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .description,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .description {
  margin-top: 7px;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .description p,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .description p {
  margin-top: 7px;
  color: #323232;
  font-size: 12.5px;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .description li,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .description li {
  display: flex;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .description li p:nth-of-type(2),
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .description li p:nth-of-type(2) {
  margin-left: 5px;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .price,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service .price {
  font-size: 16px;
  color: #00beff;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 10px;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-cta,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-cta {
  margin-top: 50px;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-cta p,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-cta p {
  text-align: center;
  font-size: 18px;
  color: #323232;
  font-weight: 700;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-cta .cta-buttons,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-cta .cta-buttons {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-cta .cta-buttons .btn,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-cta .cta-buttons .btn {
  width: 240px;
  margin-right: 30px;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-cta .cta-buttons .btn .text,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-cta .cta-buttons .btn .text {
  justify-content: center;
}
section.sklep-section-content .content-categories-service-background .category-inner .inner-cta .cta-buttons .btn:last-child,
section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-cta .cta-buttons .btn:last-child {
  margin-right: 0;
}
section.sklep-section-content .content-categories-service-background.blue,
section.kasy-fiskalne-section-content .content-categories-service-background.blue {
  background: #f5faff;
  padding: 50px 0;
}
section.sklep-section-content .content-alsen,
section.kasy-fiskalne-section-content .content-alsen,
section.sklep-section-content .content-logo-company,
section.kasy-fiskalne-section-content .content-logo-company {
  background: #00508c;
}
section.sklep-section-content .content-alsen .company-flexbox,
section.kasy-fiskalne-section-content .content-alsen .company-flexbox,
section.sklep-section-content .content-logo-company .company-flexbox,
section.kasy-fiskalne-section-content .content-logo-company .company-flexbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
}
section.sklep-section-content .content-alsen .company-flexbox img,
section.kasy-fiskalne-section-content .content-alsen .company-flexbox img,
section.sklep-section-content .content-logo-company .company-flexbox img,
section.kasy-fiskalne-section-content .content-logo-company .company-flexbox img {
  width: 30%;
  margin-top: 20px;
}
section.sklep-section-content .content-alsen .company-flexbox .flexbox-description,
section.kasy-fiskalne-section-content .content-alsen .company-flexbox .flexbox-description,
section.sklep-section-content .content-logo-company .company-flexbox .flexbox-description,
section.kasy-fiskalne-section-content .content-logo-company .company-flexbox .flexbox-description {
  text-align: center;
}
section.sklep-section-content .content-alsen .company-flexbox .flexbox-description p,
section.kasy-fiskalne-section-content .content-alsen .company-flexbox .flexbox-description p,
section.sklep-section-content .content-logo-company .company-flexbox .flexbox-description p,
section.kasy-fiskalne-section-content .content-logo-company .company-flexbox .flexbox-description p {
  font-size: 22px;
  color: #fff;
}
section.sklep-section-content .content-alsen .company-flexbox .flexbox-description p a,
section.kasy-fiskalne-section-content .content-alsen .company-flexbox .flexbox-description p a,
section.sklep-section-content .content-logo-company .company-flexbox .flexbox-description p a,
section.kasy-fiskalne-section-content .content-logo-company .company-flexbox .flexbox-description p a {
  font-weight: 700;
  color: #00beff;
}
section.instalacje-section-content {
  position: relative;
}
section.instalacje-section-content .content-welcome .welcome-inner p {
  margin-top: 10px;
}
section.instalacje-section-content .content-welcome .welcome-cover {
  width: 40%;
  left: 50%;
}
section.instalacje-section-content .content-welcome .welcome-background {
  width: 50%;
}
section.instalacje-section-content .content-products {
  padding: 55px 0;
}
section.instalacje-section-content .content-products .products-flexbox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
section.instalacje-section-content .content-products .products-flexbox .inner-service {
  width: calc(100% / 3);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
section.instalacje-section-content .content-products .products-flexbox .inner-service .service-image img {
  height: 70px;
}
section.instalacje-section-content .content-products .products-flexbox .inner-service .service-name {
  margin-top: 30px;
  font-weight: 700;
  text-transform: uppercase;
  color: #00beff;
  font-size: 22px;
}
section.instalacje-section-content .content-products .products-flexbox .inner-service .btn {
  margin-top: 30px;
}
section.instalacje-section-content .content-categories-service-background {
  padding: 75px 0;
  position: relative;
}
section.instalacje-section-content .content-categories-service-background .offset-anchor-span {
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
section.instalacje-section-content .content-categories-service-background .category-inner .inner-heading {
  text-align: center;
}
section.instalacje-section-content .content-categories-service-background .category-inner .inner-heading h2 {
  color: #00508c;
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  transition: all 0.3s;
}
section.instalacje-section-content .content-categories-service-background .category-inner .inner-flexbox {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
section.instalacje-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-description {
  width: 50%;
}
section.instalacje-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-description p {
  font-size: 16px;
  color: #323232;
  line-height: 29px;
}
section.instalacje-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image {
  width: 40%;
}
section.instalacje-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image img {
  width: 100%;
}
section.instalacje-section-content .content-categories-service-background:nth-child(odd) {
  background: #f5faff;
}
section.instalacje-section-content .content-categories-service-background:nth-child(odd) .category-inner .inner-flexbox {
  flex-direction: row-reverse;
}
section.instalacje-section-content .content-description {
  margin-top: 50px;
}
section.instalacje-section-content .content-description p {
  font-size: 16px;
  color: #323232;
  line-height: 29px;
}
section.instalacje-section-content .content-cta {
  padding: 50px 0;
}
section.instalacje-section-content .content-cta p {
  text-align: center;
  font-size: 18px;
  color: #323232;
  font-weight: 700;
}
section.instalacje-section-content .content-cta .cta-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
section.instalacje-section-content .content-cta .cta-buttons .btn {
  width: 240px;
  margin-right: 30px;
}
section.instalacje-section-content .content-cta .cta-buttons .btn .text {
  justify-content: center;
}
section.instalacje-section-content .content-cta .cta-buttons .btn:last-child {
  margin-right: 0;
}
.contact-icon-row {
  display: flex;
  align-items: center;
  margin-top: 20px;
  line-height: 1.15;
}
.contact-icon-row:first-child {
  margin-top: 0;
}
.contact-icon-row .icon-wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00508c;
  margin-right: 15px;
}
.contact-icon-row .icon-wrapper--white {
  background-color: #fff;
}
.contact-icon-row .icon-wrapper--light-blue {
  background-color: #00beff;
}
.contact-icon-row .icon-wrapper img {
  width: 20px;
  height: 20px;
}
.contact-icon-row span:nth-of-type(2) {
  color: #a0a0a0;
  font-size: 14px;
  width: 50px;
  margin-right: 15px;
}
.contact-icon-row a {
  color: inherit;
}
section.kontakt-section-content {
  position: relative;
}
section.kontakt-section-content .content-welcome .welcome-cover {
  width: 20%;
  left: 25%;
}
section.kontakt-section-content .content-welcome .welcome-background {
  width: 75%;
}
section.kontakt-section-content .content-contact {
  padding: 75px 0;
}
section.kontakt-section-content .content-contact .contact-flexbox {
  display: flex;
  justify-content: space-between;
}
section.kontakt-section-content .content-contact .contact-flexbox .contact-service {
  width: 30%;
}
section.kontakt-section-content .content-contact .contact-flexbox .contact-service .service-inner {
  padding: 0 20px;
}
section.kontakt-section-content .content-contact .contact-flexbox .contact-service .service-header img {
  max-width: 75px;
  width: 100%;
  margin: 0 auto;
  display: block;
}
section.kontakt-section-content .content-contact .contact-flexbox .contact-service .service-header h2 {
  color: #00beff;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
  height: calc(2 * 22px * 1.5);
}
section.kontakt-section-content .content-contact .contact-flexbox .contact-service address {
  margin-bottom: 0;
  margin-top: 50px;
}
section.kontakt-section-content .content-contact .contact-flexbox .contact-service h4 {
  margin-top: 40px;
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 700;
}
section.kontakt-section-content .content-contact .contact-flexbox .separator {
  width: 1px;
  background-color: #eeeeee;
  display: block;
}
section.kontakt-section-content .content-contact .contact-alert {
  margin-top: 40px;
  border: 1px solid #00508c;
}
section.kontakt-section-content .content-contact .contact-alert .header {
  background-color: #00508c;
  color: #fff;
  padding: 10px 20px;
}
section.kontakt-section-content .content-contact .contact-alert .header h3 {
  margin-top: 0px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
}
section.kontakt-section-content .content-contact .contact-alert .content {
  padding: 20px;
}
section.serwis-section-content .content-welcome .welcome-inner h1 {
  width: 45%;
  text-transform: none;
}
section.serwis-section-content .content-welcome .welcome-cover {
  width: 20%;
  left: 60%;
}
section.serwis-section-content .content-welcome .welcome-background {
  width: 40%;
}
section.serwis-section-content .services-flexbox {
  display: flex;
  justify-content: space-between;
  padding: 50px 0 80px;
}
section.serwis-section-content .services-flexbox .service-column {
  width: 48%;
}
section.serwis-section-content .services-flexbox .service-column img {
  max-width: 75px;
  width: 100%;
  margin: 0 auto;
  display: block;
}
section.serwis-section-content .services-flexbox .service-column h2 {
  color: #00beff;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
  height: calc(2 * 22px * 1.5);
}
section.serwis-section-content .services-flexbox .service-column .service-card {
  padding: 50px 60px;
  background: #e3edf5;
  border: 10px solid #f7fafc;
  position: relative;
}
section.serwis-section-content .services-flexbox .service-column .service-card .header {
  text-align: center;
}
section.serwis-section-content .services-flexbox .service-column .service-card .header h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.4px;
}
section.serwis-section-content .services-flexbox .service-column .service-card .header p {
  font-size: 16px;
}
section.serwis-section-content .services-flexbox .service-column .service-card .data {
  margin-top: 40px;
  padding: 0 40px;
}
section.serwis-section-content .services-flexbox .service-column .service-card .data a {
  color: inherit;
}
section.serwis-section-content .services-flexbox .service-column .service-card .data h4 {
  margin-top: 40px;
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 700;
}
section.serwis-section-content .services-flexbox .service-column .service-card .localisation-map {
  margin-top: 45px;
  height: 415px;
  width: 100%;
}
section.serwis-section-content .services-flexbox .service-column .service-card .contract-clients {
  margin-top: 45px;
  height: 415px;
  width: 100%;
  background-color: #00508c;
  color: #fff;
  padding: 45px 70px;
}
section.serwis-section-content .services-flexbox .service-column .service-card .contract-clients h4 {
  font-weight: 700;
  font-size: 14px;
}
section.serwis-section-content .services-flexbox .service-column .service-card .contract-clients h4:nth-of-type(2) {
  color: #00beff;
  margin-top: 40px;
}
section.serwis-section-content .services-flexbox .service-column .service-card .contract-clients a {
  color: inherit;
}
section.serwis-section-content .services-flexbox .service-column .service-card address {
  margin-top: 25px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0;
}
section.serwis-section-content .services-flexbox .service-column .service-card address p {
  font-weight: 700;
  letter-spacing: -0.35px;
  font-size: 14px;
  margin-bottom: 5px;
}
section.serwis-section-content .services-flexbox .service-column .service-card .team-viewer {
  margin-top: 25px;
}
section.serwis-section-content .services-flexbox .service-column .service-card .team-viewer img {
  margin: 0 auto;
  width: 180px;
  max-width: unset;
  display: block;
}
section.serwis-section-content .services-flexbox .service-column .service-card .button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
section.serwis-section-content .services-flexbox .service-column .service-card .button-wrapper .btn {
  margin: 30px auto 0;
  display: inline-block;
}
section.serwis-section-content .services-flexbox .service-column .service-card .button-wrapper .btn br {
  display: none;
}
section.serwis-section-content .services-flexbox .service-column .service-card:after {
  content: '';
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.5);
  bottom: 0px;
  width: 80%;
  height: 10%;
  border-radius: 100%;
  left: 10%;
  right: 10%;
}
section.serwis-section-content .not-client-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 70px;
}
section.serwis-section-content .not-client-contact h3 {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  color: #00508c;
}
section.serwis-section-content .not-client-contact .btn {
  margin-top: 40px;
}
section.do-pobrania-section-content {
  padding: 75px 0;
}
section.do-pobrania-section-content h1 {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: -0.8px;
  color: #00508c;
}
section.do-pobrania-section-content .table-wrapper {
  background-color: #f8fafa;
  padding: 70px 0 90px;
  margin-top: 75px;
}
section.do-pobrania-section-content .table-wrapper .nav-pills {
  padding: 0px 0 30px 65px;
  align-items: center;
}
section.do-pobrania-section-content .table-wrapper .nav-pills h3 {
  font-size: 15px;
  font-weight: 500;
  color: #00508c;
}
section.do-pobrania-section-content .table-wrapper .nav-pills .nav-item {
  margin: 10px;
}
section.do-pobrania-section-content .table-wrapper .nav-pills .nav-item .nav-button {
  color: #fff;
  font-size: 14px;
  padding: 8px 20px;
  text-decoration: none;
  font-weight: 600;
  background: #ed1d24;
  opacity: 0.4;
  transition: opacity 150ms ease-in;
}
section.do-pobrania-section-content .table-wrapper .nav-pills .nav-item .nav-button:hover {
  opacity: 1;
}
section.do-pobrania-section-content .table-wrapper .nav-pills .nav-item .nav-button.active {
  opacity: 1;
}
section.do-pobrania-section-content .table-wrapper .table {
  margin-bottom: 0;
  background-color: #f8fafa;
}
section.do-pobrania-section-content .table-wrapper .table thead {
  color: #00508c;
  font-weight: 700;
  text-transform: uppercase;
}
section.do-pobrania-section-content .table-wrapper .table thead tr th {
  padding: 15px;
  border: none;
  border-bottom: 2px solid #fff;
}
section.do-pobrania-section-content .table-wrapper .table thead tr th:first-child {
  width: 80px;
  border-bottom: none;
}
@media only screen and (max-width: 1366px) {
  section.do-pobrania-section-content .table-wrapper .table thead tr th:first-child {
    width: 60px;
  }
}
section.do-pobrania-section-content .table-wrapper .table thead tr th:nth-child(2) {
  min-width: 200px;
}
section.do-pobrania-section-content .table-wrapper .table thead tr th:nth-child(4) {
  width: 100px;
}
section.do-pobrania-section-content .table-wrapper .table thead tr th:last-child {
  width: 80px;
  border-bottom: none;
}
@media only screen and (max-width: 1366px) {
  section.do-pobrania-section-content .table-wrapper .table thead tr th:last-child {
    width: 60px;
  }
}
section.do-pobrania-section-content .table-wrapper .table tbody tr {
  border: none;
}
section.do-pobrania-section-content .table-wrapper .table tbody tr:hover {
  cursor: pointer;
}
section.do-pobrania-section-content .table-wrapper .table tbody tr:hover th:first-child,
section.do-pobrania-section-content .table-wrapper .table tbody tr:hover td:first-child {
  border-left: 5px solid #ed1d24;
}
section.do-pobrania-section-content .table-wrapper .table tbody tr:hover th:nth-child(4) a,
section.do-pobrania-section-content .table-wrapper .table tbody tr:hover td:nth-child(4) a {
  opacity: 1;
  font-weight: 700;
}
section.do-pobrania-section-content .table-wrapper .table tbody tr:nth-child(even) {
  background-color: #fff;
}
section.do-pobrania-section-content .table-wrapper .table tbody tr th,
section.do-pobrania-section-content .table-wrapper .table tbody tr td {
  border: none;
  font-size: 14px;
  vertical-align: middle;
}
section.do-pobrania-section-content .table-wrapper .table tbody tr th:first-child,
section.do-pobrania-section-content .table-wrapper .table tbody tr td:first-child {
  border-left: 5px solid transparent;
  transition: all 200ms ease;
}
section.do-pobrania-section-content .table-wrapper .table tbody tr th:nth-child(3),
section.do-pobrania-section-content .table-wrapper .table tbody tr td:nth-child(3) {
  padding: 15px;
  height: 100%;
  min-height: 60px;
  vertical-align: middle;
}
section.do-pobrania-section-content .table-wrapper .table tbody tr th:nth-child(3) p,
section.do-pobrania-section-content .table-wrapper .table tbody tr td:nth-child(3) p {
  height: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
}
section.do-pobrania-section-content .table-wrapper .table tbody tr th:nth-child(4),
section.do-pobrania-section-content .table-wrapper .table tbody tr td:nth-child(4) {
  position: relative;
  height: 100%;
  min-height: 60px;
  width: 100px;
}
section.do-pobrania-section-content .table-wrapper .table tbody tr th:nth-child(4) a,
section.do-pobrania-section-content .table-wrapper .table tbody tr td:nth-child(4) a {
  color: #ed1d24;
  font-weight: 700;
  opacity: 0;
  transition: opacity 200ms ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}
section.do-pobrania-section-content .table-wrapper .table tbody tr th:nth-child(4) a img,
section.do-pobrania-section-content .table-wrapper .table tbody tr td:nth-child(4) a img {
  height: 12px;
  margin-left: 10px;
}
section.do-pobrania-section-content .table-wrapper .table tbody tr th span,
section.do-pobrania-section-content .table-wrapper .table tbody tr td span {
  font-size: 10px;
  color: #a0a0a0;
  margin-right: 15px;
}
section.do-pobrania-section-content .table-wrapper .table tbody tr th span:first-of-type,
section.do-pobrania-section-content .table-wrapper .table tbody tr td span:first-of-type {
  color: inherit;
  display: block;
  margin-right: 0;
  font-size: 14px;
}
section.error-page-section-content {
  padding: 100px 0;
}
section.error-page-section-content h1 {
  font-size: 80px;
  font-weight: 700;
  text-align: center;
  color: #00beff;
}
section.error-page-section-content .content-text {
  color: #00508c;
  font-size: 20px;
  margin-top: 45px;
  text-align: center;
}
section.error-page-section-content .content-text p {
  margin-top: 40px;
}
section.error-page-section-content .content-text p:first-child {
  margin-top: 0;
}
section.error-page-section-content .content-action {
  margin: 60px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
section.formularz-section-content {
  padding: 75px 0;
}
section.formularz-section-content h1 {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: -0.8px;
  color: #00508c;
}
section.formularz-section-content .content-flexbox {
  display: flex;
  justify-content: space-between;
  margin: 75px auto 0 auto;
}
section.formularz-section-content .content-flexbox .flexbox-column {
  width: 48%;
}
section.formularz-section-content .content-flexbox .product-desc-image h2 {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  color: #00beff;
  font-size: 24px;
}
section.formularz-section-content .content-flexbox .product-desc-image .service-image {
  width: 100%;
  max-width: 290px;
  margin: 50px auto 0 auto;
  position: relative;
}
section.formularz-section-content .content-flexbox .product-desc-image .service-image .image-product {
  width: 100%;
  position: relative;
  z-index: 110;
}
section.formularz-section-content .content-flexbox .product-desc-image .service-image .image-shadow {
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 150%;
}
section.formularz-section-content .content-flexbox .form-wrapper {
  padding: 30px;
  background: #e3edf5;
  border: 10px solid #f7fafc;
  position: relative;
}
section.formularz-section-content .content-flexbox .form-wrapper .form-heading h3 {
  color: #00508c;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  letter-spacing: -1px;
}
section.formularz-section-content .content-flexbox .form-wrapper .form-heading p {
  color: #00508c;
  font-size: 20px;
  text-align: center;
  letter-spacing: -1px;
}
section.formularz-section-content .content-flexbox .form-wrapper .form-service {
  margin-top: 20px;
}
section.formularz-section-content .content-flexbox .form-wrapper .form-service .form-group {
  position: relative;
  margin-bottom: 20px;
}
section.formularz-section-content .content-flexbox .form-wrapper .form-service .form-group label {
  font-weight: 400;
  font-size: 11px;
  color: #a0a0a0;
  margin-bottom: 0;
}
section.formularz-section-content .content-flexbox .form-wrapper .form-service .form-group input,
section.formularz-section-content .content-flexbox .form-wrapper .form-service .form-group textarea {
  background-color: transparent;
  margin-top: 0;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  padding-left: 0;
  padding-right: 30px;
}
section.formularz-section-content .content-flexbox .form-wrapper .form-service .form-group input:focus,
section.formularz-section-content .content-flexbox .form-wrapper .form-service .form-group textarea:focus {
  color: #00508c;
  border-color: #00508c;
}
section.formularz-section-content .content-flexbox .form-wrapper .form-service .form-group textarea {
  min-height: 80px;
  max-height: 80px;
}
section.formularz-section-content .content-flexbox .form-wrapper .form-service .form-group .alert-text {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-size: 11px;
  color: #ed1d24;
}
section.formularz-section-content .content-flexbox .form-wrapper .form-service .form-group.required:after {
  content: '*';
  font-weight: 700;
  color: #ed1d24;
  position: absolute;
  top: 33px;
  right: 5px;
  z-index: 111;
}
section.formularz-section-content .content-flexbox .form-wrapper .form-service .form-group.required.correct:after {
  color: #15a529;
}
section.formularz-section-content .content-flexbox .form-wrapper .form-service input[type='text'],
section.formularz-section-content .content-flexbox .form-wrapper .form-service input[type='email'],
section.formularz-section-content .content-flexbox .form-wrapper .form-service textarea {
  box-shadow: none;
}
section.formularz-section-content .content-flexbox .form-wrapper .form-service textarea {
  min-width: 100%;
  max-width: 100%;
  height: 180px;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service {
  position: relative;
  margin-top: 20px;
  margin-bottom: 0;
  cursor: pointer;
  width: 100%;
  display: block;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service .service-inner {
  display: flex;
  flex-wrap: wrap;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service .service-inner .box {
  margin-right: 10px;
  height: 15px;
  min-width: 15px;
  max-width: 15px;
  background: #fff;
  border: 1px solid #e1e1e1;
  position: relative;
  transition: all 0.2s;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service .service-inner .box:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  height: 104%;
  width: 104%;
  background: #00beff;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.2s;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service .service-inner .name {
  text-align: left;
  font-weight: 400;
  font-size: 11px;
  color: #a0a0a0;
  width: calc(100% - 25px);
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service .btn-more {
  font-size: 11px;
  font-weight: 700;
  display: block;
  color: #00beff;
  margin-top: 3px;
  margin-left: 25px;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service .btn-more:before {
  content: 'więcej';
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service .btn-more.active:before {
  content: none;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service .collapse-inner {
  display: block;
  margin-left: 27px;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service .collapse-inner span {
  display: block;
  text-align: left;
  font-weight: 400;
  font-size: 11px;
  color: #a0a0a0;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service .collapse-inner.active span:after {
  content: ' *';
  color: #ed1d24;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service.more-hidden .service-inner .name:after {
  content: '... *';
  color: #ed1d24;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service.more-hidden.more-visible .service-inner .name:after {
  content: '.';
  color: #a0a0a0;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service input {
  visibility: hidden;
  position: absolute;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service .alert-text {
  font-size: 11px;
  color: #ed1d24;
}
section.formularz-section-content .content-flexbox .form-wrapper .checkbox-service > input:checked + .service-inner .box:before {
  transform: translate(-50%, -50%) scale(1);
}
section.formularz-section-content .content-flexbox .form-wrapper .recaptcha-wrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.formularz-section-content .content-flexbox .form-wrapper .required-info {
  margin-top: 10px;
}
section.formularz-section-content .content-flexbox .form-wrapper .required-info p {
  font-size: 13px;
}
section.formularz-section-content .content-flexbox .form-wrapper .required-info p strong {
  color: #ed1d24;
  font-weight: 900;
  margin-right: 10px;
  top: 5px;
  font-size: 18px;
  position: relative;
}
section.formularz-section-content .content-flexbox .form-wrapper:after {
  content: '';
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.5);
  bottom: 0px;
  width: 80%;
  height: 10%;
  border-radius: 100%;
  left: 10%;
  right: 10%;
}
section.static-page-section-content {
  padding: 75px 0;
}
section.static-page-section-content h1 {
  font-size: 32px;
  color: #00508c;
  font-weight: 700;
  text-align: center;
}
section.static-page-section-content .regulations-text {
  margin-top: 40px;
  letter-spacing: -0.4px;
}
section.static-page-section-content .regulations-text h2 {
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  letter-spacing: -0.8px;
}
section.static-page-section-content .regulations-text ol {
  margin-top: 35px;
  padding-left: 45px;
  list-style: none;
  counter-reset: counter;
}
section.static-page-section-content .regulations-text ol li {
  margin-top: 40px;
  counter-increment: counter;
  position: relative;
}
section.static-page-section-content .regulations-text ol li:first-child {
  margin-top: 0;
}
section.static-page-section-content .regulations-text ol li::before {
  content: counter(counter) '. ';
  color: #00beff;
  font-weight: 700;
  position: absolute;
  left: -40px;
}
section.static-page-section-content .regulations-text ol ol {
  list-style: none;
  counter-reset: counter-my;
}
section.static-page-section-content .regulations-text ol ol li {
  margin-top: 10px;
  counter-increment: counter-my;
  position: relative;
}
section.static-page-section-content .regulations-text ol ol li::before {
  counter-increment: list;
  list-style-type: square;
  content: counter(counter-my, lower-alpha) ')';
  color: #00beff;
  font-weight: 700;
  position: absolute;
  left: -40px;
}
section.static-page-section-content .regulations-text p:last-child {
  margin-top: 60px;
  font-size: 14px;
  color: #a0a0a0;
}
section.section-footer {
  background: #323232;
}
section.section-footer .footer-top {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #5b5b5b;
}
section.section-footer .footer-top .top-heading h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}
section.section-footer .footer-top .top-heading p {
  font-size: 16px;
  color: #00beff;
  font-weight: 700;
  white-space: nowrap;
}
section.section-footer .footer-top .top-service {
  display: flex;
  flex-wrap: wrap;
}
section.section-footer .footer-top .top-service > * {
  margin-right: 25px;
}
section.section-footer .footer-top .top-service > *:last-child {
  margin-right: 0;
}
section.section-footer .footer-top .top-service .inner-wrapper {
  display: flex;
  align-items: center;
}
section.section-footer .footer-top .top-service .inner-wrapper > * {
  margin-right: 25px;
}
section.section-footer .footer-top .top-service .inner-wrapper > *:last-child {
  margin-right: 0;
}
section.section-footer .footer-top .top-service .service-heading {
  width: 100%;
  margin-right: 0;
  margin-bottom: 10px;
}
section.section-footer .footer-top .top-service .service-heading p {
  font-size: 16px;
  text-transform: uppercase;
  color: #00beff;
  font-weight: 700;
}
section.section-footer .footer-top .top-service .service-item {
  display: flex;
  align-items: center;
}
section.section-footer .footer-top .top-service .service-item > * {
  margin-right: 10px;
}
section.section-footer .footer-top .top-service .service-item > *:last-child {
  margin-right: 0;
}
section.section-footer .footer-top .top-service .service-item img {
  height: 18px;
}
section.section-footer .footer-top .top-service .service-item p {
  font-size: 14px;
  color: #fff;
}
section.section-footer .footer-top .top-service .service-item .item-clock-inner {
  display: flex;
  align-items: center;
}
section.section-footer .footer-top .top-service .service-item .item-clock-inner > * {
  margin-right: 10px;
}
section.section-footer .footer-top .top-service .service-item .item-clock-inner > *:last-child {
  margin-right: 0;
}
section.section-footer .footer-top .top-service .service-item .item-clock-inner .inner-helper {
  display: flex;
  align-items: center;
}
section.section-footer .footer-top .top-service .service-item .item-clock-inner .inner-helper > * {
  margin-right: 15px;
}
section.section-footer .footer-top .top-service .service-item .item-clock-inner .inner-helper > *:last-child {
  margin-right: 0;
}
section.section-footer .footer-top .no-break {
  white-space: nowrap !important;
}
section.section-footer .footer-flexbox {
  padding: 40px 0 70px 0;
  display: flex;
  justify-content: space-between;
}
section.section-footer .footer-flexbox .flexbox-service {
  list-style: none;
}
section.section-footer .footer-flexbox .flexbox-service li.heading {
  font-size: 14px;
  text-transform: uppercase;
  color: #00beff;
  font-weight: 700;
  margin-bottom: 10px;
}
section.section-footer .footer-flexbox .flexbox-service li.heading a {
  color: inherit;
  font-size: inherit;
  padding-left: 0;
  cursor: text;
  pointer-events: none;
}
section.section-footer .footer-flexbox .flexbox-service li.heading a:before {
  content: none;
}
section.section-footer .footer-flexbox .flexbox-service li a {
  font-size: 12.5px;
  color: #fff;
  padding-left: 18px;
  position: relative;
}
section.section-footer .footer-flexbox .flexbox-service li a:before {
  content: '■';
  color: #00beff;
  position: absolute;
  left: 0;
  top: -4px;
}
section.section-footer .footer-flexbox .flexbox-service li.social-media a img {
  width: 100%;
  max-width: 60px;
}
section.section-footer .footer-flexbox .flexbox-service li.social-media a:before {
  top: -2px;
}
section.section-footer .footer-credits {
  padding: 40px 0;
  border-top: 1px solid #5b5b5b;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.section-footer .footer-credits p {
  font-size: 13px;
  color: #fff;
}
section.section-footer .footer-credits .credits-webemo {
  display: flex;
  align-items: center;
}
section.section-footer .footer-credits .credits-webemo p {
  margin-right: 15px;
}
section.section-footer .footer-credits .credits-webemo img {
  height: 25px;
}
section.section-footer #cookies-alert {
  background-color: transparent;
  border-left: 0 none;
  border-right: 0 none;
  border-bottom: 0 none;
  border-top: 1px solid #5b5b5b;
  margin-bottom: 0;
  padding: 0.75rem 0;
}
section.section-footer #cookies-alert p {
  color: #fff;
  font-size: 12.5px;
}
section.section-footer #cookies-alert p .cookies-more {
  color: #00beff;
}
section.section-footer #cookies-alert a {
  cursor: pointer;
}
.facebook-button,
.teamviewer-button {
  position: fixed;
  z-index: 44;
  transform: translateY(-50%);
  right: 15px;
  transition: all 0.3s;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s;
  opacity: 0.5;
  visibility: visible;
  cursor: pointer;
}
.teamviewer-button {
  top: 240px;
}
.teamviewer-button img {
  height: 40px;
  width: 40px;
}
.facebook-button {
  background: #4267b2;
  top: 180px;
}
.facebook-button img {
  height: 15px;
  width: 15px;
}
.facebook-button:hover,
.teamviewer-button:hover {
  opacity: 1;
}
@media (max-width: 1650px) {
  section.section-footer .footer-top .top-service {
    display: block;
  }
  section.section-footer .footer-top .top-service > * {
    margin-right: 0;
  }
  section.section-footer .footer-top .top-service .service-heading {
    width: auto;
    margin-right: 20px;
  }
  section.section-footer .footer-top .top-service .service-heading,
  section.section-footer .footer-top .top-service .item-clock {
    display: inline-block;
  }
}
@media (max-width: 1366px) {
  body .container-fluid {
    max-width: 1260px;
  }
  nav.section-header .header-top .top-logo .logo-image img {
    height: 36px;
    width: 202px;
  }
  section.section-testimonials .owl-carousel-testimonials-wrapper {
    padding: 0 100px;
  }
}
@media (max-width: 1250px) {
  nav.section-header .header-top {
    padding: 32px 0;
  }
  nav.section-header .header-top .top-logo .logo-slogan p {
    white-space: normal;
  }
}
@media (max-width: 1230px) {
  nav.section-header .header-top {
    padding: 32px 0;
  }
  section.section-about .about-flexbox {
    justify-content: space-between;
  }
  section.section-about .about-flexbox .flexbox-service {
    margin-right: 0;
  }
  section.section-about .about-flexbox .flexbox-service .service-square {
    height: 165px;
    width: 165px;
  }
  section.section-about .about-flexbox .flexbox-service .service-name {
    margin-top: 40px;
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service .service-square {
    height: 150px;
    width: 150px;
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service .service-square .square-icon {
    height: 30px;
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service .service-square .square-number {
    font-size: 38px;
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service .service-shadow {
    height: 150px;
    width: 150px;
  }
}
@media (max-width: 1199.98px) {
  nav.section-header .header-dropdown .dropdown-inner .inner-service .service-image .image-product {
    height: 136px;
  }
  nav.section-header .header-dropdown .dropdown-inner .divider {
    height: 136px;
  }
  section.section-map .map-form {
    margin-left: 45%;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-category {
    font-size: 18px;
    min-height: 54px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar {
    width: 24%;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body {
    width: 66%;
  }
  section.formularz-section-content .content-flexbox .flexbox-column {
    width: 60%;
  }
  section.section-footer .footer-flexbox {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    width: 25%;
  }
  section.section-footer .footer-flexbox .flexbox-service:nth-child(n+5) {
    margin-top: 50px;
  }
}
@media (max-width: 1149.98px) {
  nav.section-header .header-top {
    display: none;
  }
  nav.section-header .header-flexbox-background {
    background: #fff;
  }
  nav.section-header .container-width-100 {
    padding: 0;
  }
  nav.section-header .header-flexbox {
    position: fixed;
    top: 0px;
    z-index: 1500;
    width: 100%;
    background-color: #fff;
    display: flex;
    padding: 20px 15px;
  }
  nav.section-header .header-flexbox .flexbox-logo {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-logo img {
    height: 34px;
    width: 191.34px;
  }
  nav.section-header .header-flexbox .nav-button {
    display: flex;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background: #e3edf5;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 0 15px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    max-height: calc(100vh - 140px);
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    margin-right: 0;
    width: 100%;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a,
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid #fff;
    width: 100%;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a img,
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown-button img {
    margin-right: 15px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a .subpage-icon,
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown-button .subpage-icon {
    height: 30px;
    margin-right: 5px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-hide {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show .collapse.show {
    border-bottom: 1px solid #fff;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show ul {
    padding-left: 1.5rem;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show .header-dropdown {
    position: relative;
    display: block;
    z-index: 111;
    visibility: visible;
    opacity: 1;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show .header-dropdown .dropdown-inner {
    flex-wrap: wrap;
    padding: 15px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show .header-dropdown .dropdown-inner .inner-service {
    margin-top: 15px;
    height: auto;
    width: calc(100% / 3);
    border-bottom: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show .header-dropdown .dropdown-inner .inner-service .service-category {
    margin-top: 10px;
    margin-bottom: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show .header-dropdown .dropdown-inner .inner-service .service-name {
    margin-top: 0;
    text-transform: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show .header-dropdown .dropdown-inner .inner-service:hover .service-image .image-product {
    transform: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show .header-dropdown .dropdown-inner .inner-service:hover .service-image .image-shadow {
    filter: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show .header-dropdown .dropdown-inner .inner-service:nth-child(n+4) {
    margin-top: 25px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show .header-dropdown .dropdown-inner .divider {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show .header-dropdown:after {
    content: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-child a,
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-child .dropdown-button {
    border-bottom: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper:after {
    content: '';
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
    height: 30px;
    opacity: 0.25;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  nav.section-header .header-dropdown {
    display: none;
  }
}
@media (max-width: 1049.98px) {
  section.section-footer .footer-top .top-service {
    display: flex;
    flex-direction: column;
  }
  section.section-footer .footer-top .top-service .inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  section.section-footer .footer-top .top-service .service-heading {
    margin-bottom: 0;
  }
  section.section-footer .footer-top .top-service .service-item {
    margin-top: 10px;
  }
  section.section-footer .footer-top .no-break {
    display: block;
  }
}
@media (max-width: 991.98px) {
  .body-wrapper {
    margin-top: 80px;
  }
  .body-wrapper .office-img {
    background: url(../../files/dynamic/media/100642488_profektus-sklep-mobile.jpg) 20% center no-repeat !important;
  }
  section.section-offer .offer-service {
    min-height: 29vh;
    display: flex;
    align-items: center;
  }
  section.section-offer .offer-service .inner-wrapper .service-background {
    background: url(../img/pictures/oprogramowanie-dla-firm-mobile.jpg) center 15% no-repeat !important;
    background-size: cover !important;
  }
  section.section-offer .offer-service .service-inner {
    padding: 50px 0;
    text-align: center;
  }
  section.section-offer .offer-service .service-inner p {
    display: none;
  }
  section.section-offer .offer-service .service-inner .btn {
    margin-top: 30px;
  }
  section.section-offer .offer-service .service-cover {
    width: 100%;
    left: 0;
    background: rgba(227, 237, 245, 0.75);
  }
  section.section-offer .offer-service .service-background {
    width: 100%;
    background-position: center 20% !important;
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds {
    flex-direction: column;
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds a:nth-of-type(1) .service-image {
    background: url(../img/pictures/oprogramowanie-dla-przychodni-aptek-mobile.jpg) center 30% no-repeat !important;
    background-size: cover !important;
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds a:nth-of-type(2) .service-image {
    background: url(../img/pictures/opieka-informatyczna-mobile.jpg) center 15% no-repeat !important;
    background-size: cover !important;
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service {
    width: 100%;
    height: 50%;
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service .service-cover {
    width: 100%;
    left: 0%;
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service .service-image {
    width: 100%;
    right: 0;
    background-position: center 25% !important;
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service:nth-child(1) .service-cover {
    background: rgba(111, 154, 197, 0.75);
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service:nth-child(2) {
    background: #00508c;
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox-backgrounds .background-service:nth-child(2) .service-cover {
    background: rgba(0, 80, 140, 0.75);
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox {
    flex-direction: column;
    display: block;
  }
  @supports (display: flex) {
    section.section-offer .offer-flexbox-wrapper .offer-flexbox {
      display: flex;
    }
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox .flexbox-service {
    padding: 50px 15px;
    min-height: 29vh;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox .flexbox-service h2 {
    width: 100%;
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox .flexbox-service p {
    width: 100%;
    display: none;
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox .flexbox-service .btn {
    margin-top: 30px;
  }
  section.section-offer .offer-chevron {
    left: 0;
    transform: none;
  }
  section.section-about .about-flexbox {
    flex-wrap: wrap;
    justify-content: center;
    display: block;
  }
  @supports (display: flex) {
    section.section-about .about-flexbox {
      display: flex;
    }
  }
  section.section-about .about-flexbox .flexbox-service {
    width: 49.5%;
    display: inline-block;
  }
  @supports (display: flex) {
    section.section-about .about-flexbox .flexbox-service {
      width: 36%;
      display: flex;
    }
  }
  section.section-about .about-flexbox .flexbox-service .service-square {
    margin: 0 auto;
  }
  section.section-about .about-flexbox .flexbox-service .service-name {
    margin-top: 35px;
  }
  section.section-about .about-flexbox .flexbox-service:nth-child(n+3) {
    margin-top: 50px;
  }
  section.section-comarch {
    border-bottom: 1px solid #00508c;
  }
  section.section-comarch .comarch-flexbox {
    flex-direction: column;
    justify-content: center;
  }
  section.section-comarch .comarch-flexbox .flexbox-image {
    display: none;
  }
  section.section-comarch .comarch-flexbox .flexbox-description {
    width: 100%;
    max-width: 530px;
    text-align: center;
  }
  section.section-map {
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  section.section-map .localisation-map {
    top: auto;
    height: 60vh;
    left: auto;
    width: 100%;
    position: relative;
  }
  section.section-map .localisation-map.map-index {
    background: url(../../img/backgrounds/mapa-profektus-mobile.jpg) 15% center;
  }
  section.section-map .map-form {
    padding-top: 75px;
    padding-bottom: 75px;
    margin-top: 0;
    margin-left: 0;
  }
  .content-welcome {
    text-align: center;
  }
  .content-welcome .welcome-inner p {
    width: 100%;
  }
  .content-welcome .welcome-cover {
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
    background: rgba(227, 237, 245, 0.75);
  }
  .content-welcome .welcome-background {
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
    background-position: center !important;
  }
  section.o-firmie-section-content .content-advantages .container {
    max-width: 100%;
  }
  section.o-firmie-section-content .content-about .offset-anchor-span {
    top: -80px;
  }
  section.oprogramowanie-section-content .content-welcome .welcome-background {
    background-position: center top !important;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service {
    width: 25%;
  }
  section.oprogramowanie-section-content .content-product-service-background {
    text-align: center;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading {
    max-width: 700px;
    margin: 0 auto;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading ul {
    margin-top: 0;
    margin-left: 30px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading ul li {
    padding-left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading ul li:before {
    min-width: 10px;
    margin-right: 10px;
    display: block;
    position: relative;
    left: auto;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading ul:first-child {
    margin-top: 15px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading .heading-list {
    display: block;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading .heading-list > * {
    margin-right: 0;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner {
    flex-direction: column;
    align-items: center;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar .sidebar-image {
    margin: 0 50px 0 0;
    max-width: 157px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar .sidebar-buttons {
    display: flex;
    flex-direction: column;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar .sidebar-buttons .btn {
    margin-top: 10px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar .sidebar-buttons .btn:nth-child(1) {
    margin-top: 0;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body {
    width: 100%;
    max-width: 700px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner {
    margin-top: 20px;
    grid-gap: 20px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service {
    margin-top: 30px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .collapse-inner {
    margin-top: 20px;
  }
  section.oprogramowanie-section-content .content-product-service-background .offset-anchor-span {
    top: -80px;
  }
  section.outsourcing-section-content .content-advantages .advantage-service {
    width: calc(100% / 3);
  }
  section.outsourcing-section-content .content-advantages .advantage-service img {
    height: 65px;
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox {
    flex-wrap: wrap;
    justify-content: center;
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service {
    width: 30%;
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service .service-shadow {
    left: 50%;
    transform: translateX(-50%);
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service:nth-child(4),
  section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service:nth-child(5) {
    margin-top: 50px;
  }
  section.outsourcing-section-content .content-offer {
    overflow: hidden;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox {
    flex-direction: column;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .offset-anchor-span {
    top: -80px;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-image {
    margin: 0 100px 0 0;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-name {
    margin-top: 0;
    text-align: left;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description {
    margin-top: 20px;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description .btn {
    width: 100%;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description .btn .text {
    justify-content: center;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description:after {
    content: none;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service.service-highlight .service-name {
    transform: none;
  }
  section.formularz-section-content .content-flexbox .flexbox-column {
    width: 80%;
  }
}
@media (max-width: 849.98px) {
  section.o-firmie-section-content .welcome-background {
    background: url(../../img/pictures/o-firmie-powitanie-mobile.jpg) 50% center no-repeat !important;
    background-size: cover !important;
  }
  section.section-footer .footer-top {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  section.section-footer .footer-top .top-heading {
    width: 100%;
  }
  section.section-footer .footer-top .top-service {
    width: 40%;
    margin-top: 30px;
  }
  section.section-footer .footer-top .top-service:nth-child(3) {
    width: 60%;
  }
}
@media (max-width: 767.98px) {
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show .header-dropdown .dropdown-inner .inner-service {
    width: 50%;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.display-collapsed-show .header-dropdown .dropdown-inner .inner-service:nth-child(n+3) {
    margin-top: 25px;
  }
  section.section-about .about-flexbox .flexbox-service {
    width: 48%;
  }
  @supports (display: flex) {
    section.section-about .about-flexbox .flexbox-service {
      width: 50%;
    }
  }
  section.section-testimonials .owl-carousel-testimonials-wrapper {
    padding: 0;
  }
  section.o-firmie-section-content .welcome-background {
    background: url(../../img/pictures/o-firmie-powitanie-mobile.jpg) 50% center no-repeat !important;
    background-size: cover !important;
  }
  section.o-firmie-section-content .content-advantages .advantages-flexbox .advantage {
    width: calc(100% / 2 - 15px);
  }
  section.o-firmie-section-content .content-advantages .advantages-flexbox .advantage:nth-last-child(-n + 2) {
    justify-self: unset;
    margin-left: 0;
    margin-right: 0;
  }
  section.o-firmie-section-content .content-advantages .advantages-flexbox .advantage:nth-last-child(-n + 1) {
    justify-self: center;
    margin-left: auto;
    margin-right: auto;
  }
  section.o-firmie-section-content .content-about-flexbox {
    flex-direction: column;
  }
  section.o-firmie-section-content .content-about-flexbox .flexbox-service {
    width: 100%;
  }
  section.o-firmie-section-content .content-about-flexbox .flexbox-service:not(:first-child) {
    margin-top: 40px;
  }
  section.o-firmie-section-content .content-company-activities .content-wrapper .activities-wrapper {
    flex-direction: column;
  }
  section.o-firmie-section-content .content-company-activities .content-wrapper .activities-wrapper .flexbox-service {
    width: 100%;
  }
  section.o-firmie-section-content .content-company-activities .content-wrapper .activities-wrapper .flexbox-service:nth-of-type(2) {
    margin-top: 20px;
  }
  section.oprogramowanie-section-content .welcome-background {
    background: url(../../img/pictures/oprogramowanie-dla-firm-mobile.jpg) 50% center no-repeat !important;
    background-size: cover !important;
  }
  section.oprogramowanie-section-content .content-products .container {
    max-width: 100%;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox {
    display: block;
  }
  @supports (display: grid) {
    section.oprogramowanie-section-content .content-products .products-flexbox {
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service {
    display: block;
    width: 75%;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service:first-child {
    margin-top: 0;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-category {
    font-size: 18px;
    min-height: auto;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-image {
    margin-top: 20px;
    width: 120px;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-name {
    margin-top: 20px;
    font-size: 18px;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .btn {
    margin-top: 20px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner {
    display: block;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service {
    display: inline-block;
    width: 46%;
    margin-left: 1%;
    margin-right: 1%;
    vertical-align: top;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service:last-child {
    float: left;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service.hidden-md {
    display: none;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .collapse-inner .body-service:first-child {
    display: inline-block;
  }
  section.outsourcing-section-content .welcome-background {
    background: url(../img/pictures/opieka-informatyczna-mobile.jpg) center 15% no-repeat !important;
    background-size: cover !important;
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox {
    justify-content: space-around;
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service {
    width: 36%;
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service:nth-child(n+3) {
    margin-top: 50px;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service {
    display: block;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-image {
    margin: 0 auto;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-name {
    margin-top: 30px;
    text-align: center;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description {
    max-width: 400px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  section.outsourcing-section-content .content-offer .offer-flexbox .flexbox-service .service-description .btn {
    width: 100%;
    max-width: 290px;
  }
  section.outsourcing-section-content .content-offer .offer-cta .btn {
    width: 100%;
    max-width: 290px;
  }
  section.outsourcing-section-content .content-offer .offer-cta .btn .text {
    justify-content: center;
  }
  .instalacje-section-content .welcome-background {
    background: url(../../img/pictures/instalacje-powitanie-mobile.jpg) center 15% no-repeat !important;
    background-size: cover !important;
  }
  section.sklep-section-content .welcome-background,
  section.sklep-section-content .content-shop {
    background: url(../../img/pictures/profektus-sklep-mobile.jpg) center no-repeat !important;
    background-size: cover!important;
  }
  .serwis-section-content .welcome-background {
    background: url(../../img/pictures/serwis-komputerow-i-pomoc-techniczna-powitanie-mobile.jpg) 20% center no-repeat !important;
    background-size: cover!important;
  }
  .kontakt-section-content .welcome-background {
    background: url(../../img/pictures/kontakt-powitanie-mobile.jpg) 60% center no-repeat !important;
    background-size: cover !important;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    width: 40%;
  }
  section.section-footer .footer-flexbox .flexbox-service:nth-child(n+3) {
    margin-top: 40px;
  }
}
@media (max-width: 575.98px) {
  .g-recaptcha {
    transform: scale(0.75);
    transform-origin: 0 0;
  }
  section.section-offer .offer-service .service-inner h1 {
    font-size: 24px;
  }
  section.section-offer .offer-flexbox-wrapper .offer-flexbox .flexbox-service h2 {
    font-size: 24px;
  }
  section.section-offer .offer-chevron {
    left: 0;
    transform: none;
  }
  .content-welcome {
    min-height: 250px;
    text-align: center;
  }
  .content-welcome .welcome-inner h1 {
    font-size: 30px;
  }
  .content-welcome .welcome-inner p {
    font-size: 15px;
  }
  section.o-firmie-section-content .content-advantages .advantages-flexbox .advantage h3 {
    height: auto;
  }
  section.o-firmie-section-content .content-advantages .advantages-flexbox .advantage p {
    margin-top: 25px;
  }
  section.o-firmie-section-content .content-about {
    text-align: center;
  }
  section.o-firmie-section-content .content-about h2 {
    font-size: 24px;
  }
  section.o-firmie-section-content .content-company-activities {
    padding-bottom: 60px;
  }
  section.o-firmie-section-content .content-company-activities .content-wrapper .content-heading h2 {
    font-size: 24px;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox {
    display: block;
  }
  @supports (display: grid) {
    section.oprogramowanie-section-content .content-products .products-flexbox {
      display: grid;
    }
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service {
    width: 100%;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-category {
    font-size: 16px;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-image {
    margin-top: 15px;
    width: 80px;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-name {
    margin-top: 15px;
    font-size: 16px;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .btn {
    margin-top: 15px;
    font-size: 12px;
    padding: 0 40px 0 10px;
    height: 30px;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .btn .arrow {
    height: 30px;
    width: 30px;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .btn .arrow img {
    height: 10px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading h2 {
    font-size: 24px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading p {
    margin-top: 10px;
    font-size: 15px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading ul li {
    font-size: 15px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar {
    flex-direction: column;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar .sidebar-image {
    margin: 0;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-sidebar .sidebar-buttons {
    margin-top: 50px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service img {
    height: 45px;
    width: 45px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service h3 {
    font-size: 14px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service p {
    font-size: 13px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service .service-more .more-icon {
    height: 45px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service .service-more .more-icon .arrow {
    height: 30px;
    width: 30px;
  }
  section.outsourcing-section-content .content-advantages .advantage-service {
    width: calc(100% / 2);
  }
  section.outsourcing-section-content .content-advantages .advantage-service img {
    height: 65px;
  }
  section.outsourcing-section-content .content-offer .offer-heading h2 {
    font-size: 24px;
  }
  section.outsourcing-section-content .content-offer .offer-cta h3 {
    font-size: 20px;
  }
  section.section-footer .footer-top .top-service:nth-child(3) {
    padding-left: 20px;
  }
  section.section-footer .footer-top .top-service:nth-child(3) .service-item .item-clock-inner {
    align-items: flex-start;
  }
  section.section-footer .footer-top .top-service:nth-child(3) .service-item .item-clock-inner img {
    transform: translateY(2px);
  }
  section.section-footer .footer-top .top-service:nth-child(3) .service-item .item-clock-inner .inner-helper {
    display: block;
  }
  section.section-footer .footer-flexbox {
    padding: 40px 0;
    display: block;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    width: 100%;
    text-align: center;
  }
  section.section-footer .footer-flexbox .flexbox-service li.heading a {
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  section.section-footer .footer-flexbox .flexbox-service li.heading a:after {
    margin-left: 10px;
    content: '';
    background: url(../img/icons/arrow-right-white.svg) center;
    background-size: cover;
    display: block;
    height: 8px;
    min-width: 8px;
    transform: rotate(90deg);
  }
  section.section-footer .footer-flexbox .flexbox-service li a {
    padding-left: 0;
  }
  section.section-footer .footer-flexbox .flexbox-service li a:before {
    content: none;
  }
  section.section-footer .footer-flexbox .flexbox-service:nth-child(n+2) {
    margin-top: 20px;
  }
  section.section-footer .footer-credits {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  section.section-footer .footer-credits .credits-webemo {
    margin-top: 30px;
  }
  section.section-footer .footer-credits .credits-webemo p {
    margin-right: 10px;
  }
  section.section-footer .footer-credits .credits-webemo img {
    height: 20px;
  }
}
@media (max-width: 460px) {
  section.section-map .map-form .recaptcha-wrapper {
    display: block;
  }
  section.section-map .map-form .recaptcha-wrapper .btn {
    margin-top: 5px;
    min-width: 50%;
  }
  section.section-map .map-form .recaptcha-wrapper .btn .text {
    display: inline-block;
    line-height: 40px;
    width: 100%;
    text-align: center;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner {
    margin-top: 30px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service {
    margin-top: 30px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .body-inner .body-service h3 {
    font-size: 11px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-inner .inner-body .collapse-inner {
    margin-top: 20px;
  }
  section.oprogramowanie-section-content .content-product-service-background .content-product-service .service-heading ul {
    margin-left: 10px;
  }
  section.section-footer .footer-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  section.section-footer .footer-top .top-heading {
    text-align: center;
  }
  section.section-footer .footer-top .top-heading p {
    white-space: normal;
    font-size: 18px;
  }
  section.section-footer .footer-top .top-service {
    display: block;
    text-align: left;
    width: 100%;
  }
  section.section-footer .footer-top .top-service > * {
    margin-right: 0;
  }
  section.section-footer .footer-top .top-service .inner-wrapper {
    display: block;
  }
  section.section-footer .footer-top .top-service .inner-wrapper > * {
    margin-right: 0;
  }
  section.section-footer .footer-top .top-service .service-heading {
    text-align: center;
  }
  section.section-footer .footer-top .top-service .service-item {
    text-align: left;
    justify-content: flex-start;
  }
  section.section-footer .footer-top .top-service .service-item .item-clock-inner {
    justify-content: flex-start;
  }
  section.section-footer .footer-top .top-service .service-item .item-clock-inner .inner-helper {
    display: block;
  }
  section.section-footer .footer-top .top-service .service-heading,
  section.section-footer .footer-top .top-service .item-clock {
    display: block;
  }
  section.section-footer .footer-top .top-service:nth-child(2) {
    max-width: 230px;
    width: 100%;
  }
  section.section-footer .footer-top .top-service:nth-child(3) {
    width: 100%;
    max-width: 230px;
    padding-left: 0;
  }
}
@media (max-width: 400px) {
  nav.section-header .header-dropdown .dropdown-inner .inner-service .service-category {
    min-height: 42px;
  }
  nav.section-header .header-dropdown .dropdown-inner .inner-service .service-name {
    min-height: 42px;
  }
  section.section-about {
    padding: 50px 0;
  }
  section.section-about .about-flexbox {
    margin-top: 50px;
    display: block;
  }
  @supports (display: flex) {
    section.section-about .about-flexbox {
      display: flex;
    }
  }
  section.section-about .about-flexbox .flexbox-service {
    width: 100%;
  }
  section.section-about .about-flexbox .flexbox-service:nth-child(n+2) {
    margin-top: 30px;
  }
  section.section-testimonials {
    padding: 50px 0;
  }
  section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials {
    margin-top: 50px;
  }
  section.section-testimonials .owl-carousel-testimonials-wrapper .owl-carousel-testimonials .owl-controls {
    margin-top: 50px;
  }
  section.section-testimonials .testimonials-cta {
    margin-top: 50px;
  }
  section.section-comarch {
    padding: 50px 0;
  }
  section.section-map .map-form {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  section.o-firmie-section-content .content-advantages .advantages-flexbox {
    padding-bottom: 50px;
  }
  section.o-firmie-section-content .content-advantages .advantages-flexbox .advantage {
    width: 100%;
    margin: 50px 0 0 0;
  }
  section.o-firmie-section-content .content-about {
    padding: 50px 0;
  }
  section.o-firmie-section-content .content-about p {
    margin-top: 30px;
  }
  section.o-firmie-section-content .content-about p:first-child {
    margin-top: 30px;
  }
  section.oprogramowanie-section-content .content-products {
    padding: 50px 0;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-category {
    font-size: 14px;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-image {
    width: 60px;
  }
  section.oprogramowanie-section-content .content-products .products-flexbox .inner-service .service-name {
    font-size: 14px;
  }
  section.outsourcing-section-content .content-advantages {
    padding: 10px 0 50px 0;
    display: block;
  }
  @supports (display: flex) {
    section.outsourcing-section-content .content-advantages {
      display: flex;
    }
  }
  section.outsourcing-section-content .content-advantages .advantage-service {
    width: 100%;
  }
  section.outsourcing-section-content .content-advantages .advantage-service img {
    height: 50px;
  }
  section.outsourcing-section-content .content-numbers {
    padding: 50px 0;
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox {
    display: block;
  }
  @supports (display: flex) {
    section.outsourcing-section-content .content-numbers .numbers-flexbox {
      display: flex;
    }
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service {
    width: 100%;
  }
  section.outsourcing-section-content .content-numbers .numbers-flexbox .flexbox-service:nth-child(n+2) {
    margin-top: 30px;
  }
  section.outsourcing-section-content .content-offer {
    padding: 50px 0;
  }
  .teamviewer-button {
    display: none;
  }
}
@media (max-width: 359px) {
  nav.section-header .header-flexbox .flexbox-logo img {
    height: 28px;
    width: 157.55px;
  }
  nav.section-header .header-dropdown .dropdown-inner .inner-service {
    width: 100% !important;
  }
  nav.section-header .header-dropdown .dropdown-inner .inner-service .service-category {
    min-height: unset;
  }
  nav.section-header .header-dropdown .dropdown-inner .inner-service .service-name {
    min-height: unset;
  }
}
@media (max-width: 315px) {
  .g-recaptcha {
    transform: scale(0.6);
    transform-origin: 0 0;
  }
}
@media (max-width: 1366px) {
  section.kontakt-section-content .content-contact .contact-flexbox .contact-service {
    width: 31%;
  }
  section.kontakt-section-content .content-contact .contact-flexbox .contact-service .service-inner {
    padding: 0;
  }
}
@media (max-width: 1199.98px) {
  section.serwis-section-content .services-flexbox .service-column .service-card .contract-clients {
    padding: 45px 40px;
  }
  .contact-icon-row .icon-wrapper {
    height: 35px;
    width: 35px;
    min-width: 35px;
    margin-right: 10px;
  }
  .contact-icon-row span {
    margin-right: 10px;
  }
  .contact-icon-row span:nth-of-type(2) {
    margin-right: 10px;
  }
}
@media (max-width: 991.98px) {
  .contact-icon-row {
    min-width: 305px;
  }
  section.serwis-section-content .content-welcome .welcome-inner h1 {
    width: 100%;
  }
  section.serwis-section-content .content-welcome .welcome-inner p {
    width: 100%;
  }
  section.serwis-section-content .services-flexbox {
    flex-wrap: wrap;
  }
  section.serwis-section-content .services-flexbox .service-column {
    width: 100%;
    max-width: 600px;
    margin: 75px auto 0;
  }
  section.serwis-section-content .services-flexbox .service-column:first-child {
    margin-top: 0;
  }
  section.serwis-section-content .services-flexbox .service-column .service-card {
    margin: 0;
  }
  section.serwis-section-content .services-flexbox .service-column .service-card .contract-clients {
    height: auto;
  }
  section.kontakt-section-content .content-contact {
    padding-bottom: 0;
  }
  section.kontakt-section-content .content-contact .contact-flexbox {
    flex-wrap: wrap;
  }
  section.kontakt-section-content .content-contact .contact-flexbox .contact-service {
    width: 100%;
    margin: 0 auto;
    margin-top: 75px;
  }
  section.kontakt-section-content .content-contact .contact-flexbox .contact-service:first-child {
    margin-top: 0;
  }
  section.kontakt-section-content .content-contact .contact-flexbox .contact-service .service-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  section.kontakt-section-content .content-contact .contact-flexbox .separator {
    display: none;
  }
  section.kontakt-section-content .content-contact .contact-alert {
    margin: 50px auto 0;
    max-width: 300px;
  }
  .contact-icon-row .icon-wrapper {
    height: 40px;
    width: 40px;
    margin-right: 15px;
  }
  .contact-icon-row span:nth-of-type(2) {
    margin-right: 15px;
  }
  section.error-page-section-content h1 {
    font-size: 68px;
  }
  section.formularz-section-content .content-flexbox .flexbox-column:nth-child(2) {
    min-width: 450px;
    margin-left: 30px;
  }
  section.formularz-section-content .content-flexbox .product-desc-image h2 {
    font-size: 18px;
  }
  section.formularz-section-content .content-flexbox .form-wrapper {
    min-width: 450px;
    max-width: 720px;
  }
  section.formularz-section-content--single .content-flexbox .flexbox-column {
    width: 65%;
  }
  section.instalacje-section-content .content-categories-service-background .offset-anchor-span {
    top: -80px;
  }
  section.sklep-section-content .content-categories-service-background .offset-anchor-span,
  section.kasy-fiskalne-section-content .content-categories-service-background .offset-anchor-span {
    top: -80px;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 767.98px) {
  section.kontakt-section-content .content-contact .contact-flexbox .contact-service {
    width: 100%;
  }
  section.kontakt-section-content .content-contact .contact-flexbox .contact-service:nth-of-type(2) {
    margin-top: 50px;
  }
  section.kontakt-section-content .content-contact .contact-flexbox .contact-service .service-header h2 {
    height: auto;
  }
  section.kontakt-section-content .content-contact .alert-flexbox .contact-service:nth-of-type(2) {
    margin-top: 75px;
  }
  @supports (display: flex) {
    section.sklep-section-content .content-products .products-flexbox,
    section.kasy-fiskalne-section-content .content-products .products-flexbox {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  section.sklep-section-content .content-products .products-flexbox .inner-service,
  section.kasy-fiskalne-section-content .content-products .products-flexbox .inner-service {
    padding: 20px 10px;
  }
  section.sklep-section-content .content-products .products-flexbox .inner-service .service-image img,
  section.kasy-fiskalne-section-content .content-products .products-flexbox .inner-service .service-image img {
    height: 60px;
  }
  section.sklep-section-content .content-products .products-flexbox .inner-service .service-name,
  section.kasy-fiskalne-section-content .content-products .products-flexbox .inner-service .service-name {
    font-size: 18px;
  }
  section.sklep-section-content .content-categories-service-background,
  section.kasy-fiskalne-section-content .content-categories-service-background {
    padding: 50px 0 20px 0;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox {
    flex-wrap: wrap;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-description,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-description {
    width: 100%;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-description.order1,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-description.order1 {
    order: 1;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image {
    width: 100%;
    margin-top: 50px;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image.order2,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image.order2 {
    order: 2;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image img,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image img {
    width: 70%;
    margin: 30px auto;
    display: block;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox {
    grid-template-columns: 1fr 1fr;
  }
  section.instalacje-section-content .content-products .products-flexbox .inner-service {
    padding: 20px 10px;
  }
  section.instalacje-section-content .content-products .products-flexbox .inner-service .service-image img {
    height: 60px;
  }
  section.instalacje-section-content .content-products .products-flexbox .inner-service .service-name {
    font-size: 18px;
  }
  section.instalacje-section-content .content-categories-service-background {
    padding: 50px 0;
  }
  section.instalacje-section-content .content-categories-service-background .category-inner .inner-flexbox {
    flex-wrap: wrap;
  }
  section.instalacje-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-description {
    width: 100%;
  }
  section.instalacje-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image {
    width: 100%;
    margin-top: 50px;
  }
  section.instalacje-section-content .content-categories-service-background .category-inner .inner-flexbox .flexbox-image img {
    width: 70%;
    display: block;
    margin: 0 auto;
  }
  section.error-page-section-content h1 {
    font-size: 50px;
  }
  section.error-page-section-content .content-text p {
    margin-top: 20px;
    font-size: 16px;
  }
  section.error-page-section-content .content-text p:first-child {
    margin-top: 0;
  }
  section.error-page-section-content .content-action {
    margin-top: 50px;
  }
  section.do-pobrania-section-content .table-wrapper {
    padding: 50px 0;
  }
  section.do-pobrania-section-content .table-wrapper .table thead tr th {
    padding: 0;
  }
  section.do-pobrania-section-content .table-wrapper .table thead tr th:first-child {
    width: 10px;
  }
  section.do-pobrania-section-content .table-wrapper .table thead tr th:nth-child(2) {
    min-width: 175px;
  }
  section.do-pobrania-section-content .table-wrapper .table thead tr th:last-child {
    width: 10px;
  }
  section.do-pobrania-section-content .table-wrapper .table tbody tr:hover th:nth-child(4) a,
  section.do-pobrania-section-content .table-wrapper .table tbody tr:hover td:nth-child(4) a {
    opacity: unset;
  }
  section.do-pobrania-section-content .table-wrapper .table tbody tr th,
  section.do-pobrania-section-content .table-wrapper .table tbody tr td {
    padding: 0;
  }
  section.do-pobrania-section-content .table-wrapper .table tbody tr th:first-child,
  section.do-pobrania-section-content .table-wrapper .table tbody tr td:first-child {
    width: auto;
  }
  section.do-pobrania-section-content .table-wrapper .table tbody tr th:nth-child(3),
  section.do-pobrania-section-content .table-wrapper .table tbody tr td:nth-child(3) {
    padding: 10px 0;
  }
  section.do-pobrania-section-content .table-wrapper .table tbody tr th:nth-child(3) p,
  section.do-pobrania-section-content .table-wrapper .table tbody tr td:nth-child(3) p {
    font-size: 12px;
  }
  section.do-pobrania-section-content .table-wrapper .table tbody tr th:nth-child(4),
  section.do-pobrania-section-content .table-wrapper .table tbody tr td:nth-child(4) {
    width: auto;
  }
  section.do-pobrania-section-content .table-wrapper .table tbody tr th:nth-child(4) a,
  section.do-pobrania-section-content .table-wrapper .table tbody tr td:nth-child(4) a {
    opacity: 1;
  }
  section.do-pobrania-section-content .table-wrapper .table tbody tr th span,
  section.do-pobrania-section-content .table-wrapper .table tbody tr td span {
    margin-right: 10px;
  }
  section.do-pobrania-section-content .table-wrapper .table tbody tr th span:last-child,
  section.do-pobrania-section-content .table-wrapper .table tbody tr td span:last-child {
    margin-right: 0;
  }
  section.do-pobrania-section-content .table-wrapper .table tbody tr th span:first-of-type,
  section.do-pobrania-section-content .table-wrapper .table tbody tr td span:first-of-type {
    font-weight: 600;
    font-size: 12px;
  }
  section.formularz-section-content .content-flexbox {
    flex-wrap: wrap;
  }
  section.formularz-section-content .content-flexbox .flexbox-column {
    width: 100%;
  }
  section.formularz-section-content .content-flexbox .flexbox-column:nth-child(2) {
    margin-left: 0;
    min-width: unset;
    margin-top: 50px;
  }
  section.formularz-section-content .content-flexbox .product-desc-image .service-image {
    width: 60%;
    display: block;
  }
}
@media (max-width: 575.98px) {
  section.kontakt-section-content .content-contact {
    padding: 50px 0 0;
  }
  section.kontakt-section-content .content-contact .contact-flexbox .contact-service {
    margin-top: 50px;
  }
  section.kontakt-section-content .content-contact .contact-flexbox .contact-service .service-header h2 {
    font-size: 18px;
  }
  .contact-icon-row {
    font-size: 14px;
    min-width: 275px;
  }
  .contact-icon-row .icon-wrapper {
    margin-right: 10px;
  }
  .contact-icon-row span:nth-of-type(2) {
    margin-right: 10px;
  }
  section.sklep-section-content,
  section.kasy-fiskalne-section-content {
    padding-bottom: 50px;
    text-align: center;
  }
  section.sklep-section-content .content-products .products-flexbox .inner-service,
  section.kasy-fiskalne-section-content .content-products .products-flexbox .inner-service {
    margin-top: 20px;
    padding: 20px;
    width: 100%;
  }
  section.sklep-section-content .content-products .products-flexbox .inner-service:first-child,
  section.kasy-fiskalne-section-content .content-products .products-flexbox .inner-service:first-child {
    margin-top: 0;
  }
  section.sklep-section-content .content-products .products-flexbox .inner-service .service-name,
  section.kasy-fiskalne-section-content .content-products .products-flexbox .inner-service .service-name {
    margin-top: 20px;
  }
  section.sklep-section-content .content-products .products-flexbox .inner-service .btn,
  section.kasy-fiskalne-section-content .content-products .products-flexbox .inner-service .btn {
    margin-top: 20px;
  }
  section.sklep-section-content .content-products .products-flexbox .inner-service .product-service,
  section.kasy-fiskalne-section-content .content-products .products-flexbox .inner-service .product-service {
    padding: 15px 0;
  }
  section.sklep-section-content .content-categories-service-background,
  section.kasy-fiskalne-section-content .content-categories-service-background {
    padding-top: 50px;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-heading h2,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-heading h2 {
    font-size: 32px;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-heading h3,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-heading h3 {
    font-size: 22px;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-products,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products {
    margin: 60px 0 20px 0;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox {
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .price,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .price {
    text-align: center;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .links-wrapper,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .links-wrapper {
    align-items: flex-start;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-flexbox .links-wrapper a,
  section.kasy-fiskalne-section-content .content-categories-service-background .category-inner .inner-flexbox .links-wrapper a {
    font-size: 15px;
  }
  section.sklep-section-content .content-alsen .alsen-flexbox,
  section.kasy-fiskalne-section-content .content-alsen .alsen-flexbox {
    flex-wrap: wrap;
  }
  section.sklep-section-content .content-alsen .alsen-flexbox img,
  section.kasy-fiskalne-section-content .content-alsen .alsen-flexbox img {
    width: 70%;
    display: block;
    max-width: 200px;
    margin: 0 auto;
  }
  section.sklep-section-content .content-alsen .alsen-flexbox .flexbox-description,
  section.kasy-fiskalne-section-content .content-alsen .alsen-flexbox .flexbox-description {
    width: 100%;
    margin-top: 30px;
  }
  section.sklep-section-content .content-alsen .alsen-flexbox .flexbox-description p,
  section.kasy-fiskalne-section-content .content-alsen .alsen-flexbox .flexbox-description p {
    font-size: 18px;
  }
  section.kasy-fiskalne-section-content {
    padding-bottom: 0;
  }
  section.instalacje-section-content .content-products .products-flexbox .inner-service {
    margin-top: 20px;
    padding: 10px;
    width: 100%;
  }
  section.instalacje-section-content .content-products .products-flexbox .inner-service:first-child {
    margin-top: 0;
  }
  section.instalacje-section-content .content-products .products-flexbox .inner-service .service-name {
    margin-top: 20px;
  }
  section.instalacje-section-content .content-products .products-flexbox .inner-service .btn {
    margin-top: 20px;
  }
  section.instalacje-section-content .content-categories-service-background .category-inner .inner-heading h2 {
    font-size: 24px;
  }
  section.serwis-section-content .services-flexbox {
    padding: 50px 0;
  }
  section.serwis-section-content .services-flexbox .service-column {
    margin: 50px auto 0;
  }
  section.serwis-section-content .services-flexbox .service-column img {
    max-width: 60px;
  }
  section.serwis-section-content .services-flexbox .service-column .service-card {
    padding: 30px 20px;
  }
  section.serwis-section-content .services-flexbox .service-column .service-card .data {
    padding: 0;
  }
  section.serwis-section-content .not-client-contact {
    padding-bottom: 50px;
  }
  section.formularz-section-content {
    padding: 50px 0;
  }
  section.formularz-section-content h1 {
    font-size: 24px;
  }
  section.formularz-section-content .content-flexbox {
    margin-top: 50px;
  }
  section.formularz-section-content .content-flexbox .form-wrapper {
    min-width: unset;
  }
  section.formularz-section-content .content-flexbox .form-wrapper .recaptcha-wrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  section.formularz-section-content .content-flexbox .form-wrapper .recaptcha-wrapper .btn {
    margin-top: 5px;
  }
  section.error-page-section-content {
    padding: 75px 0;
  }
  section.static-page-section-content {
    padding: 50px 0;
  }
  section.static-page-section-content h1 {
    font-size: 24px;
  }
  section.static-page-section-content .regulations-text ol {
    padding-left: 25px;
  }
  section.static-page-section-content .regulations-text ol li {
    margin-top: 25px;
  }
  section.static-page-section-content .regulations-text ol li::before {
    left: -25px;
  }
  section.static-page-section-content .regulations-text ol ol li::before {
    left: -25px;
  }
  section.do-pobrania-section-content {
    padding: 50px 0;
  }
  section.do-pobrania-section-content h1 {
    font-size: 24px;
  }
  section.do-pobrania-section-content .table-wrapper {
    margin-top: 50px;
    overflow-x: scroll;
  }
  section.do-pobrania-section-content .table-wrapper .nav-pills {
    flex-direction: column;
    justify-content: center;
    padding: 0 0 30px 0;
  }
  section.do-pobrania-section-content .table-wrapper .table {
    min-width: 550px;
  }
  section.do-pobrania-section-content .table-wrapper .table tbody tr:hover th:nth-child(4) a,
  section.do-pobrania-section-content .table-wrapper .table tbody tr:hover td:nth-child(4) a {
    opacity: initial;
  }
  section.do-pobrania-section-content .table-wrapper .table tbody tr th:nth-child(4) a,
  section.do-pobrania-section-content .table-wrapper .table tbody tr td:nth-child(4) a {
    opacity: 1;
  }
  section.do-pobrania-section-content .table-wrapper .table tbody tr th:nth-child(4) a img,
  section.do-pobrania-section-content .table-wrapper .table tbody tr td:nth-child(4) a img {
    margin-left: 5px;
    height: 10px;
  }
}
@media (max-width: 450.98px) {
  section.serwis-section-content .services-flexbox .service-column .service-card .contract-clients {
    padding: 20px;
  }
  section.serwis-section-content .services-flexbox .service-column .service-card .contract-clients .contact-icon-row {
    min-width: unset;
  }
}
@media (max-width: 359.98px) {
  section.serwis-section-content .services-flexbox .service-column .service-card .button-wrapper .btn .text {
    justify-content: center;
    font-size: 12px;
    line-height: 15px;
  }
  section.serwis-section-content .services-flexbox .service-column .service-card .button-wrapper .btn .text br {
    display: inline;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox {
    display: block;
  }
  @supports (display: grid) {
    section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service {
    margin-top: 20px;
    display: inline-block;
  }
  @supports (display: grid) {
    section.sklep-section-content .content-categories-service-background .category-inner .inner-products .products-flexbox .product-service {
      margin-top: 0;
      display: unset;
    }
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-cta .cta-buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-cta .cta-buttons .btn {
    margin: 0;
  }
  section.sklep-section-content .content-categories-service-background .category-inner .inner-cta .cta-buttons .btn:last-child {
    margin: 15px 0 0 0;
  }
  .contact-icon-row {
    min-width: 215px;
  }
  .contact-icon-row span:nth-of-type(2) {
    display: none;
  }
  .contact-icon-row span.open-hours {
    display: inline;
    margin-right: 5px;
  }
  section.instalacje-section-content .content-cta .cta-buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  section.instalacje-section-content .content-cta .cta-buttons .btn {
    margin: 0;
  }
  section.instalacje-section-content .content-cta .cta-buttons .btn:last-child {
    margin: 15px 0 0 0;
  }
  .content-contact .contact-alert {
    width: 100%;
    min-width: unset;
  }
}
@media (max-width: 319.98px) {
  .contact-icon-row .icon-wrapper {
    height: 32px;
    width: 32px;
  }
}
